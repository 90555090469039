import axiosInstance from "@/services/axiosInstance";
import type { UploadedProductsCatalogue } from "@/types/api/data-contracts";
import type { AxiosPromise, AxiosResponse } from "axios";

class ProductService {
    // eslint-disable-next-line no-use-before-define
    private static instance: ProductService;

    public static getInstance(): ProductService {
        if (!ProductService.instance) {
            ProductService.instance = new ProductService();
        }
        return ProductService.instance;
    }

    fetchProductIcon(payload: {
        title: string;
    }): AxiosPromise<{ title: string; icon: string }> {
        return axiosInstance.post("inquiries/icons/", payload);
    }

    upload(supplierId: string, formData: FormData) {
        return axiosInstance.post(
            `companies/suppliers/${supplierId}/catalogues/`,
            formData
        );
    }

    async createCatalogueFromName(
        supplierId: string,
        fileName: string
    ): Promise<AxiosResponse<UploadedProductsCatalogue>> {
        const response = await axiosInstance.post(
            `companies/suppliers/${supplierId}/catalogues/create-from-name/`,
            { file_path: fileName }
        );

        return response.data;
    }

    /**
     * Performs a resumable upload of a catalogue file.
     * @param supplierId
     * @param file
     */
    async uploadCatalogue(supplierId: string, file: File) {
        // Get signed url from Opply API
        const response = await axiosInstance.post<{
            signed_url: string;
        }>(`companies/suppliers/${supplierId}/catalogues/signed-url/`, {
            size: file.size,
            file_name: file.name,
            method: "PUT",
        });

        // Create a new worker, the workers will handle the resumable upload in background in a separate thread.
        const resumableUploadWorker = new Worker(
            new URL("../workers/resumableUploadWorker.ts", import.meta.url),
            {
                type: "module",
            }
        );

        const signedUrl = response.data.signed_url;

        return { resumableUploadWorker, signedUrl };
    }

    list(supplierId: string) {
        return axiosInstance.get(
            `companies/suppliers/${supplierId}/catalogues/`
        );
    }

    delete(supplierId: string, catalogueId: string) {
        return axiosInstance.delete(
            `companies/suppliers/${supplierId}/catalogues/${catalogueId}/`
        );
    }
}

export default ProductService;
