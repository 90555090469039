// @ts-nocheck/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * * `Mark samples as dispatched` - Mark Samples Dispatched
 * * `Reject samples request` - Reject Samples Request
 * * `Buyer marks samples as delivered` - Buyer Marks Samples Delivered
 * * `Order is marked as dispatched or Buyer marks order as delivered` - Order Marked As Dispatched Or Buyer Marks Order Delivered
 * * `Order has been delivered` - Order Delivered
 * * `Purchase Order is Accepted or Declined` - Purchase Order Accepted Or Declined
 * * `View the Request Details page` - View Request Details Page
 * * `Payment has been received` - Payment Received
 * * `Signed Agreement Uploaded` - Signed Agreement Uploaded
 * * `Purchase Order is placed` - Purchase Order Placed
 * * `Update quote or reject negotiation` - Update Quote Or Reject Negotiation
 * * `One or more ingredients are created` - Ingredients Created
 * * `One of more products are created` - Products Created
 * * `Samples are marked as received` - Samples Marked As Received
 * * `Marked as delivered` - Marked As Delivered
 * * `Quote for request or decline request` - Quote For Request Or Decline
 * * `Product list is uploaded` - Product List Uploaded
 * * `Respond to Request` - Respond To Request
 * * `Respond to negotiations` - Respond To Negotiations
 * * `Contract is created` - Contract Created
 * * `Buyer has created a request` - Buyer Created Request
 * * `Order or decline quote` - Order Or Decline Quote
 * * `Open contract details page` - Open Contract Details Page
 * * `Accept contract renewal` - Accept Contract Renewal
 * * `Decline contract renewal` - Decline Contract Renewal
 * * `Negotiate contract renewal` - Negotiate Contract Renewal
 * * `Place first request` - Place First Request
 * * `Feedback has been given for a sample` - Feedback Given For Sample
 * * `5 or more ingredients are created` - Upload Ingredient List
 * * `2 or more products are created` - Upload Product List
 */
export enum ActionClearTriggerEnum {
    MarkSamplesAsDispatched = "Mark samples as dispatched",
    RejectSamplesRequest = "Reject samples request",
    BuyerMarksSamplesAsDelivered = "Buyer marks samples as delivered",
    OrderIsMarkedAsDispatchedOrBuyerMarksOrderAsDelivered = "Order is marked as dispatched or Buyer marks order as delivered",
    OrderHasBeenDelivered = "Order has been delivered",
    PurchaseOrderIsAcceptedOrDeclined = "Purchase Order is Accepted or Declined",
    ViewTheRequestDetailsPage = "View the Request Details page",
    PaymentHasBeenReceived = "Payment has been received",
    SignedAgreementUploaded = "Signed Agreement Uploaded",
    PurchaseOrderIsPlaced = "Purchase Order is placed",
    UpdateQuoteOrRejectNegotiation = "Update quote or reject negotiation",
    OneOrMoreIngredientsAreCreated = "One or more ingredients are created",
    OneOfMoreProductsAreCreated = "One of more products are created",
    SamplesAreMarkedAsReceived = "Samples are marked as received",
    MarkedAsDelivered = "Marked as delivered",
    QuoteForRequestOrDeclineRequest = "Quote for request or decline request",
    ProductListIsUploaded = "Product list is uploaded",
    RespondToRequest = "Respond to Request",
    RespondToNegotiations = "Respond to negotiations",
    ContractIsCreated = "Contract is created",
    BuyerHasCreatedARequest = "Buyer has created a request",
    OrderOrDeclineQuote = "Order or decline quote",
    OpenContractDetailsPage = "Open contract details page",
    AcceptContractRenewal = "Accept contract renewal",
    DeclineContractRenewal = "Decline contract renewal",
    NegotiateContractRenewal = "Negotiate contract renewal",
    PlaceFirstRequest = "Place first request",
    FeedbackHasBeenGivenForASample = "Feedback has been given for a sample",
    Value5OrMoreIngredientsAreCreated = "5 or more ingredients are created",
    Value2OrMoreProductsAreCreated = "2 or more products are created",
}

/**
 * * `order` - Order
 * * `request` - Request
 * * `contracts` - Contracts
 * * `onboarding` - Onboarding
 * * `samples` - Samples
 * * `quotes` - Quotes
 */
export enum ActionTypeEnum {
    Order = "order",
    Request = "request",
    Contracts = "contracts",
    Onboarding = "onboarding",
    Samples = "samples",
    Quotes = "quotes",
}

/**
 * * `producer` - Producer
 * * `distributor` - Distributor
 * * `importer_exporter` - Importer / Exporter
 * * `wholesaler` - Wholesaler
 * * `food_manufacturer` - Manufacturer of Finished Food Products
 * * `beverage_manufacturer` - Manufacturer of Finished Beverage Products
 * * `raw_mat_manufacturer` - Manufacturer of raw materials
 * * `other` - Other
 */
export enum ActivityTypeEnum {
    Producer = "producer",
    Distributor = "distributor",
    ImporterExporter = "importer_exporter",
    Wholesaler = "wholesaler",
    FoodManufacturer = "food_manufacturer",
    BeverageManufacturer = "beverage_manufacturer",
    RawMatManufacturer = "raw_mat_manufacturer",
    Other = "other",
}

export interface Address {
    /** @maxLength 100 */
    building?: string | null;
    /** @maxLength 255 */
    street_1?: string | null;
    /** @maxLength 255 */
    street_2?: string | null;
    /** @maxLength 100 */
    town?: string | null;
    /** @maxLength 100 */
    region?: string | null;
    /** @maxLength 100 */
    postcode?: string | null;
    country?: Country | BlankEnum | NullEnum | null;
    /** @maxLength 100 */
    place_id?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,4}(?:\.\d{0,15})?$
     */
    latitude?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,4}(?:\.\d{0,15})?$
     */
    longitude?: string | null;
    company_id: number;
}

/**
 * * `billing_address` - Billing address
 * * `delivery_address` - Delivery address
 * * `dispatch_address` - Dispatch address
 * * `company_address` - Company address
 */
export enum AddressTypeEnum {
    BillingAddress = "billing_address",
    DeliveryAddress = "delivery_address",
    DispatchAddress = "dispatch_address",
    CompanyAddress = "company_address",
}

export interface AdminBuyerSummary {
    product_types_description?: string | null;
    revenue?: RevenueEnum | BlankEnum | NullEnum | null;
}

export interface AdminCheckReferralCode {
    referral_code: string;
}

export interface AdminCompany {
    id: number;
    /** @format uuid */
    uuid: string;
    /** @maxLength 255 */
    website?: string | null;
    /** @maxLength 255 */
    name: string;
    /** @format uri */
    logo?: string | null;
    /** @maxLength 100 */
    industry?: string | null;
    kyb_status: string;
    /** @maxLength 50 */
    phone_number?: string | null;
    country: string;
    state: string;
    /**
     * * `brand` - Brand
     * * `supplier` - Supplier
     */
    company_type?: CompanyTypeEnum;
    /** @default ["h","r","e","o","t"] */
    product_type?: ProductTypeEnum[];
    /** @default ["h","r","e","o","t"] */
    activity_type?: ActivityTypeEnum[];
    /** @maxLength 255 */
    registration_number?: string | null;
    mongo_id: string | null;
    referral_code: string;
    phone_country_code?: PhoneCountryCodeEnum | BlankEnum | NullEnum | null;
    company_addresses?: CompanyAddress[];
    currency: string;
    /**
     * rapyd payout method
     * @maxLength 45
     */
    payout_method_type?: string | null;
    bank_account_country?: Country | BlankEnum | NullEnum | null;
    /** @format date-time */
    created: string;
    last_login: LastLogin;
    /** @default ["h","r","e","o","t"] */
    product_category?: ProductCategoryEnum[];
    annual_turnover?: AnnualTurnoverEnum | BlankEnum | NullEnum | null;
    num_of_employees?: NumOfEmployeesEnum | BlankEnum | NullEnum | null;
    num_of_skus?: NumOfSkusEnum | BlankEnum | NullEnum | null;
    mov?: MovEnum | BlankEnum | NullEnum | null;
    is_active: string;
}

export interface AdminCompanyDocument {
    /** @format uuid */
    uuid: string;
    company: number;
    /** @format uri */
    document: string;
    description?: string | null;
    /**
     * * `products_list` - Products List
     * * `ingredients_list` - Ingredients List
     * * `other` - Other
     */
    document_category?: CompanyDocumentCategory;
}

export interface AdminCompanyRetrieve {
    id: number;
    /** @format uuid */
    uuid: string;
    /** @maxLength 255 */
    website?: string | null;
    /** @maxLength 255 */
    name: string;
    /** @format uri */
    logo?: string | null;
    /** @maxLength 100 */
    industry?: string | null;
    kyb_status: string;
    /** @maxLength 50 */
    phone_number?: string | null;
    country: string;
    state: string;
    /**
     * * `brand` - Brand
     * * `supplier` - Supplier
     */
    company_type?: CompanyTypeEnum;
    /** @default ["h","r","e","o","t"] */
    product_type?: ProductTypeEnum[];
    activity_type: string;
    /** @maxLength 255 */
    registration_number?: string | null;
    /** @maxLength 50 */
    mongo_id?: string | null;
    /** @maxLength 10 */
    referral_code?: string;
    phone_country_code?: PhoneCountryCodeEnum | BlankEnum | NullEnum | null;
    company_addresses?: CompanyAddress[];
    currency: string;
    /**
     * rapyd payout method
     * @maxLength 45
     */
    payout_method_type?: string | null;
    bank_account_country?: Country | BlankEnum | NullEnum | null;
    /** @format date-time */
    created: string;
    last_login: LastLogin;
    /** @default ["h","r","e","o","t"] */
    product_category?: ProductCategoryEnum[];
    annual_turnover?: AnnualTurnoverEnum | BlankEnum | NullEnum | null;
    num_of_employees?: NumOfEmployeesEnum | BlankEnum | NullEnum | null;
    num_of_skus?: NumOfSkusEnum | BlankEnum | NullEnum | null;
    mov?: MovEnum | BlankEnum | NullEnum | null;
    is_active: string;
    created_by: User;
    registration_name: string;
    description: string;
    company_documents: any[];
    referred_by: Company;
    /** @format date */
    kyb_verified_date: string | null;
    /** @format date */
    kyb_approved_date: string | null;
    /** @format date */
    kriya_kyb_approved_date: string | null;
    kriya_decision_status: string;
    /** @format double */
    kriya_total_limit: number;
    /** @format double */
    kriya_available_limit: number;
}

export interface AdminIngredientResponse {
    /** @format uuid */
    uuid: string;
    /** @maxLength 255 */
    name: string;
    /** @format uuid */
    company_uuid: string;
    inventory_unit_of_measurement: InventoryUnitOfMeasurementEnum;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    on_hand: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    allocated: string;
    associated_inquiries: string[];
    associated_contracts: string[];
    current_supplier?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    current_unit_price: string;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    currency?: Currencies;
    description: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    quantity: string;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement?: UnitOfMeasurementEnum;
    diet_requirements?: DietRequirementsEnum | BlankEnum | NullEnum | null;
    frequency?: FrequencyEnum | BlankEnum | NullEnum | null;
    existing_suppliers: string;
    inventories: IngredientInventory[];
}

export interface AdminMessagesResponse {
    /** @format uuid */
    uuid: string;
    /** @format uuid */
    sender_company_uuid: string;
    /** @format uuid */
    recipient_company_uuid: string;
    /** @format date-time */
    created: string;
    text?: string | null;
    /** @format uuid */
    content_object_uuid: string;
    content_object_type: string;
}

export interface AdminProductCatalogueList {
    /** @format uuid */
    uuid: string;
    /**
     * * `pending` - Pending
     * * `being_processed` - Is being processed
     * * `processed` - Processed
     * * `failed` - Failed
     */
    status?: UploadedProductsCatalogueStatus;
    /** @format uri */
    catalogue?: string | null;
    /** @format date-time */
    created: string;
}

export interface AdminProductsResponse {
    /** @format uuid */
    uuid: string;
    /** @maxLength 255 */
    title: string;
    /** @format uuid */
    company_uuid: string;
    in_stock: number;
    in_production: number;
    associated_ingredients: string[];
}

export interface AdminSendUploadedListEmailNotification {
    /**
     * * `products_list` - Products List
     * * `ingredients_list` - Ingredients List
     * * `other` - Other
     */
    category: CompanyDocumentCategory;
}

export interface AllAndFeaturedQuotesForInquiry {
    /** @format uuid */
    uuid: string;
    /** @maxLength 255 */
    product_name: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,10})?$
     */
    unit_price?: string;
    /**
     * * `EUR` - EUR
     * * `GBP` - GBP
     * * `USD` - USD
     * * `CAD` - CAD
     */
    currency: SupportedCurrencies;
    /**
     * * `non_recurring` - Non Recurring
     * * `recurring` - Recurring
     * * `purchase_request_quote` - Purchase Request Quote
     * * `offline_quote` - Offline Quote
     * * `sales_quote` - Sales Quote
     */
    category?: QuoteCategory;
    /**
     * @format decimal
     * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
     */
    quantity: string;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement?: UnitOfMeasurementEnum;
    /**
     * The maximum time a supplier agrees to deliver the product within (days)
     * @min 0
     * @max 2147483647
     */
    lead_time: number;
    /** @format date */
    expiry_date?: string | null;
    /**
     * * `available` - available
     * * `ordered` - ordered
     * * `expired` - expired
     * * `declined` - declined
     * * `pending_payment` - pending_payment
     * * `proceeded` - proceeded
     * * `contract_created` - Contract Created
     * * `contract_proceeded` - contract_proceeded
     * * `contract_confirmed` - contract_confirmed
     */
    status?: QuoteState;
    /**
     * * `available` - Available
     * * `requested` - Requested
     * * `sent` - Sent
     * * `received` - Received
     * * `accepted` - Accepted
     * * `rejected` - Rejected
     * * `declined` - Declined
     */
    samples_status?: SamplesStatusEnum;
    samples_delivery_address?: number | null;
    samples_feedback?: SamplesFeedbackEnum[] | null;
    samples_feedback_comment?: string;
    unread_messages_count: number;
    has_specs: boolean;
    unit_price_no_vat_with_shipping: string;
    price_percentage_lower: string;
    packaging: string;
    expires_in: string;
    total_forecast_volume: string;
}

/**
 * * `HUF` - HUF
 * * `SVC` - SVC
 * * `MZN` - MZN
 * * `BOB` - BOB
 * * `GHS` - GHS
 * * `MAD` - MAD
 * * `USD` - USD
 * * `ECS` - ECS
 * * `AFA` - AFA
 * * `ECV` - ECV
 * * `ZAR` - ZAR
 * * `CNH` - CNH
 * * `GEK` - GEK
 * * `TWD` - TWD
 * * `FRF` - FRF
 * * `SUR` - SUR
 * * `VNN` - VNN
 * * `TTD` - TTD
 * * `ZWD` - ZWD
 * * `ALL` - ALL
 * * `LTL` - LTL
 * * `LVL` - LVL
 * * `JOD` - JOD
 * * `CNY` - CNY
 * * `LBP` - LBP
 * * `BYB` - BYB
 * * `ARM` - ARM
 * * `KPW` - KPW
 * * `CYP` - CYP
 * * `AZN` - AZN
 * * `LTT` - LTT
 * * `XTS` - XTS
 * * `BYR` - BYR
 * * `CNX` - CNX
 * * `HKD` - HKD
 * * `RSD` - RSD
 * * `BGN` - BGN
 * * `ERN` - ERN
 * * `EEK` - EEK
 * * `GHC` - GHC
 * * `TJS` - TJS
 * * `SLL` - SLL
 * * `ARL` - ARL
 * * `BUK` - BUK
 * * `ARS` - ARS
 * * `SSP` - SSP
 * * `TOP` - TOP
 * * `XCD` - XCD
 * * `SEK` - SEK
 * * `PAB` - PAB
 * * `XAU` - XAU
 * * `KGS` - KGS
 * * `DJF` - DJF
 * * `XFU` - XFU
 * * `SIT` - SIT
 * * `AON` - AON
 * * `EUR` - EUR
 * * `NIO` - NIO
 * * `BGL` - BGL
 * * `AFN` - AFN
 * * `MMK` - MMK
 * * `USS` - USS
 * * `BOL` - BOL
 * * `BRE` - BRE
 * * `AUD` - AUD
 * * `UGS` - UGS
 * * `LUF` - LUF
 * * `JMD` - JMD
 * * `COU` - COU
 * * `GYD` - GYD
 * * `STD` - STD
 * * `MDL` - MDL
 * * `YUN` - YUN
 * * `DZD` - DZD
 * * `NGN` - NGN
 * * `UYU` - UYU
 * * `SYP` - SYP
 * * `ADP` - ADP
 * * `XBC` - XBC
 * * `MLF` - MLF
 * * `XUA` - XUA
 * * `XXX` - XXX
 * * `ISK` - ISK
 * * `BDT` - BDT
 * * `NZD` - NZD
 * * `ETB` - ETB
 * * `HTG` - HTG
 * * `BEC` - BEC
 * * `SAR` - SAR
 * * `AZM` - AZM
 * * `KWD` - KWD
 * * `CLP` - CLP
 * * `SDD` - SDD
 * * `INR` - INR
 * * `ATS` - ATS
 * * `AWG` - AWG
 * * `IEP` - IEP
 * * `GNF` - GNF
 * * `ILS` - ILS
 * * `SDP` - SDP
 * * `XOF` - XOF
 * * `UGX` - UGX
 * * `GEL` - GEL
 * * `YER` - YER
 * * `BRZ` - BRZ
 * * `ZWR` - ZWR
 * * `TMT` - TMT
 * * `XBB` - XBB
 * * `CRC` - CRC
 * * `MGA` - MGA
 * * `BOV` - BOV
 * * `SLE` - SLE
 * * `CUC` - CUC
 * * `CSD` - CSD
 * * `AOA` - AOA
 * * `SDG` - SDG
 * * `UYP` - UYP
 * * `MNT` - MNT
 * * `BRB` - BRB
 * * `YUM` - YUM
 * * `ZAL` - ZAL
 * * `XEU` - XEU
 * * `VEF` - VEF
 * * `PKR` - PKR
 * * `BHD` - BHD
 * * `PLN` - PLN
 * * `ITL` - ITL
 * * `TND` - TND
 * * `MWK` - MWK
 * * `LUC` - LUC
 * * `MDC` - MDC
 * * `BMD` - BMD
 * * `QAR` - QAR
 * * `PEI` - PEI
 * * `FKP` - FKP
 * * `GQE` - GQE
 * * `XPF` - XPF
 * * `CHW` - CHW
 * * `BRR` - BRR
 * * `SHP` - SHP
 * * `CHE` - CHE
 * * `HRD` - HRD
 * * `BGM` - BGM
 * * `LVR` - LVR
 * * `CLE` - CLE
 * * `ZRN` - ZRN
 * * `DEM` - DEM
 * * `AOK` - AOK
 * * `XDR` - XDR
 * * `TPE` - TPE
 * * `ILR` - ILR
 * * `MRU` - MRU
 * * `KHR` - KHR
 * * `MXV` - MXV
 * * `HRK` - HRK
 * * `SBD` - SBD
 * * `YUR` - YUR
 * * `PES` - PES
 * * `GNS` - GNS
 * * `MKD` - MKD
 * * `PTE` - PTE
 * * `BOP` - BOP
 * * `BGO` - BGO
 * * `CDF` - CDF
 * * `BYN` - BYN
 * * `CLF` - CLF
 * * `LRD` - LRD
 * * `IRR` - IRR
 * * `MRO` - MRO
 * * `MUR` - MUR
 * * `VES` - VES
 * * `VEB` - VEB
 * * `RHD` - RHD
 * * `GBP` - GBP
 * * `TRL` - TRL
 * * `RWF` - RWF
 * * `ZRZ` - ZRZ
 * * `TJR` - TJR
 * * `CAD` - CAD
 * * `HNL` - HNL
 * * `LYD` - LYD
 * * `FIM` - FIM
 * * `ESA` - ESA
 * * `ESB` - ESB
 * * `GWE` - GWE
 * * `AOR` - AOR
 * * `AED` - AED
 * * `IQD` - IQD
 * * `KRO` - KRO
 * * `THB` - THB
 * * `WST` - WST
 * * `LAK` - LAK
 * * `MVR` - MVR
 * * `IDR` - IDR
 * * `MYR` - MYR
 * * `BEF` - BEF
 * * `AMD` - AMD
 * * `XCG` - XCG
 * * `MTP` - MTP
 * * `SCR` - SCR
 * * `KRH` - KRH
 * * `PHP` - PHP
 * * `TMM` - TMM
 * * `UZS` - UZS
 * * `ZMW` - ZMW
 * * `STN` - STN
 * * `XSU` - XSU
 * * `UYI` - UYI
 * * `BRC` - BRC
 * * `CZK` - CZK
 * * `GTQ` - GTQ
 * * `PGK` - PGK
 * * `CVE` - CVE
 * * `KES` - KES
 * * `XRE` - XRE
 * * `XBA` - XBA
 * * `VUV` - VUV
 * * `RON` - RON
 * * `ESP` - ESP
 * * `NLG` - NLG
 * * `CUP` - CUP
 * * `LKR` - LKR
 * * `XPT` - XPT
 * * `LUL` - LUL
 * * `TZS` - TZS
 * * `XPD` - XPD
 * * `DKK` - DKK
 * * `MZM` - MZM
 * * `MVP` - MVP
 * * `XAG` - XAG
 * * `PLZ` - PLZ
 * * `MTL` - MTL
 * * `BRL` - BRL
 * * `PYG` - PYG
 * * `CHF` - CHF
 * * `UAK` - UAK
 * * `COP` - COP
 * * `SOS` - SOS
 * * `MKN` - MKN
 * * `SZL` - SZL
 * * `ANG` - ANG
 * * `GIP` - GIP
 * * `ALK` - ALK
 * * `YUD` - YUD
 * * `OMR` - OMR
 * * `LSL` - LSL
 * * `BAD` - BAD
 * * `SKK` - SKK
 * * `BWP` - BWP
 * * `YDD` - YDD
 * * `ROL` - ROL
 * * `GMD` - GMD
 * * `BEL` - BEL
 * * `DOP` - DOP
 * * `KYD` - KYD
 * * `MAF` - MAF
 * * `MOP` - MOP
 * * `NAD` - NAD
 * * `RUR` - RUR
 * * `BAM` - BAM
 * * `BZD` - BZD
 * * `VND` - VND
 * * `XBD` - XBD
 * * `BIF` - BIF
 * * `SRD` - SRD
 * * `ARP` - ARP
 * * `FJD` - FJD
 * * `KRW` - KRW
 * * `JPY` - JPY
 * * `MGF` - MGF
 * * `MXN` - MXN
 * * `USN` - USN
 * * `BND` - BND
 * * `MZE` - MZE
 * * `GWP` - GWP
 * * `XAF` - XAF
 * * `BSD` - BSD
 * * `BBD` - BBD
 * * `UAH` - UAH
 * * `TRY` - TRY
 * * `BRN` - BRN
 * * `DDM` - DDM
 * * `ZWL` - ZWL
 * * `ILP` - ILP
 * * `RUB` - RUB
 * * `CSK` - CSK
 * * `SGD` - SGD
 * * `XFO` - XFO
 * * `EGP` - EGP
 * * `NIC` - NIC
 * * `NOK` - NOK
 * * `KMF` - KMF
 * * `NPR` - NPR
 * * `UYW` - UYW
 * * `KZT` - KZT
 * * `VED` - VED
 * * `BTN` - BTN
 * * `BAN` - BAN
 * * `ISJ` - ISJ
 * * `PEN` - PEN
 * * `ZMK` - ZMK
 * * `MXP` - MXP
 * * `MCF` - MCF
 * * `ARA` - ARA
 * * `GRD` - GRD
 * * `SRG` - SRG
 */
export enum AllCurrencies {
    HUF = "HUF",
    SVC = "SVC",
    MZN = "MZN",
    BOB = "BOB",
    GHS = "GHS",
    MAD = "MAD",
    USD = "USD",
    ECS = "ECS",
    AFA = "AFA",
    ECV = "ECV",
    ZAR = "ZAR",
    CNH = "CNH",
    GEK = "GEK",
    TWD = "TWD",
    FRF = "FRF",
    SUR = "SUR",
    VNN = "VNN",
    TTD = "TTD",
    ZWD = "ZWD",
    ALL = "ALL",
    LTL = "LTL",
    LVL = "LVL",
    JOD = "JOD",
    CNY = "CNY",
    LBP = "LBP",
    BYB = "BYB",
    ARM = "ARM",
    KPW = "KPW",
    CYP = "CYP",
    AZN = "AZN",
    LTT = "LTT",
    XTS = "XTS",
    BYR = "BYR",
    CNX = "CNX",
    HKD = "HKD",
    RSD = "RSD",
    BGN = "BGN",
    ERN = "ERN",
    EEK = "EEK",
    GHC = "GHC",
    TJS = "TJS",
    SLL = "SLL",
    ARL = "ARL",
    BUK = "BUK",
    ARS = "ARS",
    SSP = "SSP",
    TOP = "TOP",
    XCD = "XCD",
    SEK = "SEK",
    PAB = "PAB",
    XAU = "XAU",
    KGS = "KGS",
    DJF = "DJF",
    XFU = "XFU",
    SIT = "SIT",
    AON = "AON",
    EUR = "EUR",
    NIO = "NIO",
    BGL = "BGL",
    AFN = "AFN",
    MMK = "MMK",
    USS = "USS",
    BOL = "BOL",
    BRE = "BRE",
    AUD = "AUD",
    UGS = "UGS",
    LUF = "LUF",
    JMD = "JMD",
    COU = "COU",
    GYD = "GYD",
    STD = "STD",
    MDL = "MDL",
    YUN = "YUN",
    DZD = "DZD",
    NGN = "NGN",
    UYU = "UYU",
    SYP = "SYP",
    ADP = "ADP",
    XBC = "XBC",
    MLF = "MLF",
    XUA = "XUA",
    XXX = "XXX",
    ISK = "ISK",
    BDT = "BDT",
    NZD = "NZD",
    ETB = "ETB",
    HTG = "HTG",
    BEC = "BEC",
    SAR = "SAR",
    AZM = "AZM",
    KWD = "KWD",
    CLP = "CLP",
    SDD = "SDD",
    INR = "INR",
    ATS = "ATS",
    AWG = "AWG",
    IEP = "IEP",
    GNF = "GNF",
    ILS = "ILS",
    SDP = "SDP",
    XOF = "XOF",
    UGX = "UGX",
    GEL = "GEL",
    YER = "YER",
    BRZ = "BRZ",
    ZWR = "ZWR",
    TMT = "TMT",
    XBB = "XBB",
    CRC = "CRC",
    MGA = "MGA",
    BOV = "BOV",
    SLE = "SLE",
    CUC = "CUC",
    CSD = "CSD",
    AOA = "AOA",
    SDG = "SDG",
    UYP = "UYP",
    MNT = "MNT",
    BRB = "BRB",
    YUM = "YUM",
    ZAL = "ZAL",
    XEU = "XEU",
    VEF = "VEF",
    PKR = "PKR",
    BHD = "BHD",
    PLN = "PLN",
    ITL = "ITL",
    TND = "TND",
    MWK = "MWK",
    LUC = "LUC",
    MDC = "MDC",
    BMD = "BMD",
    QAR = "QAR",
    PEI = "PEI",
    FKP = "FKP",
    GQE = "GQE",
    XPF = "XPF",
    CHW = "CHW",
    BRR = "BRR",
    SHP = "SHP",
    CHE = "CHE",
    HRD = "HRD",
    BGM = "BGM",
    LVR = "LVR",
    CLE = "CLE",
    ZRN = "ZRN",
    DEM = "DEM",
    AOK = "AOK",
    XDR = "XDR",
    TPE = "TPE",
    ILR = "ILR",
    MRU = "MRU",
    KHR = "KHR",
    MXV = "MXV",
    HRK = "HRK",
    SBD = "SBD",
    YUR = "YUR",
    PES = "PES",
    GNS = "GNS",
    MKD = "MKD",
    PTE = "PTE",
    BOP = "BOP",
    BGO = "BGO",
    CDF = "CDF",
    BYN = "BYN",
    CLF = "CLF",
    LRD = "LRD",
    IRR = "IRR",
    MRO = "MRO",
    MUR = "MUR",
    VES = "VES",
    VEB = "VEB",
    RHD = "RHD",
    GBP = "GBP",
    TRL = "TRL",
    RWF = "RWF",
    ZRZ = "ZRZ",
    TJR = "TJR",
    CAD = "CAD",
    HNL = "HNL",
    LYD = "LYD",
    FIM = "FIM",
    ESA = "ESA",
    ESB = "ESB",
    GWE = "GWE",
    AOR = "AOR",
    AED = "AED",
    IQD = "IQD",
    KRO = "KRO",
    THB = "THB",
    WST = "WST",
    LAK = "LAK",
    MVR = "MVR",
    IDR = "IDR",
    MYR = "MYR",
    BEF = "BEF",
    AMD = "AMD",
    XCG = "XCG",
    MTP = "MTP",
    SCR = "SCR",
    KRH = "KRH",
    PHP = "PHP",
    TMM = "TMM",
    UZS = "UZS",
    ZMW = "ZMW",
    STN = "STN",
    XSU = "XSU",
    UYI = "UYI",
    BRC = "BRC",
    CZK = "CZK",
    GTQ = "GTQ",
    PGK = "PGK",
    CVE = "CVE",
    KES = "KES",
    XRE = "XRE",
    XBA = "XBA",
    VUV = "VUV",
    RON = "RON",
    ESP = "ESP",
    NLG = "NLG",
    CUP = "CUP",
    LKR = "LKR",
    XPT = "XPT",
    LUL = "LUL",
    TZS = "TZS",
    XPD = "XPD",
    DKK = "DKK",
    MZM = "MZM",
    MVP = "MVP",
    XAG = "XAG",
    PLZ = "PLZ",
    MTL = "MTL",
    BRL = "BRL",
    PYG = "PYG",
    CHF = "CHF",
    UAK = "UAK",
    COP = "COP",
    SOS = "SOS",
    MKN = "MKN",
    SZL = "SZL",
    ANG = "ANG",
    GIP = "GIP",
    ALK = "ALK",
    YUD = "YUD",
    OMR = "OMR",
    LSL = "LSL",
    BAD = "BAD",
    SKK = "SKK",
    BWP = "BWP",
    YDD = "YDD",
    ROL = "ROL",
    GMD = "GMD",
    BEL = "BEL",
    DOP = "DOP",
    KYD = "KYD",
    MAF = "MAF",
    MOP = "MOP",
    NAD = "NAD",
    RUR = "RUR",
    BAM = "BAM",
    BZD = "BZD",
    VND = "VND",
    XBD = "XBD",
    BIF = "BIF",
    SRD = "SRD",
    ARP = "ARP",
    FJD = "FJD",
    KRW = "KRW",
    JPY = "JPY",
    MGF = "MGF",
    MXN = "MXN",
    USN = "USN",
    BND = "BND",
    MZE = "MZE",
    GWP = "GWP",
    XAF = "XAF",
    BSD = "BSD",
    BBD = "BBD",
    UAH = "UAH",
    TRY = "TRY",
    BRN = "BRN",
    DDM = "DDM",
    ZWL = "ZWL",
    ILP = "ILP",
    RUB = "RUB",
    CSK = "CSK",
    SGD = "SGD",
    XFO = "XFO",
    EGP = "EGP",
    NIC = "NIC",
    NOK = "NOK",
    KMF = "KMF",
    NPR = "NPR",
    UYW = "UYW",
    KZT = "KZT",
    VED = "VED",
    BTN = "BTN",
    BAN = "BAN",
    ISJ = "ISJ",
    PEN = "PEN",
    ZMK = "ZMK",
    MXP = "MXP",
    MCF = "MCF",
    ARA = "ARA",
    GRD = "GRD",
    SRG = "SRG",
}

export interface AllEmailNotifications {
    all_emails: boolean;
}

/**
 * * `celery` - Celery
 * * `cereals_containing_gluten` - Cereals Containing Gluten
 * * `crustaceans` - Crustaceans
 * * `eggs` - Eggs
 * * `fish` - Fish
 * * `lupin` - Lupin
 * * `milk` - Milk
 * * `molluscs` - Molluscs
 * * `mustard` - Mustard
 * * `nuts` - Nuts
 * * `peanuts` - Peanuts
 * * `sesame_seeds` - Sesame Seeds
 * * `soya` - Soya
 * * `sulphur_dioxide` - Sulphur Dioxide
 */
export enum AllergenRequirementsEnum {
    Celery = "celery",
    CerealsContainingGluten = "cereals_containing_gluten",
    Crustaceans = "crustaceans",
    Eggs = "eggs",
    Fish = "fish",
    Lupin = "lupin",
    Milk = "milk",
    Molluscs = "molluscs",
    Mustard = "mustard",
    Nuts = "nuts",
    Peanuts = "peanuts",
    SesameSeeds = "sesame_seeds",
    Soya = "soya",
    SulphurDioxide = "sulphur_dioxide",
}

export interface Allocate {
    quantity_to_allocate: number;
}

/**
 * * `0 USD - 5m USD` - 0 USD - 5m USD
 * * `6m USD - 20m USD` - 6m USD - 20m USD
 * * `21m USD - 100m USD` - 21m USD - 100m USD
 * * `100m+ USD` - 100m+ USD
 */
export enum AnnualTurnoverEnum {
    Value0USD5MUSD = "0 USD - 5m USD",
    Value6MUSD20MUSD = "6m USD - 20m USD",
    Value21MUSD100MUSD = "21m USD - 100m USD",
    Value100MUSD = "100m+ USD",
}

export interface Article {
    /** @format uuid */
    uuid: string;
    inquiries: Inquiry[];
    inventories: ProductInventory[];
    /** @format date-time */
    created: string;
    /** @format date-time */
    modified: string;
    /** @maxLength 255 */
    title: string;
    /**
     * @format uri
     * @maxLength 2048
     */
    logo_url?: string;
    description?: string | null;
    /** @maxLength 32 */
    sku?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    recommended_retail_price?: string | null;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    currency?: Currencies;
    /**
     * * `for_inquiries` - For Inquiries
     * * `product` - Product
     */
    category?: ArticleCategory;
    /**
     * @format decimal
     * @pattern ^-?\d{0,12}(?:\.\d{0,0})?$
     */
    stock_on_hand?: string | null;
    uploaded_by_admin?: boolean;
    company: number;
}

/**
 * * `for_inquiries` - For Inquiries
 * * `product` - Product
 */
export enum ArticleCategory {
    ForInquiries = "for_inquiries",
    Product = "product",
}

export interface BankTransfer {
    bank_account: string;
    merchant_reference_id: string;
    reference_number: string;
}

export interface BasketDiscountSwagger {
    quote_uuids: string[];
}

export interface BasketPrices {
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    sum_to_pay: string;
    /**
     * * `HUF` - HUF
     * * `SVC` - SVC
     * * `MZN` - MZN
     * * `BOB` - BOB
     * * `GHS` - GHS
     * * `MAD` - MAD
     * * `USD` - USD
     * * `ECS` - ECS
     * * `AFA` - AFA
     * * `ECV` - ECV
     * * `ZAR` - ZAR
     * * `CNH` - CNH
     * * `GEK` - GEK
     * * `TWD` - TWD
     * * `FRF` - FRF
     * * `SUR` - SUR
     * * `VNN` - VNN
     * * `TTD` - TTD
     * * `ZWD` - ZWD
     * * `ALL` - ALL
     * * `LTL` - LTL
     * * `LVL` - LVL
     * * `JOD` - JOD
     * * `CNY` - CNY
     * * `LBP` - LBP
     * * `BYB` - BYB
     * * `ARM` - ARM
     * * `KPW` - KPW
     * * `CYP` - CYP
     * * `AZN` - AZN
     * * `LTT` - LTT
     * * `XTS` - XTS
     * * `BYR` - BYR
     * * `CNX` - CNX
     * * `HKD` - HKD
     * * `RSD` - RSD
     * * `BGN` - BGN
     * * `ERN` - ERN
     * * `EEK` - EEK
     * * `GHC` - GHC
     * * `TJS` - TJS
     * * `SLL` - SLL
     * * `ARL` - ARL
     * * `BUK` - BUK
     * * `ARS` - ARS
     * * `SSP` - SSP
     * * `TOP` - TOP
     * * `XCD` - XCD
     * * `SEK` - SEK
     * * `PAB` - PAB
     * * `XAU` - XAU
     * * `KGS` - KGS
     * * `DJF` - DJF
     * * `XFU` - XFU
     * * `SIT` - SIT
     * * `AON` - AON
     * * `EUR` - EUR
     * * `NIO` - NIO
     * * `BGL` - BGL
     * * `AFN` - AFN
     * * `MMK` - MMK
     * * `USS` - USS
     * * `BOL` - BOL
     * * `BRE` - BRE
     * * `AUD` - AUD
     * * `UGS` - UGS
     * * `LUF` - LUF
     * * `JMD` - JMD
     * * `COU` - COU
     * * `GYD` - GYD
     * * `STD` - STD
     * * `MDL` - MDL
     * * `YUN` - YUN
     * * `DZD` - DZD
     * * `NGN` - NGN
     * * `UYU` - UYU
     * * `SYP` - SYP
     * * `ADP` - ADP
     * * `XBC` - XBC
     * * `MLF` - MLF
     * * `XUA` - XUA
     * * `XXX` - XXX
     * * `ISK` - ISK
     * * `BDT` - BDT
     * * `NZD` - NZD
     * * `ETB` - ETB
     * * `HTG` - HTG
     * * `BEC` - BEC
     * * `SAR` - SAR
     * * `AZM` - AZM
     * * `KWD` - KWD
     * * `CLP` - CLP
     * * `SDD` - SDD
     * * `INR` - INR
     * * `ATS` - ATS
     * * `AWG` - AWG
     * * `IEP` - IEP
     * * `GNF` - GNF
     * * `ILS` - ILS
     * * `SDP` - SDP
     * * `XOF` - XOF
     * * `UGX` - UGX
     * * `GEL` - GEL
     * * `YER` - YER
     * * `BRZ` - BRZ
     * * `ZWR` - ZWR
     * * `TMT` - TMT
     * * `XBB` - XBB
     * * `CRC` - CRC
     * * `MGA` - MGA
     * * `BOV` - BOV
     * * `SLE` - SLE
     * * `CUC` - CUC
     * * `CSD` - CSD
     * * `AOA` - AOA
     * * `SDG` - SDG
     * * `UYP` - UYP
     * * `MNT` - MNT
     * * `BRB` - BRB
     * * `YUM` - YUM
     * * `ZAL` - ZAL
     * * `XEU` - XEU
     * * `VEF` - VEF
     * * `PKR` - PKR
     * * `BHD` - BHD
     * * `PLN` - PLN
     * * `ITL` - ITL
     * * `TND` - TND
     * * `MWK` - MWK
     * * `LUC` - LUC
     * * `MDC` - MDC
     * * `BMD` - BMD
     * * `QAR` - QAR
     * * `PEI` - PEI
     * * `FKP` - FKP
     * * `GQE` - GQE
     * * `XPF` - XPF
     * * `CHW` - CHW
     * * `BRR` - BRR
     * * `SHP` - SHP
     * * `CHE` - CHE
     * * `HRD` - HRD
     * * `BGM` - BGM
     * * `LVR` - LVR
     * * `CLE` - CLE
     * * `ZRN` - ZRN
     * * `DEM` - DEM
     * * `AOK` - AOK
     * * `XDR` - XDR
     * * `TPE` - TPE
     * * `ILR` - ILR
     * * `MRU` - MRU
     * * `KHR` - KHR
     * * `MXV` - MXV
     * * `HRK` - HRK
     * * `SBD` - SBD
     * * `YUR` - YUR
     * * `PES` - PES
     * * `GNS` - GNS
     * * `MKD` - MKD
     * * `PTE` - PTE
     * * `BOP` - BOP
     * * `BGO` - BGO
     * * `CDF` - CDF
     * * `BYN` - BYN
     * * `CLF` - CLF
     * * `LRD` - LRD
     * * `IRR` - IRR
     * * `MRO` - MRO
     * * `MUR` - MUR
     * * `VES` - VES
     * * `VEB` - VEB
     * * `RHD` - RHD
     * * `GBP` - GBP
     * * `TRL` - TRL
     * * `RWF` - RWF
     * * `ZRZ` - ZRZ
     * * `TJR` - TJR
     * * `CAD` - CAD
     * * `HNL` - HNL
     * * `LYD` - LYD
     * * `FIM` - FIM
     * * `ESA` - ESA
     * * `ESB` - ESB
     * * `GWE` - GWE
     * * `AOR` - AOR
     * * `AED` - AED
     * * `IQD` - IQD
     * * `KRO` - KRO
     * * `THB` - THB
     * * `WST` - WST
     * * `LAK` - LAK
     * * `MVR` - MVR
     * * `IDR` - IDR
     * * `MYR` - MYR
     * * `BEF` - BEF
     * * `AMD` - AMD
     * * `XCG` - XCG
     * * `MTP` - MTP
     * * `SCR` - SCR
     * * `KRH` - KRH
     * * `PHP` - PHP
     * * `TMM` - TMM
     * * `UZS` - UZS
     * * `ZMW` - ZMW
     * * `STN` - STN
     * * `XSU` - XSU
     * * `UYI` - UYI
     * * `BRC` - BRC
     * * `CZK` - CZK
     * * `GTQ` - GTQ
     * * `PGK` - PGK
     * * `CVE` - CVE
     * * `KES` - KES
     * * `XRE` - XRE
     * * `XBA` - XBA
     * * `VUV` - VUV
     * * `RON` - RON
     * * `ESP` - ESP
     * * `NLG` - NLG
     * * `CUP` - CUP
     * * `LKR` - LKR
     * * `XPT` - XPT
     * * `LUL` - LUL
     * * `TZS` - TZS
     * * `XPD` - XPD
     * * `DKK` - DKK
     * * `MZM` - MZM
     * * `MVP` - MVP
     * * `XAG` - XAG
     * * `PLZ` - PLZ
     * * `MTL` - MTL
     * * `BRL` - BRL
     * * `PYG` - PYG
     * * `CHF` - CHF
     * * `UAK` - UAK
     * * `COP` - COP
     * * `SOS` - SOS
     * * `MKN` - MKN
     * * `SZL` - SZL
     * * `ANG` - ANG
     * * `GIP` - GIP
     * * `ALK` - ALK
     * * `YUD` - YUD
     * * `OMR` - OMR
     * * `LSL` - LSL
     * * `BAD` - BAD
     * * `SKK` - SKK
     * * `BWP` - BWP
     * * `YDD` - YDD
     * * `ROL` - ROL
     * * `GMD` - GMD
     * * `BEL` - BEL
     * * `DOP` - DOP
     * * `KYD` - KYD
     * * `MAF` - MAF
     * * `MOP` - MOP
     * * `NAD` - NAD
     * * `RUR` - RUR
     * * `BAM` - BAM
     * * `BZD` - BZD
     * * `VND` - VND
     * * `XBD` - XBD
     * * `BIF` - BIF
     * * `SRD` - SRD
     * * `ARP` - ARP
     * * `FJD` - FJD
     * * `KRW` - KRW
     * * `JPY` - JPY
     * * `MGF` - MGF
     * * `MXN` - MXN
     * * `USN` - USN
     * * `BND` - BND
     * * `MZE` - MZE
     * * `GWP` - GWP
     * * `XAF` - XAF
     * * `BSD` - BSD
     * * `BBD` - BBD
     * * `UAH` - UAH
     * * `TRY` - TRY
     * * `BRN` - BRN
     * * `DDM` - DDM
     * * `ZWL` - ZWL
     * * `ILP` - ILP
     * * `RUB` - RUB
     * * `CSK` - CSK
     * * `SGD` - SGD
     * * `XFO` - XFO
     * * `EGP` - EGP
     * * `NIC` - NIC
     * * `NOK` - NOK
     * * `KMF` - KMF
     * * `NPR` - NPR
     * * `UYW` - UYW
     * * `KZT` - KZT
     * * `VED` - VED
     * * `BTN` - BTN
     * * `BAN` - BAN
     * * `ISJ` - ISJ
     * * `PEN` - PEN
     * * `ZMK` - ZMK
     * * `MXP` - MXP
     * * `MCF` - MCF
     * * `ARA` - ARA
     * * `GRD` - GRD
     * * `SRG` - SRG
     */
    currency: AllCurrencies;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    sum_to_pay_no_discount: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    discount_amount: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    total_shipping_cost: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    total_price: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    total_price_no_vat: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    total_shipping_cost_no_vat: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    total_vat: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    total_cost: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    total_additional_fees: string;
}

export interface BasketResponse {
    /** @format uuid */
    supplier_uuid: string;
    basket_prices: BasketPrices;
}

export enum BlankEnum {
    Value = "",
}

export interface BrandCreate {
    company_name: string;
    /** @format uri */
    website?: string | null;
    first_name: string;
    last_name: string;
    /** @format email */
    email: string;
    /** @maxLength 10 */
    referred_by?: string;
    phone_country_code?: PhoneCountryCodeEnum | BlankEnum | NullEnum | null;
    registration_number?: string | null;
    phone_number?: string | null;
    company_address: CompanyAddress;
    company_type: ProductTypeEnum[];
}

export interface BrandDashboardCount {
    /** @min 0 */
    active_requests: number;
    /** @min 0 */
    orders_in_progress: number;
    /** @min 0 */
    new_quotes_count: number;
}

export interface BrandInvoice {
    number: string;
    billed_to: string;
    /** @format date-time */
    billed_on: string;
    due_to: string;
    payment_due_date: string | null;
    payment_status: string;
    payment_details: PaymentDetailsInvoice | null;
    /**
     * * `HUF` - HUF
     * * `SVC` - SVC
     * * `MZN` - MZN
     * * `BOB` - BOB
     * * `GHS` - GHS
     * * `MAD` - MAD
     * * `USD` - USD
     * * `ECS` - ECS
     * * `AFA` - AFA
     * * `ECV` - ECV
     * * `ZAR` - ZAR
     * * `CNH` - CNH
     * * `GEK` - GEK
     * * `TWD` - TWD
     * * `FRF` - FRF
     * * `SUR` - SUR
     * * `VNN` - VNN
     * * `TTD` - TTD
     * * `ZWD` - ZWD
     * * `ALL` - ALL
     * * `LTL` - LTL
     * * `LVL` - LVL
     * * `JOD` - JOD
     * * `CNY` - CNY
     * * `LBP` - LBP
     * * `BYB` - BYB
     * * `ARM` - ARM
     * * `KPW` - KPW
     * * `CYP` - CYP
     * * `AZN` - AZN
     * * `LTT` - LTT
     * * `XTS` - XTS
     * * `BYR` - BYR
     * * `CNX` - CNX
     * * `HKD` - HKD
     * * `RSD` - RSD
     * * `BGN` - BGN
     * * `ERN` - ERN
     * * `EEK` - EEK
     * * `GHC` - GHC
     * * `TJS` - TJS
     * * `SLL` - SLL
     * * `ARL` - ARL
     * * `BUK` - BUK
     * * `ARS` - ARS
     * * `SSP` - SSP
     * * `TOP` - TOP
     * * `XCD` - XCD
     * * `SEK` - SEK
     * * `PAB` - PAB
     * * `XAU` - XAU
     * * `KGS` - KGS
     * * `DJF` - DJF
     * * `XFU` - XFU
     * * `SIT` - SIT
     * * `AON` - AON
     * * `EUR` - EUR
     * * `NIO` - NIO
     * * `BGL` - BGL
     * * `AFN` - AFN
     * * `MMK` - MMK
     * * `USS` - USS
     * * `BOL` - BOL
     * * `BRE` - BRE
     * * `AUD` - AUD
     * * `UGS` - UGS
     * * `LUF` - LUF
     * * `JMD` - JMD
     * * `COU` - COU
     * * `GYD` - GYD
     * * `STD` - STD
     * * `MDL` - MDL
     * * `YUN` - YUN
     * * `DZD` - DZD
     * * `NGN` - NGN
     * * `UYU` - UYU
     * * `SYP` - SYP
     * * `ADP` - ADP
     * * `XBC` - XBC
     * * `MLF` - MLF
     * * `XUA` - XUA
     * * `XXX` - XXX
     * * `ISK` - ISK
     * * `BDT` - BDT
     * * `NZD` - NZD
     * * `ETB` - ETB
     * * `HTG` - HTG
     * * `BEC` - BEC
     * * `SAR` - SAR
     * * `AZM` - AZM
     * * `KWD` - KWD
     * * `CLP` - CLP
     * * `SDD` - SDD
     * * `INR` - INR
     * * `ATS` - ATS
     * * `AWG` - AWG
     * * `IEP` - IEP
     * * `GNF` - GNF
     * * `ILS` - ILS
     * * `SDP` - SDP
     * * `XOF` - XOF
     * * `UGX` - UGX
     * * `GEL` - GEL
     * * `YER` - YER
     * * `BRZ` - BRZ
     * * `ZWR` - ZWR
     * * `TMT` - TMT
     * * `XBB` - XBB
     * * `CRC` - CRC
     * * `MGA` - MGA
     * * `BOV` - BOV
     * * `SLE` - SLE
     * * `CUC` - CUC
     * * `CSD` - CSD
     * * `AOA` - AOA
     * * `SDG` - SDG
     * * `UYP` - UYP
     * * `MNT` - MNT
     * * `BRB` - BRB
     * * `YUM` - YUM
     * * `ZAL` - ZAL
     * * `XEU` - XEU
     * * `VEF` - VEF
     * * `PKR` - PKR
     * * `BHD` - BHD
     * * `PLN` - PLN
     * * `ITL` - ITL
     * * `TND` - TND
     * * `MWK` - MWK
     * * `LUC` - LUC
     * * `MDC` - MDC
     * * `BMD` - BMD
     * * `QAR` - QAR
     * * `PEI` - PEI
     * * `FKP` - FKP
     * * `GQE` - GQE
     * * `XPF` - XPF
     * * `CHW` - CHW
     * * `BRR` - BRR
     * * `SHP` - SHP
     * * `CHE` - CHE
     * * `HRD` - HRD
     * * `BGM` - BGM
     * * `LVR` - LVR
     * * `CLE` - CLE
     * * `ZRN` - ZRN
     * * `DEM` - DEM
     * * `AOK` - AOK
     * * `XDR` - XDR
     * * `TPE` - TPE
     * * `ILR` - ILR
     * * `MRU` - MRU
     * * `KHR` - KHR
     * * `MXV` - MXV
     * * `HRK` - HRK
     * * `SBD` - SBD
     * * `YUR` - YUR
     * * `PES` - PES
     * * `GNS` - GNS
     * * `MKD` - MKD
     * * `PTE` - PTE
     * * `BOP` - BOP
     * * `BGO` - BGO
     * * `CDF` - CDF
     * * `BYN` - BYN
     * * `CLF` - CLF
     * * `LRD` - LRD
     * * `IRR` - IRR
     * * `MRO` - MRO
     * * `MUR` - MUR
     * * `VES` - VES
     * * `VEB` - VEB
     * * `RHD` - RHD
     * * `GBP` - GBP
     * * `TRL` - TRL
     * * `RWF` - RWF
     * * `ZRZ` - ZRZ
     * * `TJR` - TJR
     * * `CAD` - CAD
     * * `HNL` - HNL
     * * `LYD` - LYD
     * * `FIM` - FIM
     * * `ESA` - ESA
     * * `ESB` - ESB
     * * `GWE` - GWE
     * * `AOR` - AOR
     * * `AED` - AED
     * * `IQD` - IQD
     * * `KRO` - KRO
     * * `THB` - THB
     * * `WST` - WST
     * * `LAK` - LAK
     * * `MVR` - MVR
     * * `IDR` - IDR
     * * `MYR` - MYR
     * * `BEF` - BEF
     * * `AMD` - AMD
     * * `XCG` - XCG
     * * `MTP` - MTP
     * * `SCR` - SCR
     * * `KRH` - KRH
     * * `PHP` - PHP
     * * `TMM` - TMM
     * * `UZS` - UZS
     * * `ZMW` - ZMW
     * * `STN` - STN
     * * `XSU` - XSU
     * * `UYI` - UYI
     * * `BRC` - BRC
     * * `CZK` - CZK
     * * `GTQ` - GTQ
     * * `PGK` - PGK
     * * `CVE` - CVE
     * * `KES` - KES
     * * `XRE` - XRE
     * * `XBA` - XBA
     * * `VUV` - VUV
     * * `RON` - RON
     * * `ESP` - ESP
     * * `NLG` - NLG
     * * `CUP` - CUP
     * * `LKR` - LKR
     * * `XPT` - XPT
     * * `LUL` - LUL
     * * `TZS` - TZS
     * * `XPD` - XPD
     * * `DKK` - DKK
     * * `MZM` - MZM
     * * `MVP` - MVP
     * * `XAG` - XAG
     * * `PLZ` - PLZ
     * * `MTL` - MTL
     * * `BRL` - BRL
     * * `PYG` - PYG
     * * `CHF` - CHF
     * * `UAK` - UAK
     * * `COP` - COP
     * * `SOS` - SOS
     * * `MKN` - MKN
     * * `SZL` - SZL
     * * `ANG` - ANG
     * * `GIP` - GIP
     * * `ALK` - ALK
     * * `YUD` - YUD
     * * `OMR` - OMR
     * * `LSL` - LSL
     * * `BAD` - BAD
     * * `SKK` - SKK
     * * `BWP` - BWP
     * * `YDD` - YDD
     * * `ROL` - ROL
     * * `GMD` - GMD
     * * `BEL` - BEL
     * * `DOP` - DOP
     * * `KYD` - KYD
     * * `MAF` - MAF
     * * `MOP` - MOP
     * * `NAD` - NAD
     * * `RUR` - RUR
     * * `BAM` - BAM
     * * `BZD` - BZD
     * * `VND` - VND
     * * `XBD` - XBD
     * * `BIF` - BIF
     * * `SRD` - SRD
     * * `ARP` - ARP
     * * `FJD` - FJD
     * * `KRW` - KRW
     * * `JPY` - JPY
     * * `MGF` - MGF
     * * `MXN` - MXN
     * * `USN` - USN
     * * `BND` - BND
     * * `MZE` - MZE
     * * `GWP` - GWP
     * * `XAF` - XAF
     * * `BSD` - BSD
     * * `BBD` - BBD
     * * `UAH` - UAH
     * * `TRY` - TRY
     * * `BRN` - BRN
     * * `DDM` - DDM
     * * `ZWL` - ZWL
     * * `ILP` - ILP
     * * `RUB` - RUB
     * * `CSK` - CSK
     * * `SGD` - SGD
     * * `XFO` - XFO
     * * `EGP` - EGP
     * * `NIC` - NIC
     * * `NOK` - NOK
     * * `KMF` - KMF
     * * `NPR` - NPR
     * * `UYW` - UYW
     * * `KZT` - KZT
     * * `VED` - VED
     * * `BTN` - BTN
     * * `BAN` - BAN
     * * `ISJ` - ISJ
     * * `PEN` - PEN
     * * `ZMK` - ZMK
     * * `MXP` - MXP
     * * `MCF` - MCF
     * * `ARA` - ARA
     * * `GRD` - GRD
     * * `SRG` - SRG
     */
    currency: AllCurrencies;
    products: ProductDetails[];
    fees?: Fees[] | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    fees_sum: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    products_price: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    shipping_price: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    total_price: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    vat_sum: string;
}

export interface BrandOnboarding {
    /** @maxLength 255 */
    first_name: string;
    /** @maxLength 255 */
    last_name: string;
    /**
     * @format email
     * @maxLength 255
     */
    email: string;
    /** @maxLength 255 */
    password: string;
    /** @maxLength 255 */
    company_name: string;
    /** @maxLength 100 */
    state?: string;
    /** @maxLength 255 */
    website?: string;
    company_address: CompanyAddress;
    referred_by?: string;
    phone_country_code?: PhoneCountryCodeEnum | BlankEnum | NullEnum | null;
    registration_number?: string | null;
    phone_number?: string | null;
    /** @default ["h","r","e","o","t"] */
    product_type?: ProductTypeEnum[];
}

export interface BrandQuoteUpdate {
    /**
     * * `available` - Available
     * * `requested` - Requested
     * * `sent` - Sent
     * * `received` - Received
     * * `accepted` - Accepted
     * * `rejected` - Rejected
     * * `declined` - Declined
     */
    samples_status?: SamplesStatusEnum;
    /** @format uuid */
    samples_delivery_address?: string;
    samples_feedback?: SamplesFeedbackEnum[] | null;
    samples_feedback_comment?: string;
    /** @format date */
    samples_received_date?: string | null;
    /** @format date */
    samples_accepted_date?: string | null;
    /** @format date */
    samples_rejected_date?: string | null;
    /** @format date */
    samples_declined_date?: string | null;
    /** @format date */
    samples_requested_date?: string | null;
    /**
     * * `in_advance` - In Advance
     * * `on_dispatch` - On Dispatch
     * * `7_days_after_dispatch` - Days After Dispatch 7
     * * `14_days_after_dispatch` - Days After Dispatch 14
     * * `30_days_after_dispatch` - Days After Dispatch 30
     * * `60_days_after_dispatch` - Days After Dispatch 60
     */
    requested_payment_terms?: RequestedPaymentTermsEnum;
    extension_accepted?: boolean | null;
}

export interface BrandVatInvoiceData {
    /** @min 1 */
    invoice_id: number;
    brand_name: string;
    address_line_1: string | null;
    address_line_2: string | null;
    city: string | null;
    country: string | null;
    postal_code: string | null;
    payment_date: string;
    /** @format uuid */
    order_number: string;
    currency: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
     */
    total_price_commission: string;
    commission_payin_vat_rate_pct: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
     */
    total_price_commission_vat: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
     */
    total_price_commission_with_vat: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
     */
    fx_rate: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
     */
    total_price_fx_fee: string;
}

export interface BuyerSummaryRequest {
    /** @format uuid */
    uuid: string;
}

export interface BuyerSummaryResponse {
    product_type: ProductTypeEnum[];
    product_types_description?: string | null;
    revenue?: RevenueEnum | BlankEnum | NullEnum | null;
    location: DeliveryAddressForSupplier;
}

export interface CannyTokenResponse {
    token: string;
}

export interface Channel {
    /** @format uuid */
    uuid: string;
    /** @format date-time */
    new_messages_check?: string;
    companies: ChannelCompany[];
    latest_message: Message;
    related_object_type: string;
    /** @format uuid */
    related_object_uuid: string;
    object_history: ChannelObjectHistory[];
    buyer_name: string;
    supplier_name: string;
}

export interface ChannelCompany {
    /** @format uuid */
    uuid: string;
}

export interface ChannelExpanded {
    /** @format uuid */
    uuid: string;
    buyer_name: string;
    supplier_name: string;
}

export interface ChannelForMessage {
    /** @format uuid */
    uuid: string;
    /** @format date-time */
    new_messages_check?: string;
    companies: ChannelForMessageCompany[];
    related_object_type: string;
    /** @format uuid */
    related_object_uuid: string;
    name: string;
    /** @format uri */
    logo_url: string;
}

export interface ChannelForMessageCompany {
    /** @format uuid */
    uuid: string;
}

export interface ChannelObjectHistory {
    object_type: string;
    object_uuid: string;
    /** @format date-time */
    created: string;
    action?: ChannelObjectHistoryActionEnum | BlankEnum | NullEnum | null;
    data?: any;
}

/**
 * * `request_created` - Request Created
 * * `quote_created` - Quote Created
 * * `contract_created` - Contract Created
 * * `order_created` - Order Created
 * * `purchase_request_created` - Purchase Request Created
 * * `purchase_request_quote_created` - Purchase Request Quote Created
 * * `purchase_request_order_created` - Purchase Request Order Created
 */
export enum ChannelObjectHistoryActionEnum {
    RequestCreated = "request_created",
    QuoteCreated = "quote_created",
    ContractCreated = "contract_created",
    OrderCreated = "order_created",
    PurchaseRequestCreated = "purchase_request_created",
    PurchaseRequestQuoteCreated = "purchase_request_quote_created",
    PurchaseRequestOrderCreated = "purchase_request_order_created",
}

export interface ChannelUUID {
    /** @format uuid */
    uuid: string;
}

export interface CheckProducts {
    product_uuids: string[];
}

export interface Company {
    id: number;
    /** @format uuid */
    uuid: string;
    /** @maxLength 255 */
    website?: string | null;
    /** @maxLength 255 */
    name: string;
    /** @format uri */
    logo?: string | null;
    /** @maxLength 100 */
    industry?: string | null;
    kyb_status: string;
    /** @maxLength 50 */
    phone_number?: string | null;
    country: string;
    state: string;
    /**
     * * `brand` - Brand
     * * `supplier` - Supplier
     */
    company_type?: CompanyTypeEnum;
    /** @default ["h","r","e","o","t"] */
    product_type?: ProductTypeEnum[];
    /** @default ["h","r","e","o","t"] */
    activity_type?: ActivityTypeEnum[];
    /** @maxLength 255 */
    registration_number?: string | null;
    mongo_id: string | null;
    referral_code: string;
    phone_country_code?: PhoneCountryCodeEnum | BlankEnum | NullEnum | null;
    company_addresses?: CompanyAddress[];
    currency: string;
    /**
     * rapyd payout method
     * @maxLength 45
     */
    payout_method_type?: string | null;
    bank_account_country?: Country | BlankEnum | NullEnum | null;
}

export interface CompanyAddress {
    id: number;
    /** @format date-time */
    created: string;
    /** @format date-time */
    modified: string;
    /** @format uuid */
    uuid: string;
    /** @maxLength 255 */
    name: string;
    /**
     * * `billing_address` - Billing address
     * * `delivery_address` - Delivery address
     * * `dispatch_address` - Dispatch address
     * * `company_address` - Company address
     */
    address_type?: AddressTypeEnum;
    /** @maxLength 255 */
    contact_full_name?: string | null;
    /** @maxLength 50 */
    contact_phone?: string | null;
    /** @maxLength 100 */
    building?: string | null;
    /** @maxLength 255 */
    street_1?: string | null;
    /** @maxLength 255 */
    street_2?: string | null;
    /** @maxLength 100 */
    town?: string | null;
    /** @maxLength 100 */
    region?: string | null;
    /** @maxLength 100 */
    postcode?: string | null;
    country?: Country | BlankEnum | NullEnum | null;
    /** @maxLength 100 */
    place_id?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,4}(?:\.\d{0,15})?$
     */
    latitude?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,4}(?:\.\d{0,15})?$
     */
    longitude?: string | null;
    /** @maxLength 50 */
    vat_number?: string | null;
    vat_country_code?: Country | BlankEnum | NullEnum | null;
    vat_exempt?: boolean;
    phone_country_code?: PhoneCountryCodeEnum | BlankEnum | NullEnum | null;
    delivery_instructions?: string | null;
    collection_instructions?: string | null;
    /**
     * * `EU` - Eu
     * * `GB` - Gb
     * * `Other` - Other
     */
    country_type?: CountryTypeEnum;
    /** @maxLength 20 */
    eori_number?: string;
}

export interface CompanyAddressAdminCreate {
    id: number;
    /**
     * company uuid
     * @format uuid
     */
    company: string;
    /** @format date-time */
    created: string;
    /** @format date-time */
    modified: string;
    /** @format uuid */
    uuid: string;
    /** @maxLength 255 */
    name: string;
    /** @default "billing_address" */
    address_type?: AddressTypeEnum;
    /** @maxLength 255 */
    contact_full_name?: string | null;
    /** @maxLength 50 */
    contact_phone?: string | null;
    /** @maxLength 100 */
    building?: string | null;
    /** @maxLength 255 */
    street_1?: string | null;
    /** @maxLength 255 */
    street_2?: string | null;
    /** @maxLength 100 */
    town?: string | null;
    /** @maxLength 100 */
    region?: string | null;
    /** @maxLength 100 */
    postcode?: string | null;
    country?: Country | BlankEnum | NullEnum | null;
    /** @maxLength 100 */
    place_id?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,4}(?:\.\d{0,15})?$
     */
    latitude?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,4}(?:\.\d{0,15})?$
     */
    longitude?: string | null;
    /** @maxLength 50 */
    vat_number?: string | null;
    vat_country_code?: Country | BlankEnum | NullEnum | null;
    vat_exempt?: boolean;
    phone_country_code?: PhoneCountryCodeEnum | BlankEnum | NullEnum | null;
    delivery_instructions?: string | null;
    collection_instructions?: string | null;
    /**
     * * `EU` - Eu
     * * `GB` - Gb
     * * `Other` - Other
     */
    country_type?: CountryTypeEnum;
    /** @maxLength 20 */
    eori_number?: string;
}

export interface CompanyDetailsCreate {
    company_name?: string | null;
    registration_number?: string | null;
    /**
     * @format email
     * @maxLength 254
     */
    email?: string | null;
    phone_country_code?: string | null;
    phone_number?: string | null;
    company_type?: KriyaCompanyType | BlankEnum | NullEnum | null;
    billing_address?: number | null;
}

export interface CompanyDetailsRetrieve {
    company: number;
    company_name: string;
    registration_number: string;
    /**
     * @format email
     * @maxLength 254
     */
    email?: string | null;
    phone_country_code: string;
    phone_number: string;
    company_type?: KriyaCompanyType | BlankEnum | NullEnum | null;
    billing_address?: number | null;
    decision_status?: DecisionStatusEnum | BlankEnum | NullEnum | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    total_limit?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    available_limit?: string | null;
}

export interface CompanyDocument {
    /** @format uuid */
    uuid: string;
    /** @format uri */
    document: string;
    description?: string | null;
    /**
     * * `products_list` - Products List
     * * `ingredients_list` - Ingredients List
     * * `other` - Other
     */
    document_category?: CompanyDocumentCategory;
}

/**
 * * `products_list` - Products List
 * * `ingredients_list` - Ingredients List
 * * `other` - Other
 */
export enum CompanyDocumentCategory {
    ProductsList = "products_list",
    IngredientsList = "ingredients_list",
    Other = "other",
}

export interface CompanyInfo {
    id: number;
    /** @format uuid */
    uuid: string;
    /** @maxLength 255 */
    website?: string | null;
    /** @maxLength 255 */
    name: string;
    /** @format uri */
    logo?: string | null;
    /** @maxLength 100 */
    industry?: string | null;
    kyb_status: string;
    /** @maxLength 50 */
    phone_number?: string | null;
    country: string;
    state: string;
    /**
     * * `brand` - Brand
     * * `supplier` - Supplier
     */
    company_type?: CompanyTypeEnum;
    /** @default ["h","r","e","o","t"] */
    product_type?: ProductTypeEnum[];
    /** @default ["h","r","e","o","t"] */
    activity_type?: ActivityTypeEnum[];
    /** @maxLength 255 */
    registration_number?: string | null;
    /** @maxLength 50 */
    mongo_id?: string | null;
    /** @maxLength 10 */
    referral_code?: string;
    phone_country_code?: PhoneCountryCodeEnum | BlankEnum | NullEnum | null;
    company_addresses: string;
    currency: string;
    /**
     * rapyd payout method
     * @maxLength 45
     */
    payout_method_type?: string | null;
    bank_account_country?: Country | BlankEnum | NullEnum | null;
    questionnaire_submitted?: boolean | null;
}

export interface CompanyInquiryAdmin {
    /** @maxLength 255 */
    name: string;
    /** @format date-time */
    created: string;
    suppliers_found: string;
    number_of_quotes: string;
    /**
     * * `awaiting_quotes` - Awaiting Quotes
     * * `quotes_available` - Quotes Available
     * * `ordered` - Ordered
     * * `expired` - Expired
     * * `proceeded` - Proceeded
     * * `contract_confirmed` - Contract Confirmed
     * * `supplier_decline_to_quote` - Supplier Decline To Quote
     * * `cancelled` - Cancelled
     */
    state?: InquiryState;
}

export interface CompanyInvite {
    id: number;
    contact_first_name: string;
    contact_last_name: string;
    company_name: string;
    /** @format email */
    contact_email: string;
    /**
     * * `brand` - Brand
     * * `supplier` - Supplier
     */
    referred_company_type: CompanyTypeEnum;
    signup_link: string;
}

export interface CompanyMembersList {
    /** @maxLength 30 */
    first_name: string;
    /** @maxLength 30 */
    last_name: string;
    /** @format email */
    email: string;
    /**
     * * `pending` - Pending
     * * `accepted` - Accepted
     * * `owner` - Owner
     */
    status: CompanyMembersListStatusEnum;
}

/**
 * * `pending` - Pending
 * * `accepted` - Accepted
 * * `owner` - Owner
 */
export enum CompanyMembersListStatusEnum {
    Pending = "pending",
    Accepted = "accepted",
    Owner = "owner",
}

export interface CompanyNameAndId {
    id: number;
    /** @format uuid */
    uuid: string;
    /** @maxLength 255 */
    name: string;
}

/**
 * * `brand` - Brand
 * * `supplier` - Supplier
 */
export enum CompanyTypeEnum {
    Brand = "brand",
    Supplier = "supplier",
}

export interface CompanyUserAdmin {
    /** @format uuid */
    uuid: string;
    /** @maxLength 150 */
    first_name?: string;
    /** @maxLength 150 */
    last_name?: string;
    /**
     * @format email
     * @maxLength 254
     */
    email: string;
    /** @maxLength 255 */
    job_title?: string | null;
    last_login: string;
}

export interface ConnectedStatusResponse {
    connected: boolean;
}

export interface ContractRenewNegotiation {
    /** @format uuid */
    uuid: string;
    /** @format uuid */
    company_uuid?: string;
    /**
     * Duration of the contract in months.
     * @min -2147483648
     * @max 2147483647
     */
    contract_length?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    quantity_per_order: number;
    /**
     * @format decimal
     * @pattern ^-?\d{0,17}(?:\.\d{0,2})?$
     */
    total_forecast_volume?: string;
    /** @format date */
    first_order_delivery_date?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,10})?$
     */
    unit_price?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    price_per_order?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    shipping_cost_per_order?: string | null;
    /**
     * * `created` - Created
     * * `accepted` - Accepted
     * * `declined` - Declined
     * * `completed` - Completed
     */
    status?: QuoteNegotiationStatus;
    /** @format date */
    declined_date?: string | null;
    /** @format date */
    accepted_date?: string | null;
    /** @format date */
    confirmed_date?: string | null;
    /** @format uuid */
    new_contract_uuid?: string | null;
    /** @format date-time */
    created: string;
    /** @format date-time */
    modified: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    payout_per_unit_no_shipping?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    sum_payout_no_shipping?: string;
}

export interface ContractRenewNegotiationDecline {
    decline_reason?: string | null;
}

/**
 * * `AF` - Afghanistan
 * * `AX` - Åland Islands
 * * `AL` - Albania
 * * `DZ` - Algeria
 * * `AS` - American Samoa
 * * `AD` - Andorra
 * * `AO` - Angola
 * * `AI` - Anguilla
 * * `AQ` - Antarctica
 * * `AG` - Antigua and Barbuda
 * * `AR` - Argentina
 * * `AM` - Armenia
 * * `AW` - Aruba
 * * `AU` - Australia
 * * `AT` - Austria
 * * `AZ` - Azerbaijan
 * * `BS` - Bahamas
 * * `BH` - Bahrain
 * * `BD` - Bangladesh
 * * `BB` - Barbados
 * * `BY` - Belarus
 * * `BE` - Belgium
 * * `BZ` - Belize
 * * `BJ` - Benin
 * * `BM` - Bermuda
 * * `BT` - Bhutan
 * * `BO` - Bolivia
 * * `BQ` - Bonaire, Sint Eustatius and Saba
 * * `BA` - Bosnia and Herzegovina
 * * `BW` - Botswana
 * * `BV` - Bouvet Island
 * * `BR` - Brazil
 * * `IO` - British Indian Ocean Territory
 * * `BN` - Brunei
 * * `BG` - Bulgaria
 * * `BF` - Burkina Faso
 * * `BI` - Burundi
 * * `CV` - Cabo Verde
 * * `KH` - Cambodia
 * * `CM` - Cameroon
 * * `CA` - Canada
 * * `KY` - Cayman Islands
 * * `CF` - Central African Republic
 * * `TD` - Chad
 * * `CL` - Chile
 * * `CN` - China
 * * `CX` - Christmas Island
 * * `CC` - Cocos (Keeling) Islands
 * * `CO` - Colombia
 * * `KM` - Comoros
 * * `CG` - Congo
 * * `CD` - Congo (the Democratic Republic of the)
 * * `CK` - Cook Islands
 * * `CR` - Costa Rica
 * * `CI` - Côte d'Ivoire
 * * `HR` - Croatia
 * * `CU` - Cuba
 * * `CW` - Curaçao
 * * `CY` - Cyprus
 * * `CZ` - Czechia
 * * `DK` - Denmark
 * * `DJ` - Djibouti
 * * `DM` - Dominica
 * * `DO` - Dominican Republic
 * * `EC` - Ecuador
 * * `EG` - Egypt
 * * `SV` - El Salvador
 * * `GQ` - Equatorial Guinea
 * * `ER` - Eritrea
 * * `EE` - Estonia
 * * `SZ` - Eswatini
 * * `ET` - Ethiopia
 * * `FK` - Falkland Islands (Malvinas)
 * * `FO` - Faroe Islands
 * * `FJ` - Fiji
 * * `FI` - Finland
 * * `FR` - France
 * * `GF` - French Guiana
 * * `PF` - French Polynesia
 * * `TF` - French Southern Territories
 * * `GA` - Gabon
 * * `GM` - Gambia
 * * `GE` - Georgia
 * * `DE` - Germany
 * * `GH` - Ghana
 * * `GI` - Gibraltar
 * * `GR` - Greece
 * * `GL` - Greenland
 * * `GD` - Grenada
 * * `GP` - Guadeloupe
 * * `GU` - Guam
 * * `GT` - Guatemala
 * * `GG` - Guernsey
 * * `GN` - Guinea
 * * `GW` - Guinea-Bissau
 * * `GY` - Guyana
 * * `HT` - Haiti
 * * `HM` - Heard Island and McDonald Islands
 * * `VA` - Holy See
 * * `HN` - Honduras
 * * `HK` - Hong Kong
 * * `HU` - Hungary
 * * `IS` - Iceland
 * * `IN` - India
 * * `ID` - Indonesia
 * * `IR` - Iran
 * * `IQ` - Iraq
 * * `IE` - Ireland
 * * `IM` - Isle of Man
 * * `IL` - Israel
 * * `IT` - Italy
 * * `JM` - Jamaica
 * * `JP` - Japan
 * * `JE` - Jersey
 * * `JO` - Jordan
 * * `KZ` - Kazakhstan
 * * `KE` - Kenya
 * * `KI` - Kiribati
 * * `KW` - Kuwait
 * * `KG` - Kyrgyzstan
 * * `LA` - Laos
 * * `LV` - Latvia
 * * `LB` - Lebanon
 * * `LS` - Lesotho
 * * `LR` - Liberia
 * * `LY` - Libya
 * * `LI` - Liechtenstein
 * * `LT` - Lithuania
 * * `LU` - Luxembourg
 * * `MO` - Macao
 * * `MG` - Madagascar
 * * `MW` - Malawi
 * * `MY` - Malaysia
 * * `MV` - Maldives
 * * `ML` - Mali
 * * `MT` - Malta
 * * `MH` - Marshall Islands
 * * `MQ` - Martinique
 * * `MR` - Mauritania
 * * `MU` - Mauritius
 * * `YT` - Mayotte
 * * `MX` - Mexico
 * * `FM` - Micronesia
 * * `MD` - Moldova
 * * `MC` - Monaco
 * * `MN` - Mongolia
 * * `ME` - Montenegro
 * * `MS` - Montserrat
 * * `MA` - Morocco
 * * `MZ` - Mozambique
 * * `MM` - Myanmar
 * * `NA` - Namibia
 * * `NR` - Nauru
 * * `NP` - Nepal
 * * `NL` - Netherlands
 * * `NC` - New Caledonia
 * * `NZ` - New Zealand
 * * `NI` - Nicaragua
 * * `NE` - Niger
 * * `NG` - Nigeria
 * * `NU` - Niue
 * * `NF` - Norfolk Island
 * * `KP` - North Korea
 * * `MK` - North Macedonia
 * * `XI` - Northern Ireland
 * * `MP` - Northern Mariana Islands
 * * `NO` - Norway
 * * `OM` - Oman
 * * `PK` - Pakistan
 * * `PW` - Palau
 * * `PS` - Palestine, State of
 * * `PA` - Panama
 * * `PG` - Papua New Guinea
 * * `PY` - Paraguay
 * * `PE` - Peru
 * * `PH` - Philippines
 * * `PN` - Pitcairn
 * * `PL` - Poland
 * * `PT` - Portugal
 * * `PR` - Puerto Rico
 * * `QA` - Qatar
 * * `RE` - Réunion
 * * `RO` - Romania
 * * `RU` - Russia
 * * `RW` - Rwanda
 * * `BL` - Saint Barthélemy
 * * `SH` - Saint Helena, Ascension and Tristan da Cunha
 * * `KN` - Saint Kitts and Nevis
 * * `LC` - Saint Lucia
 * * `MF` - Saint Martin (French part)
 * * `PM` - Saint Pierre and Miquelon
 * * `VC` - Saint Vincent and the Grenadines
 * * `WS` - Samoa
 * * `SM` - San Marino
 * * `ST` - Sao Tome and Principe
 * * `SA` - Saudi Arabia
 * * `SN` - Senegal
 * * `RS` - Serbia
 * * `SC` - Seychelles
 * * `SL` - Sierra Leone
 * * `SG` - Singapore
 * * `SX` - Sint Maarten (Dutch part)
 * * `SK` - Slovakia
 * * `SI` - Slovenia
 * * `SB` - Solomon Islands
 * * `SO` - Somalia
 * * `ZA` - South Africa
 * * `GS` - South Georgia and the South Sandwich Islands
 * * `KR` - South Korea
 * * `SS` - South Sudan
 * * `ES` - Spain
 * * `LK` - Sri Lanka
 * * `SD` - Sudan
 * * `SR` - Suriname
 * * `SJ` - Svalbard and Jan Mayen
 * * `SE` - Sweden
 * * `CH` - Switzerland
 * * `SY` - Syria
 * * `TW` - Taiwan
 * * `TJ` - Tajikistan
 * * `TZ` - Tanzania
 * * `TH` - Thailand
 * * `TL` - Timor-Leste
 * * `TG` - Togo
 * * `TK` - Tokelau
 * * `TO` - Tonga
 * * `TT` - Trinidad and Tobago
 * * `TN` - Tunisia
 * * `TR` - Türkiye
 * * `TM` - Turkmenistan
 * * `TC` - Turks and Caicos Islands
 * * `TV` - Tuvalu
 * * `UG` - Uganda
 * * `UA` - Ukraine
 * * `AE` - United Arab Emirates
 * * `GB` - United Kingdom
 * * `UM` - United States Minor Outlying Islands
 * * `US` - United States of America
 * * `UY` - Uruguay
 * * `UZ` - Uzbekistan
 * * `VU` - Vanuatu
 * * `VE` - Venezuela
 * * `VN` - Vietnam
 * * `VG` - Virgin Islands (British)
 * * `VI` - Virgin Islands (U.S.)
 * * `WF` - Wallis and Futuna
 * * `EH` - Western Sahara
 * * `YE` - Yemen
 * * `ZM` - Zambia
 * * `ZW` - Zimbabwe
 */
export enum Country {
    AF = "AF",
    AX = "AX",
    AL = "AL",
    DZ = "DZ",
    AS = "AS",
    AD = "AD",
    AO = "AO",
    AI = "AI",
    AQ = "AQ",
    AG = "AG",
    AR = "AR",
    AM = "AM",
    AW = "AW",
    AU = "AU",
    AT = "AT",
    AZ = "AZ",
    BS = "BS",
    BH = "BH",
    BD = "BD",
    BB = "BB",
    BY = "BY",
    BE = "BE",
    BZ = "BZ",
    BJ = "BJ",
    BM = "BM",
    BT = "BT",
    BO = "BO",
    BQ = "BQ",
    BA = "BA",
    BW = "BW",
    BV = "BV",
    BR = "BR",
    IO = "IO",
    BN = "BN",
    BG = "BG",
    BF = "BF",
    BI = "BI",
    CV = "CV",
    KH = "KH",
    CM = "CM",
    CA = "CA",
    KY = "KY",
    CF = "CF",
    TD = "TD",
    CL = "CL",
    CN = "CN",
    CX = "CX",
    CC = "CC",
    CO = "CO",
    KM = "KM",
    CG = "CG",
    CD = "CD",
    CK = "CK",
    CR = "CR",
    CI = "CI",
    HR = "HR",
    CU = "CU",
    CW = "CW",
    CY = "CY",
    CZ = "CZ",
    DK = "DK",
    DJ = "DJ",
    DM = "DM",
    DO = "DO",
    EC = "EC",
    EG = "EG",
    SV = "SV",
    GQ = "GQ",
    ER = "ER",
    EE = "EE",
    SZ = "SZ",
    ET = "ET",
    FK = "FK",
    FO = "FO",
    FJ = "FJ",
    FI = "FI",
    FR = "FR",
    GF = "GF",
    PF = "PF",
    TF = "TF",
    GA = "GA",
    GM = "GM",
    GE = "GE",
    DE = "DE",
    GH = "GH",
    GI = "GI",
    GR = "GR",
    GL = "GL",
    GD = "GD",
    GP = "GP",
    GU = "GU",
    GT = "GT",
    GG = "GG",
    GN = "GN",
    GW = "GW",
    GY = "GY",
    HT = "HT",
    HM = "HM",
    VA = "VA",
    HN = "HN",
    HK = "HK",
    HU = "HU",
    IS = "IS",
    IN = "IN",
    ID = "ID",
    IR = "IR",
    IQ = "IQ",
    IE = "IE",
    IM = "IM",
    IL = "IL",
    IT = "IT",
    JM = "JM",
    JP = "JP",
    JE = "JE",
    JO = "JO",
    KZ = "KZ",
    KE = "KE",
    KI = "KI",
    KW = "KW",
    KG = "KG",
    LA = "LA",
    LV = "LV",
    LB = "LB",
    LS = "LS",
    LR = "LR",
    LY = "LY",
    LI = "LI",
    LT = "LT",
    LU = "LU",
    MO = "MO",
    MG = "MG",
    MW = "MW",
    MY = "MY",
    MV = "MV",
    ML = "ML",
    MT = "MT",
    MH = "MH",
    MQ = "MQ",
    MR = "MR",
    MU = "MU",
    YT = "YT",
    MX = "MX",
    FM = "FM",
    MD = "MD",
    MC = "MC",
    MN = "MN",
    ME = "ME",
    MS = "MS",
    MA = "MA",
    MZ = "MZ",
    MM = "MM",
    NA = "NA",
    NR = "NR",
    NP = "NP",
    NL = "NL",
    NC = "NC",
    NZ = "NZ",
    NI = "NI",
    NE = "NE",
    NG = "NG",
    NU = "NU",
    NF = "NF",
    KP = "KP",
    MK = "MK",
    XI = "XI",
    MP = "MP",
    NO = "NO",
    OM = "OM",
    PK = "PK",
    PW = "PW",
    PS = "PS",
    PA = "PA",
    PG = "PG",
    PY = "PY",
    PE = "PE",
    PH = "PH",
    PN = "PN",
    PL = "PL",
    PT = "PT",
    PR = "PR",
    QA = "QA",
    RE = "RE",
    RO = "RO",
    RU = "RU",
    RW = "RW",
    BL = "BL",
    SH = "SH",
    KN = "KN",
    LC = "LC",
    MF = "MF",
    PM = "PM",
    VC = "VC",
    WS = "WS",
    SM = "SM",
    ST = "ST",
    SA = "SA",
    SN = "SN",
    RS = "RS",
    SC = "SC",
    SL = "SL",
    SG = "SG",
    SX = "SX",
    SK = "SK",
    SI = "SI",
    SB = "SB",
    SO = "SO",
    ZA = "ZA",
    GS = "GS",
    KR = "KR",
    SS = "SS",
    ES = "ES",
    LK = "LK",
    SD = "SD",
    SR = "SR",
    SJ = "SJ",
    SE = "SE",
    CH = "CH",
    SY = "SY",
    TW = "TW",
    TJ = "TJ",
    TZ = "TZ",
    TH = "TH",
    TL = "TL",
    TG = "TG",
    TK = "TK",
    TO = "TO",
    TT = "TT",
    TN = "TN",
    TR = "TR",
    TM = "TM",
    TC = "TC",
    TV = "TV",
    UG = "UG",
    UA = "UA",
    AE = "AE",
    GB = "GB",
    UM = "UM",
    US = "US",
    UY = "UY",
    UZ = "UZ",
    VU = "VU",
    VE = "VE",
    VN = "VN",
    VG = "VG",
    VI = "VI",
    WF = "WF",
    EH = "EH",
    YE = "YE",
    ZM = "ZM",
    ZW = "ZW",
}

/**
 * * `EU` - Eu
 * * `GB` - Gb
 * * `Other` - Other
 */
export enum CountryTypeEnum {
    EU = "EU",
    GB = "GB",
    Other = "Other",
}

export interface CreateUser {
    id: number;
    /** @maxLength 128 */
    password: string;
    /** @maxLength 150 */
    first_name?: string;
    /** @maxLength 150 */
    last_name?: string;
    /**
     * @format email
     * @maxLength 254
     */
    email: string;
    /** @maxLength 128 */
    phone_number?: string | null;
    auth_token: string;
}

/**
 * * `EUR` - EUR
 * * `USD` - USD
 * * `GBP` - GBP
 * * `CAD` - CAD
 */
export enum Currencies {
    EUR = "EUR",
    USD = "USD",
    GBP = "GBP",
    CAD = "CAD",
}

export interface Customer {
    delivery_address?: CustomerAddress;
    billing_address?: CustomerAddress;
    /** @format date-time */
    created: string;
    /** @format date-time */
    modified: string;
    /** @format uuid */
    uuid: string;
    /** @maxLength 255 */
    company_name?: string;
    /** @maxLength 255 */
    contact_name?: string | null;
    /**
     * @format email
     * @maxLength 254
     */
    email?: string | null;
    /** @maxLength 50 */
    phone?: string | null;
}

export interface CustomerAddress {
    id: number;
    /** @format date-time */
    created: string;
    /** @format date-time */
    modified: string;
    /** @format uuid */
    uuid: string;
    /** @maxLength 255 */
    name: string;
    /**
     * * `billing_address` - Billing address
     * * `delivery_address` - Delivery address
     * * `dispatch_address` - Dispatch address
     * * `company_address` - Company address
     */
    address_type?: AddressTypeEnum;
    /** @maxLength 255 */
    contact_full_name?: string | null;
    /** @maxLength 50 */
    contact_phone?: string | null;
    /** @maxLength 100 */
    building?: string | null;
    /** @maxLength 255 */
    street_1?: string | null;
    /** @maxLength 255 */
    street_2?: string | null;
    /** @maxLength 100 */
    town?: string | null;
    /** @maxLength 100 */
    region?: string | null;
    /** @maxLength 100 */
    postcode?: string | null;
    country?: Country | BlankEnum | NullEnum | null;
    /** @maxLength 100 */
    place_id?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,4}(?:\.\d{0,15})?$
     */
    latitude?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,4}(?:\.\d{0,15})?$
     */
    longitude?: string | null;
    /** @maxLength 50 */
    vat_number?: string | null;
    vat_country_code?: Country | BlankEnum | NullEnum | null;
    vat_exempt?: boolean;
    phone_country_code?: PhoneCountryCodeEnum | BlankEnum | NullEnum | null;
    delivery_instructions?: string | null;
    collection_instructions?: string | null;
    /**
     * * `EU` - Eu
     * * `GB` - Gb
     * * `Other` - Other
     */
    country_type?: CountryTypeEnum;
    /** @maxLength 20 */
    eori_number?: string;
}

export interface DashboardContractsList {
    /** @format uuid */
    uuid: string;
    /** @format uuid */
    inquiry_uuid: string;
    /** @maxLength 255 */
    product_name: string;
    /**
     * @format uri
     * @maxLength 2048
     */
    logo_url?: string;
    /**
     * * `available` - available
     * * `ordered` - ordered
     * * `expired` - expired
     * * `declined` - declined
     * * `pending_payment` - pending_payment
     * * `proceeded` - proceeded
     * * `contract_created` - Contract Created
     * * `contract_proceeded` - contract_proceeded
     * * `contract_confirmed` - contract_confirmed
     */
    status?: QuoteState;
    total_forecast_volume: string;
    ordered_volume: number;
    is_new: boolean;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement?: UnitOfMeasurementEnum;
    is_pending: boolean;
    days_left: number;
    quantity_remaining: number;
    contract_status: string;
    is_filled: boolean;
    agreement_required?: boolean;
    has_pending_purchase_request: boolean;
    contract_progress_status: string;
}

export interface DashboardInquiries {
    /** @format uuid */
    uuid: string;
    /** @maxLength 255 */
    name: string;
    /**
     * @format uri
     * @maxLength 2048
     */
    logo_url?: string;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    currency?: Currencies;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement?: UnitOfMeasurementEnum;
    total_quotes_count: number;
    new_quotes_count: number;
    /** @format double */
    lowest_unit_price: number;
    savings_percentage: number;
    is_new: boolean;
    /** @format uuid */
    original_quote_uuid: string;
    ai_generated?: boolean;
}

export interface DashboardInquiriesForSupplier {
    /** @format uuid */
    uuid: string;
    /** @maxLength 255 */
    name: string;
    /**
     * @format uri
     * @maxLength 2048
     */
    logo_url?: string;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    currency?: Currencies;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement?: UnitOfMeasurementEnum;
    /**
     * * `non_recurring` - Non Recurring
     * * `recurring` - Recurring
     * * `purchase_request` - Purchase Request
     * * `ingredient` - Ingredient
     * * `ingredient_copy` - Ingredient Copy
     * * `offline_inquiry` - Offline Inquiry
     * * `sales_inquiry` - Sales Inquiry
     */
    category?: InquiryCategory;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    quantity: string;
    total_forecast_quantity: string;
    /** @format date */
    first_order_delivery_date?: string | null;
    is_new: boolean;
    /** @format date */
    due_date: string;
    /** @format uuid */
    original_quote_uuid: string;
}

export interface DashboardOrders {
    /** @format uuid */
    uuid: string;
    order_number: string;
    /** @format date */
    quote_delivery_date: string;
    /**
     * * `pending_dispatch` - pending_dispatch
     * * `dispatched` - Dispatched
     * * `delivered` - Delivered
     */
    shipment_status?: ShipmentStatusEnum;
    is_new: boolean;
}

/**
 * * `Submitted` - Submitted
 * * `InReview` - In Review
 * * `Approved` - Approved
 * * `Rejected` - Rejected
 * * `OnHold` - Oh Hold
 */
export enum DecisionStatusEnum {
    Submitted = "Submitted",
    InReview = "InReview",
    Approved = "Approved",
    Rejected = "Rejected",
    OnHold = "OnHold",
}

export interface DeliveryAddressForSupplier {
    /** @maxLength 100 */
    town?: string | null;
    country?: Country | BlankEnum | NullEnum | null;
    /** @maxLength 100 */
    postcode?: string | null;
}

export interface DeliveryAddressResponse {
    /** @maxLength 100 */
    street_1?: string | null;
    /** @maxLength 100 */
    street_2?: string | null;
    /** @maxLength 100 */
    postcode?: string | null;
    /** @maxLength 100 */
    town?: string | null;
    /** @maxLength 100 */
    region?: string | null;
    country?: Country | BlankEnum | NullEnum | null;
}

/**
 * * `kosher` - Kosher
 * * `halal` - Halal
 * * `organic` - Organic
 * * `vegan` - Vegan
 * * `vegetarian` - Vegetarian
 */
export enum DietRequirementsEnum {
    Kosher = "kosher",
    Halal = "halal",
    Organic = "organic",
    Vegan = "vegan",
    Vegetarian = "vegetarian",
}

export interface Directors {
    id: number;
    company: number;
    /** @maxLength 255 */
    first_name: string;
    /** @maxLength 255 */
    last_name: string;
    /** @format date */
    date_of_birth: string;
    residential_address: KriyaKYBAddress;
}

export interface DuplicateQuote {
    /** @format date */
    delivery_date: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
     */
    quantity: string;
    delivery_address?: number | null;
}

export interface EmailInviteResponse {
    id: number;
    /** @format date-time */
    created: string;
    /** @format date-time */
    modified: string;
    /** @format uuid */
    uuid: string;
    /** @maxLength 30 */
    first_name: string;
    /** @maxLength 30 */
    last_name: string;
    /**
     * @format email
     * @maxLength 254
     */
    email: string;
    /**
     * * `pending` - Pending
     * * `accepted` - Accepted
     */
    status?: EmailInviteResponseStatusEnum;
    company: number;
}

/**
 * * `pending` - Pending
 * * `accepted` - Accepted
 */
export enum EmailInviteResponseStatusEnum {
    Pending = "pending",
    Accepted = "accepted",
}

export interface EmailNotifications {
    /** @format uuid */
    uuid: string;
    /** @maxLength 255 */
    title?: string | null;
    /**
     * * `b_s_new_message` - b_s_new_message
     * * `b_s_daily_message_summary` - b_s_daily_message_summary
     * * `b_payment_confirmation` - b_payment_confirmation
     * * `b_payment_due` - b_payment_due
     * * `b_payment_reminder` - b_payment_reminder
     * * `s_payout_complete` - s_payout_complete
     * * `b_purchase_request_accepted` - b_purchase_request_accepted
     * * `s_new_purchase_request` - s_new_purchase_request
     * * `b_dispatch_notification` - b_dispatch_notification
     * * `b_new_quote_received` - b_new_quote_received
     * * `s_quote_accepted` - s_quote_accepted
     * * `s_new_request` - s_new_request
     * * `b_order_confirmations` - b_order_confirmations
     * * `s_dispatch_reminders` - s_dispatch_reminders
     * * `s_samples_requested` - s_samples_requested
     * * `b_quote_available_to_order` - b_quote_available_to_order
     * * `b_purchase_request_declined` - b_purchase_request_declined
     * * `s_quote_negotiations_started` - s_quote_negotiations_started
     * * `b_quote_negotiation_responses` - b_quote_negotiation_responses
     * * `b_products_list_uploaded` - b_products_list_uploaded
     * * `b_ingredients_list_uploaded` - b_ingredients_list_uploaded
     * * `s_reminder_to_quote` - s_reminder_to_quote
     * * `b_quote_expiry_reminder` - b_quote_expiry_reminder
     * * `b_samples_dispatched` - b_samples_dispatched
     * * `s_supplier_remind_to_quote` - s_supplier_remind_to_quote
     * * `b_review_quotes` - b_review_quotes
     * * `s_samples_delivered` - s_samples_delivered
     * * `b_samples_feedback` - b_samples_feedback
     * * `s_reorder_request_created` - s_reorder_request_created
     * * `b_reorder_request_accepted` - b_reorder_request_accepted
     * * `b_reorder_request_declined` - b_reorder_request_declined
     * * `s_order_delivered` - s_order_delivered
     * * `s_purchase_order_cancelled` - s_purchase_order_cancelled
     * * `s_payout_confirmation` - s_payout_confirmation
     * * `b_contract_confirmed` - b_contract_confirmed
     * * `s_contract_confirmed` - s_contract_confirmed
     * * `s_create_contract` - s_create_contract
     * * `b_upload_signed_agreement` - b_upload_signed_agreement
     * * `s_purchase_order_edited` - s_purchase_order_edited
     * * `s_quote_declined` - s_quote_declined
     * * `s_requested_updated_quote` - s_requested_updated_quote
     * * `b_quote_updated` - b_quote_updated
     */
    type: EmailNotificationsTypeEnum;
    /** @maxLength 255 */
    description?: string | null;
    is_activated_for_user: boolean;
}

/**
 * * `b_s_new_message` - b_s_new_message
 * * `b_s_daily_message_summary` - b_s_daily_message_summary
 * * `b_payment_confirmation` - b_payment_confirmation
 * * `b_payment_due` - b_payment_due
 * * `b_payment_reminder` - b_payment_reminder
 * * `s_payout_complete` - s_payout_complete
 * * `b_purchase_request_accepted` - b_purchase_request_accepted
 * * `s_new_purchase_request` - s_new_purchase_request
 * * `b_dispatch_notification` - b_dispatch_notification
 * * `b_new_quote_received` - b_new_quote_received
 * * `s_quote_accepted` - s_quote_accepted
 * * `s_new_request` - s_new_request
 * * `b_order_confirmations` - b_order_confirmations
 * * `s_dispatch_reminders` - s_dispatch_reminders
 * * `s_samples_requested` - s_samples_requested
 * * `b_quote_available_to_order` - b_quote_available_to_order
 * * `b_purchase_request_declined` - b_purchase_request_declined
 * * `s_quote_negotiations_started` - s_quote_negotiations_started
 * * `b_quote_negotiation_responses` - b_quote_negotiation_responses
 * * `b_products_list_uploaded` - b_products_list_uploaded
 * * `b_ingredients_list_uploaded` - b_ingredients_list_uploaded
 * * `s_reminder_to_quote` - s_reminder_to_quote
 * * `b_quote_expiry_reminder` - b_quote_expiry_reminder
 * * `b_samples_dispatched` - b_samples_dispatched
 * * `s_supplier_remind_to_quote` - s_supplier_remind_to_quote
 * * `b_review_quotes` - b_review_quotes
 * * `s_samples_delivered` - s_samples_delivered
 * * `b_samples_feedback` - b_samples_feedback
 * * `s_reorder_request_created` - s_reorder_request_created
 * * `b_reorder_request_accepted` - b_reorder_request_accepted
 * * `b_reorder_request_declined` - b_reorder_request_declined
 * * `s_order_delivered` - s_order_delivered
 * * `s_purchase_order_cancelled` - s_purchase_order_cancelled
 * * `s_payout_confirmation` - s_payout_confirmation
 * * `b_contract_confirmed` - b_contract_confirmed
 * * `s_contract_confirmed` - s_contract_confirmed
 * * `s_create_contract` - s_create_contract
 * * `b_upload_signed_agreement` - b_upload_signed_agreement
 * * `s_purchase_order_edited` - s_purchase_order_edited
 * * `s_quote_declined` - s_quote_declined
 * * `s_requested_updated_quote` - s_requested_updated_quote
 * * `b_quote_updated` - b_quote_updated
 */
export enum EmailNotificationsTypeEnum {
    BSNewMessage = "b_s_new_message",
    BSDailyMessageSummary = "b_s_daily_message_summary",
    BPaymentConfirmation = "b_payment_confirmation",
    BPaymentDue = "b_payment_due",
    BPaymentReminder = "b_payment_reminder",
    SPayoutComplete = "s_payout_complete",
    BPurchaseRequestAccepted = "b_purchase_request_accepted",
    SNewPurchaseRequest = "s_new_purchase_request",
    BDispatchNotification = "b_dispatch_notification",
    BNewQuoteReceived = "b_new_quote_received",
    SQuoteAccepted = "s_quote_accepted",
    SNewRequest = "s_new_request",
    BOrderConfirmations = "b_order_confirmations",
    SDispatchReminders = "s_dispatch_reminders",
    SSamplesRequested = "s_samples_requested",
    BQuoteAvailableToOrder = "b_quote_available_to_order",
    BPurchaseRequestDeclined = "b_purchase_request_declined",
    SQuoteNegotiationsStarted = "s_quote_negotiations_started",
    BQuoteNegotiationResponses = "b_quote_negotiation_responses",
    BProductsListUploaded = "b_products_list_uploaded",
    BIngredientsListUploaded = "b_ingredients_list_uploaded",
    SReminderToQuote = "s_reminder_to_quote",
    BQuoteExpiryReminder = "b_quote_expiry_reminder",
    BSamplesDispatched = "b_samples_dispatched",
    SSupplierRemindToQuote = "s_supplier_remind_to_quote",
    BReviewQuotes = "b_review_quotes",
    SSamplesDelivered = "s_samples_delivered",
    BSamplesFeedback = "b_samples_feedback",
    SReorderRequestCreated = "s_reorder_request_created",
    BReorderRequestAccepted = "b_reorder_request_accepted",
    BReorderRequestDeclined = "b_reorder_request_declined",
    SOrderDelivered = "s_order_delivered",
    SPurchaseOrderCancelled = "s_purchase_order_cancelled",
    SPayoutConfirmation = "s_payout_confirmation",
    BContractConfirmed = "b_contract_confirmed",
    SContractConfirmed = "s_contract_confirmed",
    SCreateContract = "s_create_contract",
    BUploadSignedAgreement = "b_upload_signed_agreement",
    SPurchaseOrderEdited = "s_purchase_order_edited",
    SQuoteDeclined = "s_quote_declined",
    SRequestedUpdatedQuote = "s_requested_updated_quote",
    BQuoteUpdated = "b_quote_updated",
}

export interface ExistingSuppliersList {
    id: number;
    /** @maxLength 255 */
    company_name: string;
    /**
     * @format email
     * @maxLength 254
     */
    contact_email: string;
    /**
     * * `pending` - Pending
     * * `registered` - Registered
     */
    status?: ExistingSuppliersListStatusEnum;
    referred_company: string;
    number_or_orders: number;
    total_order_value: string;
    last_order_date: string;
    location: string;
    company_type: string;
    product_category: string;
    product_type: string;
    website: string;
}

/**
 * * `pending` - Pending
 * * `registered` - Registered
 */
export enum ExistingSuppliersListStatusEnum {
    Pending = "pending",
    Registered = "registered",
}

export interface ExportAdmin {
    /** @format date */
    date_from?: string | null;
    /** @format date */
    date_to?: string | null;
    company_uuid?: string[] | null;
    file_format?: FileFormatEnum | BlankEnum;
}

export interface Fees {
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    amount: string;
    vat_rate?: string;
}

/**
 * * `csv` - csv
 * * `json` - json
 * * `xlsx` - xlsx
 */
export enum FileFormatEnum {
    Csv = "csv",
    Json = "json",
    Xlsx = "xlsx",
}

export interface FileUpload {
    /**
     * Name of the file being uploaded
     * @maxLength 1024
     */
    file_name: string;
    /**
     * Name of the model related to this file. eg. for chats it would be 'Message'
     * @maxLength 256
     */
    model_name: string;
    /**
     * Name of the field on the model where file is stored. eg. for chats it would be 'attachment'
     * @maxLength 256
     */
    field_name: string;
    /**
     * Content type of the file.
     * @maxLength 256
     */
    content_type: string;
    /**
     * File size.
     * @min 1
     */
    size: number;
}

export interface FileUploadResponse {
    /** @format uri */
    url: string;
    token: string;
    file_name: string;
}

export interface Forecast {
    id: number;
    /**
     * @min 0
     * @max 2147483647
     */
    months?: number | null;
    /** @format date */
    start_period?: string | null;
    /** @format date-time */
    modified_at: string;
}

/**
 * * `one_time_order` - One Time Order
 * * `weekly` - Weekly
 * * `Monthly` - Monthly
 * * `quarterly` - Quarterly
 * * `annually` - Annually
 * * `undecided` - Undecided
 */
export enum ForecastEnum {
    OneTimeOrder = "one_time_order",
    Weekly = "weekly",
    Monthly = "Monthly",
    Quarterly = "quarterly",
    Annually = "annually",
    Undecided = "undecided",
}

export interface ForecastMonth {
    /** @format uuid */
    uuid: string;
    /** @format uuid */
    product_forecast: string;
    /**
     * @min -2147483648
     * @max 2147483647
     */
    opening_stock?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    demand_forecast?: number | null;
    /** @format date */
    date?: string | null;
    production_runs: ProductionRun[];
}

/**
 * * `weekly` - Weekly
 * * `monthly` - Monthly
 * * `quarterly` - Quarterly
 * * `annually` - Annually
 */
export enum FrequencyEnum {
    Weekly = "weekly",
    Monthly = "monthly",
    Quarterly = "quarterly",
    Annually = "annually",
}

export interface GoodsReceiptPDFUrl {
    /** @format uri */
    pdf_url: string;
}

export interface GoogleStorageSignedUrl {
    /** @maxLength 976 */
    file_name: string;
    /**
     * @format int64
     * @min 1
     * @max 10000000000
     */
    size: number;
    /** @maxLength 64 */
    content_type?: string;
}

export interface GoogleStorageSignedUrlResponse {
    signed_url: string;
}

export interface GoogleUpload {
    /** @maxLength 1024 */
    file_name: string;
    /** @min 1 */
    size: number;
    /** @maxLength 256 */
    content_type?: string;
    /** @maxLength 256 */
    field_name: string;
}

export interface GoogleUploadComplete {
    token: string;
}

export interface GoogleUploadResponse {
    /** @format uri */
    url: string;
    token: string;
}

export interface Icon {
    /** @maxLength 255 */
    title: string;
    /** @format uri */
    icon: string;
}

/**
 * * `delivery_duty_paid` - delivery_duty_paid
 * * `delivery_duty_unpaid` - delivery_duty_unpaid
 * * `cost_insurance_and_freight` - cost_insurance_and_freight
 * * `carriage_and_insurance_paid_to` - carriage_and_insurance_paid_to
 * * `cost_and_freight` - cost_and_freight
 * * `carriage_paid_to` - carriage_paid_to
 * * `delivered_at_place` - delivered_at_place
 * * `ex_works` - ex_works
 * * `free_alongside_ship` - free_alongside_ship
 * * `free_on_board` - free_on_board
 */
export enum IncotermsEnum {
    DeliveryDutyPaid = "delivery_duty_paid",
    DeliveryDutyUnpaid = "delivery_duty_unpaid",
    CostInsuranceAndFreight = "cost_insurance_and_freight",
    CarriageAndInsurancePaidTo = "carriage_and_insurance_paid_to",
    CostAndFreight = "cost_and_freight",
    CarriagePaidTo = "carriage_paid_to",
    DeliveredAtPlace = "delivered_at_place",
    ExWorks = "ex_works",
    FreeAlongsideShip = "free_alongside_ship",
    FreeOnBoard = "free_on_board",
}

export interface Ingredient {
    id: number;
    /** @format uuid */
    uuid: string;
    /** @maxLength 255 */
    name: string;
    /**
     * @format uri
     * @maxLength 2048
     */
    logo_url?: string;
    /**
     * @format decimal
     * @default "0.00"
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    current_unit_price?: string | null;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    currency?: Currencies;
    description: string;
    /**
     * @format decimal
     * @default "0.00"
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    quantity?: string | null;
    /** @default "kg" */
    unit_of_measurement?: UnitOfMeasurementEnum | BlankEnum | NullEnum | null;
    allergen_requirements?: (AllergenRequirementsEnum | BlankEnum)[];
    allergen_declaration?: boolean;
    dietary_certificate?: boolean;
    diet_requirements?: (DietRequirementsEnum | BlankEnum)[];
    current_supplier?: string;
    frequency?: FrequencyEnum | BlankEnum | NullEnum | null;
    /** @format uuid */
    article?: string | null;
    existing_suppliers: string[] | null;
    /** @format uuid */
    parent_uuid?: string | null;
    inventories: IngredientInventory[];
    /**
     * @min -2147483648
     * @max 2147483647
     */
    supplier_coverage?: number | null;
}

export interface IngredientInventory {
    /** @format uuid */
    uuid?: string;
    /** @format uuid */
    ingredient: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,17}(?:\.\d{0,2})?$
     */
    on_hand?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,17}(?:\.\d{0,2})?$
     */
    allocated?: string;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement?: UnitOfMeasurementEnum;
    /**
     * @format decimal
     * @pattern ^-?\d{0,17}(?:\.\d{0,2})?$
     */
    min_stock?: string | null;
    min_stock_unit?: MinStockUnitEnum | BlankEnum | NullEnum | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,17}(?:\.\d{0,2})?$
     */
    storage_capacity?: string | null;
    storage_unit?: StorageUnitEnum | BlankEnum | NullEnum | null;
    /**
     * @min 0
     * @max 2147483647
     */
    shelf_life?: number | null;
    shelf_life_unit?: ShelfLifeUnitEnum | BlankEnum | NullEnum | null;
    /**
     * @min 0
     * @max 2147483647
     */
    lead_time?: number | null;
}

export interface IngredientsForProductList {
    /** @format uuid */
    uuid: string;
    /**
     * @format decimal
     * @default "0.00"
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    quantity?: string | null;
    /** @default "kg" */
    unit_of_measurement?: UnitOfMeasurementEnum | BlankEnum | NullEnum | null;
}

export interface IngredientsForQuote {
    id: number;
    /** @format uuid */
    uuid: string;
    /** @maxLength 255 */
    name: string;
    /** @format double */
    on_hand: number;
    inventory_unit_of_measurement: InventoryUnitOfMeasurementEnum;
}

export interface InitializeSupplier {
    /** @maxLength 255 */
    name: string;
    /** @format uri */
    website?: string | null;
    /** @maxLength 100 */
    industry?: string | null;
    /** @maxLength 50 */
    phone_number?: string | null;
    country: string;
    state: string;
    /** @format email */
    email: string;
    /** @format uuid */
    inquiry_uuid?: string | null;
    /** @maxLength 50 */
    mongo_id?: string | null;
}

export interface Inquiry {
    id: number;
    /** @format uuid */
    uuid: string;
    /**
     * * `awaiting_quotes` - Awaiting Quotes
     * * `quotes_available` - Quotes Available
     * * `ordered` - Ordered
     * * `expired` - Expired
     * * `proceeded` - Proceeded
     * * `contract_confirmed` - Contract Confirmed
     * * `supplier_decline_to_quote` - Supplier Decline To Quote
     * * `cancelled` - Cancelled
     */
    state?: InquiryState;
    /**
     * * `non_recurring` - Non Recurring
     * * `recurring` - Recurring
     * * `purchase_request` - Purchase Request
     * * `ingredient` - Ingredient
     * * `ingredient_copy` - Ingredient Copy
     * * `offline_inquiry` - Offline Inquiry
     * * `sales_inquiry` - Sales Inquiry
     */
    category?: InquiryCategory;
    /** @maxLength 255 */
    name: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    current_unit_price: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    target_unit_price: string;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    currency?: Currencies;
    description: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    quantity: string;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement?: UnitOfMeasurementEnum;
    allergen_requirements?: (AllergenRequirementsEnum | BlankEnum)[];
    allergen_declaration?: boolean;
    dietary_certificate?: boolean;
    diet_requirements?: (DietRequirementsEnum | BlankEnum)[];
    forecast?: ForecastEnum | BlankEnum | NullEnum | null;
    /** @format date */
    due_date: string;
    delivery_address?: number | null;
    /** @maxItems 250 */
    supplier_countries: (Country | BlankEnum)[];
    current_supplier?: string;
    /**
     * Datetime when the inquiry becomes published. Leave empty to keep it unpublished.
     * @format date-time
     */
    published?: string | null;
    /**
     * @format uri
     * @maxLength 2048
     */
    logo_url?: string;
    is_new: boolean;
    /** @format uuid */
    article?: string | null;
    existing_suppliers: string[] | null;
    supply_priorities?: SupplyPriorities[];
    /** @format date */
    first_order_delivery_date?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    opply_brand_commission?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    opply_supplier_commission?: string;
    /** @default false */
    is_recurring?: boolean;
    frequency?: FrequencyEnum | BlankEnum | NullEnum | null;
    /** @min 0 */
    desired_contract_length?: number | null;
    is_delivery_date_flexible?: boolean;
    /** @format uuid */
    parent_uuid?: string | null;
    ingredient?: number | null;
    reason_for_request?: string | null;
    desired_packaging_format?: string;
    desired_packaging_format_is_flexible?: boolean;
    ai_generated?: boolean;
    documents_to_copy?: string[] | null;
}

export interface InquiryAdmin {
    /** @format uuid */
    uuid: string;
    /** @maxLength 255 */
    name: string;
    company_name: string;
    /** @format date-time */
    created: string;
    /**
     * @min 0
     * @default 0
     */
    assigned_suppliers?: number;
    /**
     * @min 0
     * @default 0
     */
    quotes_count?: number;
    /**
     * * `awaiting_quotes` - Awaiting Quotes
     * * `quotes_available` - Quotes Available
     * * `ordered` - Ordered
     * * `expired` - Expired
     * * `proceeded` - Proceeded
     * * `contract_confirmed` - Contract Confirmed
     * * `supplier_decline_to_quote` - Supplier Decline To Quote
     * * `cancelled` - Cancelled
     */
    state?: InquiryState;
    /** @format date */
    due_date: string;
    /**
     * Datetime when the inquiry becomes published. Leave empty to keep it unpublished.
     * @format date-time
     */
    published?: string | null;
    description: string;
    /**
     * @format uri
     * @maxLength 2048
     */
    logo_url?: string;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    currency?: Currencies;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    quantity: string;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement?: UnitOfMeasurementEnum;
    /** @maxItems 250 */
    supplier_countries: (Country | BlankEnum)[];
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    target_unit_price: string;
    allergen_requirements?: (AllergenRequirementsEnum | BlankEnum)[];
    allergen_declaration?: boolean;
    dietary_certificate?: boolean;
    diet_requirements?: (DietRequirementsEnum | BlankEnum)[];
    forecast?: ForecastEnum | BlankEnum | NullEnum | null;
    current_supplier?: string;
    total_forecast_quantity: string;
    reason_for_request?: string | null;
    is_delivery_date_flexible?: boolean;
    /** @format date */
    first_order_delivery_date?: string | null;
    ai_generated?: boolean;
}

/**
 * * `non_recurring` - Non Recurring
 * * `recurring` - Recurring
 * * `purchase_request` - Purchase Request
 * * `ingredient` - Ingredient
 * * `ingredient_copy` - Ingredient Copy
 * * `offline_inquiry` - Offline Inquiry
 * * `sales_inquiry` - Sales Inquiry
 */
export enum InquiryCategory {
    NonRecurring = "non_recurring",
    Recurring = "recurring",
    PurchaseRequest = "purchase_request",
    Ingredient = "ingredient",
    IngredientCopy = "ingredient_copy",
    OfflineInquiry = "offline_inquiry",
    SalesInquiry = "sales_inquiry",
}

export interface InquiryCreateAdmin {
    /** @format uuid */
    uuid: string;
    /** @format uuid */
    company_uuid: string;
    /** @maxLength 255 */
    name: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    current_unit_price: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    target_unit_price: string;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    currency?: Currencies;
    description: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    quantity: string;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement?: UnitOfMeasurementEnum;
    allergen_requirements?: (AllergenRequirementsEnum | BlankEnum)[];
    allergen_declaration?: boolean;
    dietary_certificate?: boolean;
    diet_requirements?: (DietRequirementsEnum | BlankEnum)[];
    forecast?: ForecastEnum | BlankEnum | NullEnum | null;
    /** @format date */
    due_date: string;
    /** @maxItems 250 */
    supplier_countries: (Country | BlankEnum)[];
    current_supplier?: string;
    /**
     * @format uri
     * @maxLength 2048
     */
    logo_url?: string;
    /** @format uuid */
    article?: string | null;
    /**
     * Datetime when the inquiry becomes published. Leave empty to keep it unpublished.
     * @format date-time
     */
    published: string | null;
    state: InquiryState;
    /** @format uuid */
    existing_supplier?: string;
    existing_supplier_name: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    opply_brand_commission?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    opply_supplier_commission?: string;
}

export interface InquiryDeclinedResponse {
    decline_reason: InquiryDeclinedResponseDeclineReasonEnum[];
    decline_reason_extended?: string | null;
}

/**
 * * `do_not_sell_this_ingredient` - Not Sell
 * * `request_quantity_too_small` - Too Small
 * * `request_quantity_too_large` - Too Large
 * * `cant_meet_specification` - Cant Meet Specification
 * * `cant_meet_delivery_date` - Cant Meet Delivery Date
 * * `ingredient_out_of_stock` - Out Of Stock
 * * `other` - Other
 */
export enum InquiryDeclinedResponseDeclineReasonEnum {
    DoNotSellThisIngredient = "do_not_sell_this_ingredient",
    RequestQuantityTooSmall = "request_quantity_too_small",
    RequestQuantityTooLarge = "request_quantity_too_large",
    CantMeetSpecification = "cant_meet_specification",
    CantMeetDeliveryDate = "cant_meet_delivery_date",
    IngredientOutOfStock = "ingredient_out_of_stock",
    Other = "other",
}

export interface InquiryDeliveryAddress {
    id: number;
    /** @maxLength 255 */
    name: string;
    /** @maxLength 255 */
    street_1?: string | null;
    /** @maxLength 255 */
    street_2?: string | null;
    /** @maxLength 255 */
    contact_full_name?: string | null;
    /** @maxLength 50 */
    contact_phone?: string | null;
    phone_country_code?: PhoneCountryCodeEnum | BlankEnum | NullEnum | null;
    /** @maxLength 100 */
    town?: string | null;
    /** @maxLength 100 */
    region?: string | null;
    /** @maxLength 100 */
    postcode?: string | null;
    /** @maxLength 20 */
    eori_number?: string;
    delivery_instructions?: string | null;
    /** @maxLength 100 */
    building?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,4}(?:\.\d{0,15})?$
     */
    latitude?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,4}(?:\.\d{0,15})?$
     */
    longitude?: string | null;
    collection_instructions?: string | null;
    /** @format uuid */
    uuid: string;
    country: Country | NullEnum | null;
    place_id: string | null;
    vat_number: string | null;
    vat_country_code: Country | NullEnum | null;
    vat_exempt: boolean;
    country_type: CountryTypeEnum;
}

export interface InquiryDocument {
    /** @format uuid */
    uuid: string;
    /** @format uri */
    document: string;
    description?: string | null;
}

export interface InquiryList {
    id: number;
    /** @format uuid */
    uuid: string;
    /**
     * * `awaiting_quotes` - Awaiting Quotes
     * * `quotes_available` - Quotes Available
     * * `ordered` - Ordered
     * * `expired` - Expired
     * * `proceeded` - Proceeded
     * * `contract_confirmed` - Contract Confirmed
     * * `supplier_decline_to_quote` - Supplier Decline To Quote
     * * `cancelled` - Cancelled
     */
    state?: InquiryState;
    /**
     * * `non_recurring` - Non Recurring
     * * `recurring` - Recurring
     * * `purchase_request` - Purchase Request
     * * `ingredient` - Ingredient
     * * `ingredient_copy` - Ingredient Copy
     * * `offline_inquiry` - Offline Inquiry
     * * `sales_inquiry` - Sales Inquiry
     */
    category?: InquiryCategory;
    /** @maxLength 255 */
    name: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    current_unit_price: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    target_unit_price: string;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    currency?: Currencies;
    description: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    quantity: string;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement?: UnitOfMeasurementEnum;
    allergen_requirements?: (AllergenRequirementsEnum | BlankEnum)[];
    allergen_declaration?: boolean;
    dietary_certificate?: boolean;
    diet_requirements?: (DietRequirementsEnum | BlankEnum)[];
    forecast?: ForecastEnum | BlankEnum | NullEnum | null;
    /** @format date */
    due_date: string;
    delivery_address?: number | null;
    /** @maxItems 250 */
    supplier_countries: (Country | BlankEnum)[];
    current_supplier?: string;
    /**
     * Datetime when the inquiry becomes published. Leave empty to keep it unpublished.
     * @format date-time
     */
    published?: string | null;
    /**
     * @format uri
     * @maxLength 2048
     */
    logo_url?: string;
    is_new: boolean;
    /** @format uuid */
    article?: string | null;
    existing_suppliers: string[] | null;
    supply_priorities?: SupplyPriorities[];
    /** @format date */
    first_order_delivery_date?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    opply_brand_commission?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    opply_supplier_commission?: string;
    /** @default false */
    is_recurring?: boolean;
    frequency?: FrequencyEnum | BlankEnum | NullEnum | null;
    /** @min 0 */
    desired_contract_length?: number | null;
    is_delivery_date_flexible?: boolean;
    /** @format uuid */
    parent_uuid?: string | null;
    ingredient?: number | null;
    reason_for_request?: string | null;
    desired_packaging_format?: string;
    desired_packaging_format_is_flexible?: boolean;
    ai_generated?: boolean;
    documents_to_copy?: string[] | null;
    orders_uuid: string[];
    /** @format uuid */
    existing_supplier_uuid: string;
    total_forecast_quantity: string;
    /** @format uuid */
    original_quote_uuid: string;
    declined: string;
    decline_reason: string;
}

export interface InquiryResponse {
    id: number;
    /** @format uuid */
    uuid: string;
    /**
     * * `awaiting_quotes` - Awaiting Quotes
     * * `quotes_available` - Quotes Available
     * * `ordered` - Ordered
     * * `expired` - Expired
     * * `proceeded` - Proceeded
     * * `contract_confirmed` - Contract Confirmed
     * * `supplier_decline_to_quote` - Supplier Decline To Quote
     * * `cancelled` - Cancelled
     */
    state?: InquiryState;
    /**
     * * `non_recurring` - Non Recurring
     * * `recurring` - Recurring
     * * `purchase_request` - Purchase Request
     * * `ingredient` - Ingredient
     * * `ingredient_copy` - Ingredient Copy
     * * `offline_inquiry` - Offline Inquiry
     * * `sales_inquiry` - Sales Inquiry
     */
    category?: InquiryCategory;
    /** @maxLength 255 */
    name: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    current_unit_price: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    target_unit_price: string;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    currency?: Currencies;
    description: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    quantity: string;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement?: UnitOfMeasurementEnum;
    allergen_requirements?: (AllergenRequirementsEnum | BlankEnum)[];
    allergen_declaration?: boolean;
    dietary_certificate?: boolean;
    diet_requirements?: (DietRequirementsEnum | BlankEnum)[];
    forecast?: ForecastEnum | BlankEnum | NullEnum | null;
    /** @format date */
    due_date: string;
    delivery_address: CompanyAddress;
    /** @maxItems 250 */
    supplier_countries: (Country | BlankEnum)[];
    current_supplier?: string;
    /**
     * Datetime when the inquiry becomes published. Leave empty to keep it unpublished.
     * @format date-time
     */
    published?: string | null;
    /**
     * @format uri
     * @maxLength 2048
     */
    logo_url?: string;
    is_new: boolean;
    /** @format uuid */
    article?: string | null;
    existing_suppliers: string[] | null;
    supply_priorities?: SupplyPriorities[];
    /** @format date */
    first_order_delivery_date?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    opply_brand_commission?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    opply_supplier_commission?: string;
    /** @default false */
    is_recurring?: boolean;
    frequency?: FrequencyEnum | BlankEnum | NullEnum | null;
    /** @min 0 */
    desired_contract_length?: number | null;
    is_delivery_date_flexible?: boolean;
    /** @format uuid */
    parent_uuid?: string | null;
    ingredient?: number | null;
    reason_for_request?: string | null;
    desired_packaging_format?: string;
    desired_packaging_format_is_flexible?: boolean;
    ai_generated?: boolean;
    documents_to_copy?: string[] | null;
    orders_uuid: string[];
    /** @format uuid */
    existing_supplier_uuid: string;
    total_forecast_quantity: string;
    /** @format uuid */
    original_quote_uuid: string;
    declined: string;
    decline_reason: string;
    company_address: Address;
    progress: string;
    possible_suppliers: string;
    suppliers_found: string;
    number_of_quotes: string;
    company: Company;
    existing_supplier_name: string;
    supplier_matching: string;
    total_forecast_spend: string;
}

export interface InquiryRetrieveAdmin {
    /** @format uuid */
    uuid: string;
    /** @maxLength 255 */
    name: string;
    company_name: string;
    /** @format date-time */
    created: string;
    assigned_suppliers: InquiryTargetSupplier[];
    /**
     * @min 0
     * @default 0
     */
    quotes_count?: number;
    /**
     * * `awaiting_quotes` - Awaiting Quotes
     * * `quotes_available` - Quotes Available
     * * `ordered` - Ordered
     * * `expired` - Expired
     * * `proceeded` - Proceeded
     * * `contract_confirmed` - Contract Confirmed
     * * `supplier_decline_to_quote` - Supplier Decline To Quote
     * * `cancelled` - Cancelled
     */
    state?: InquiryState;
    /** @format date */
    due_date: string;
    /**
     * Datetime when the inquiry becomes published. Leave empty to keep it unpublished.
     * @format date-time
     */
    published?: string | null;
    description: string;
    /**
     * @format uri
     * @maxLength 2048
     */
    logo_url?: string;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    currency?: Currencies;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    quantity: string;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement?: UnitOfMeasurementEnum;
    /** @maxItems 250 */
    supplier_countries: (Country | BlankEnum)[];
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    target_unit_price: string;
    allergen_requirements?: (AllergenRequirementsEnum | BlankEnum)[];
    allergen_declaration?: boolean;
    dietary_certificate?: boolean;
    diet_requirements?: (DietRequirementsEnum | BlankEnum)[];
    forecast?: ForecastEnum | BlankEnum | NullEnum | null;
    current_supplier?: string;
    total_forecast_quantity: string;
    reason_for_request?: string | null;
    is_delivery_date_flexible?: boolean;
    /** @format date */
    first_order_delivery_date?: string | null;
    ai_generated?: boolean;
    created_by: string;
    delivery_address: CompanyAddress;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    current_unit_price: string;
    company_number: string;
    quotes: QuoteForRetrieveInquiryAdmin[];
    existing_supplier: string;
    supply_priorities: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    opply_brand_commission?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    opply_supplier_commission?: string;
    /**
     * * `non_recurring` - Non Recurring
     * * `recurring` - Recurring
     * * `purchase_request` - Purchase Request
     * * `ingredient` - Ingredient
     * * `ingredient_copy` - Ingredient Copy
     * * `offline_inquiry` - Offline Inquiry
     * * `sales_inquiry` - Sales Inquiry
     */
    category?: InquiryCategory;
    frequency?: FrequencyEnum | BlankEnum | NullEnum | null;
    /**
     * @min -2147483648
     * @max 2147483647
     */
    desired_contract_length?: number | null;
    existing_supplier_name: string;
    supplier_matching: string;
    buyer_name: string;
    buyer_uuid: string;
    purchase_order_supplier_uuid: string;
}

/**
 * * `awaiting_quotes` - Awaiting Quotes
 * * `quotes_available` - Quotes Available
 * * `ordered` - Ordered
 * * `expired` - Expired
 * * `proceeded` - Proceeded
 * * `contract_confirmed` - Contract Confirmed
 * * `supplier_decline_to_quote` - Supplier Decline To Quote
 * * `cancelled` - Cancelled
 */
export enum InquiryState {
    AwaitingQuotes = "awaiting_quotes",
    QuotesAvailable = "quotes_available",
    Ordered = "ordered",
    Expired = "expired",
    Proceeded = "proceeded",
    ContractConfirmed = "contract_confirmed",
    SupplierDeclineToQuote = "supplier_decline_to_quote",
    Cancelled = "cancelled",
}

export interface InquiryTargetCreate {
    inquiry: Inquiry;
    company: Company;
    id: number;
    /** @format uuid */
    inquiry_uuid: string;
    /** @format date-time */
    created: string;
    /** @format date-time */
    modified: string;
    /** @default false */
    is_existing_supplier?: boolean;
}

export interface InquiryTargetSupplier {
    /** @format uuid */
    uuid: string;
    name: string;
    country: string;
    /** @format date-time */
    created: string;
    quote_status: string;
    kyb_status: string;
    decline_info: string;
}

export interface InquiryUpdate {
    id: number;
    /** @format uuid */
    uuid: string;
    state: string;
    /**
     * * `non_recurring` - Non Recurring
     * * `recurring` - Recurring
     * * `purchase_request` - Purchase Request
     * * `ingredient` - Ingredient
     * * `ingredient_copy` - Ingredient Copy
     * * `offline_inquiry` - Offline Inquiry
     * * `sales_inquiry` - Sales Inquiry
     */
    category?: InquiryCategory;
    /** @maxLength 255 */
    name?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    current_unit_price: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    target_unit_price: string;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    currency?: Currencies;
    description: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    quantity?: string;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement?: UnitOfMeasurementEnum;
    allergen_requirements?: (AllergenRequirementsEnum | BlankEnum)[];
    allergen_declaration?: boolean;
    dietary_certificate?: boolean;
    diet_requirements?: (DietRequirementsEnum | BlankEnum)[];
    forecast?: ForecastEnum | BlankEnum | NullEnum | null;
    /** @format date */
    due_date: string;
    delivery_address?: number | null;
    /** @maxItems 250 */
    supplier_countries: (Country | BlankEnum)[];
    current_supplier?: string;
    /**
     * Datetime when the inquiry becomes published. Leave empty to keep it unpublished.
     * @format date-time
     */
    published?: string | null;
    /**
     * @format uri
     * @maxLength 2048
     */
    logo_url?: string;
    is_new: boolean;
    /** @format uuid */
    article?: string | null;
    existing_suppliers: string[] | null;
    supply_priorities?: SupplyPriorities[];
    /** @format date */
    first_order_delivery_date?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    opply_brand_commission?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    opply_supplier_commission?: string;
    /** @default false */
    is_recurring?: boolean;
    frequency?: FrequencyEnum | BlankEnum | NullEnum | null;
    /** @min 0 */
    desired_contract_length?: number | null;
    is_delivery_date_flexible?: boolean;
    /** @format uuid */
    parent_uuid?: string | null;
    ingredient?: number | null;
    reason_for_request?: string | null;
    desired_packaging_format?: string;
    desired_packaging_format_is_flexible?: boolean;
    ai_generated?: boolean;
    documents_to_copy?: string[] | null;
    existing_supplier_name: string;
}

export interface InquiryUpdateAdmin {
    id: number;
    /** @format uuid */
    uuid: string;
    state?: string;
    /**
     * * `non_recurring` - Non Recurring
     * * `recurring` - Recurring
     * * `purchase_request` - Purchase Request
     * * `ingredient` - Ingredient
     * * `ingredient_copy` - Ingredient Copy
     * * `offline_inquiry` - Offline Inquiry
     * * `sales_inquiry` - Sales Inquiry
     */
    category?: InquiryCategory;
    /** @maxLength 255 */
    name?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    current_unit_price: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    target_unit_price: string;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    currency?: Currencies;
    description: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    quantity?: string;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement?: UnitOfMeasurementEnum;
    allergen_requirements?: (AllergenRequirementsEnum | BlankEnum)[];
    allergen_declaration?: boolean;
    dietary_certificate?: boolean;
    diet_requirements?: (DietRequirementsEnum | BlankEnum)[];
    forecast?: ForecastEnum | BlankEnum | NullEnum | null;
    /** @format date */
    due_date: string;
    delivery_address?: number | null;
    /** @maxItems 250 */
    supplier_countries: (Country | BlankEnum)[];
    current_supplier?: string;
    /**
     * Datetime when the inquiry becomes published. Leave empty to keep it unpublished.
     * @format date-time
     */
    published?: string | null;
    /**
     * @format uri
     * @maxLength 2048
     */
    logo_url?: string;
    is_new: boolean;
    /** @format uuid */
    article?: string | null;
    existing_suppliers: string[] | null;
    supply_priorities?: SupplyPriorities[];
    /** @format date */
    first_order_delivery_date?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    opply_brand_commission?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    opply_supplier_commission?: string;
    /** @default false */
    is_recurring?: boolean;
    frequency?: FrequencyEnum | BlankEnum | NullEnum | null;
    /** @min 0 */
    desired_contract_length?: number | null;
    is_delivery_date_flexible?: boolean;
    /** @format uuid */
    parent_uuid?: string | null;
    ingredient?: number | null;
    reason_for_request?: string | null;
    desired_packaging_format?: string;
    desired_packaging_format_is_flexible?: boolean;
    ai_generated?: boolean;
    documents_to_copy?: string[] | null;
    existing_supplier_name: string;
}

export interface InquiryUpdateResponseAdmin {
    id: number;
    /** @format uuid */
    uuid: string;
    state?: string;
    /**
     * * `non_recurring` - Non Recurring
     * * `recurring` - Recurring
     * * `purchase_request` - Purchase Request
     * * `ingredient` - Ingredient
     * * `ingredient_copy` - Ingredient Copy
     * * `offline_inquiry` - Offline Inquiry
     * * `sales_inquiry` - Sales Inquiry
     */
    category?: InquiryCategory;
    /** @maxLength 255 */
    name?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    current_unit_price: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    target_unit_price: string;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    currency?: Currencies;
    description: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    quantity?: string;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement?: UnitOfMeasurementEnum;
    allergen_requirements?: (AllergenRequirementsEnum | BlankEnum)[];
    allergen_declaration?: boolean;
    dietary_certificate?: boolean;
    diet_requirements?: (DietRequirementsEnum | BlankEnum)[];
    forecast?: ForecastEnum | BlankEnum | NullEnum | null;
    /** @format date */
    due_date: string;
    delivery_address?: number | null;
    /** @maxItems 250 */
    supplier_countries: (Country | BlankEnum)[];
    current_supplier?: string;
    /**
     * Datetime when the inquiry becomes published. Leave empty to keep it unpublished.
     * @format date-time
     */
    published?: string | null;
    /**
     * @format uri
     * @maxLength 2048
     */
    logo_url?: string;
    is_new: boolean;
    /** @format uuid */
    article?: string | null;
    existing_suppliers: string[] | null;
    supply_priorities?: SupplyPriorities[];
    /** @format date */
    first_order_delivery_date?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    opply_brand_commission?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    opply_supplier_commission?: string;
    /** @default false */
    is_recurring?: boolean;
    frequency?: FrequencyEnum | BlankEnum | NullEnum | null;
    /** @min 0 */
    desired_contract_length?: number | null;
    is_delivery_date_flexible?: boolean;
    /** @format uuid */
    parent_uuid?: string | null;
    ingredient?: number | null;
    reason_for_request?: string | null;
    desired_packaging_format?: string;
    desired_packaging_format_is_flexible?: boolean;
    ai_generated?: boolean;
    documents_to_copy?: string[] | null;
    existing_supplier_name: string;
    company_id: number;
}

/**
 * * `fl oz` - Fluid Ounces
 * * `g` - Grams
 * * `mg` - milligrams
 * * `gals` - Gallons
 * * `kg` - Kilograms
 * * `L` - Liters
 * * `tons` - Metric Tons
 * * `oz` - Ounces
 * * `lbs` - Pounds
 * * `pcs` - Pieces
 * * `pt` - Pints
 * * `ml` - Milliliter
 */
export enum InventoryUnitOfMeasurementEnum {
    FlOz = "fl oz",
    G = "g",
    Mg = "mg",
    Gals = "gals",
    Kg = "kg",
    L = "L",
    Tons = "tons",
    Oz = "oz",
    Lbs = "lbs",
    Pcs = "pcs",
    Pt = "pt",
    Ml = "ml",
}

export interface KYBStep1 {
    phone_number: string;
    phone_country_code: string;
}

export interface KYBStep1Response {
    /** @format uri */
    redirect_url: string;
}

export interface KYBVerificationStatusResponse {
    /**
     * * `Not started` - Not started
     * * `Started` - Started
     * * `In Review` - In Review
     * * `Completed` - Completed
     */
    status: KYBVerificationStatusResponseStatusEnum;
    /**
     * @min 1
     * @max 4
     */
    current_step: number;
    /** @format uri */
    redirect_url?: string | null;
}

/**
 * * `Not started` - Not started
 * * `Started` - Started
 * * `In Review` - In Review
 * * `Completed` - Completed
 */
export enum KYBVerificationStatusResponseStatusEnum {
    NotStarted = "Not started",
    Started = "Started",
    InReview = "In Review",
    Completed = "Completed",
}

export interface KriyaCancelOrder {
    /** @format uuid */
    order_uuid: string;
}

/**
 * * `LimitedCompany` - Limited Company
 * * `SoleTrader` - Sole Trader
 */
export enum KriyaCompanyType {
    LimitedCompany = "LimitedCompany",
    SoleTrader = "SoleTrader",
}

export interface KriyaKYBAddress {
    /** @maxLength 500 */
    address_line_1: string;
    /** @maxLength 500 */
    address_line_2?: string | null;
    /** @maxLength 100 */
    city: string;
    /** @maxLength 100 */
    region?: string | null;
    /** @maxLength 100 */
    postcode: string;
    /**
     * * `AF` - Afghanistan
     * * `AX` - Åland Islands
     * * `AL` - Albania
     * * `DZ` - Algeria
     * * `AS` - American Samoa
     * * `AD` - Andorra
     * * `AO` - Angola
     * * `AI` - Anguilla
     * * `AQ` - Antarctica
     * * `AG` - Antigua and Barbuda
     * * `AR` - Argentina
     * * `AM` - Armenia
     * * `AW` - Aruba
     * * `AU` - Australia
     * * `AT` - Austria
     * * `AZ` - Azerbaijan
     * * `BS` - Bahamas
     * * `BH` - Bahrain
     * * `BD` - Bangladesh
     * * `BB` - Barbados
     * * `BY` - Belarus
     * * `BE` - Belgium
     * * `BZ` - Belize
     * * `BJ` - Benin
     * * `BM` - Bermuda
     * * `BT` - Bhutan
     * * `BO` - Bolivia
     * * `BQ` - Bonaire, Sint Eustatius and Saba
     * * `BA` - Bosnia and Herzegovina
     * * `BW` - Botswana
     * * `BV` - Bouvet Island
     * * `BR` - Brazil
     * * `IO` - British Indian Ocean Territory
     * * `BN` - Brunei
     * * `BG` - Bulgaria
     * * `BF` - Burkina Faso
     * * `BI` - Burundi
     * * `CV` - Cabo Verde
     * * `KH` - Cambodia
     * * `CM` - Cameroon
     * * `CA` - Canada
     * * `KY` - Cayman Islands
     * * `CF` - Central African Republic
     * * `TD` - Chad
     * * `CL` - Chile
     * * `CN` - China
     * * `CX` - Christmas Island
     * * `CC` - Cocos (Keeling) Islands
     * * `CO` - Colombia
     * * `KM` - Comoros
     * * `CG` - Congo
     * * `CD` - Congo (the Democratic Republic of the)
     * * `CK` - Cook Islands
     * * `CR` - Costa Rica
     * * `CI` - Côte d'Ivoire
     * * `HR` - Croatia
     * * `CU` - Cuba
     * * `CW` - Curaçao
     * * `CY` - Cyprus
     * * `CZ` - Czechia
     * * `DK` - Denmark
     * * `DJ` - Djibouti
     * * `DM` - Dominica
     * * `DO` - Dominican Republic
     * * `EC` - Ecuador
     * * `EG` - Egypt
     * * `SV` - El Salvador
     * * `GQ` - Equatorial Guinea
     * * `ER` - Eritrea
     * * `EE` - Estonia
     * * `SZ` - Eswatini
     * * `ET` - Ethiopia
     * * `FK` - Falkland Islands (Malvinas)
     * * `FO` - Faroe Islands
     * * `FJ` - Fiji
     * * `FI` - Finland
     * * `FR` - France
     * * `GF` - French Guiana
     * * `PF` - French Polynesia
     * * `TF` - French Southern Territories
     * * `GA` - Gabon
     * * `GM` - Gambia
     * * `GE` - Georgia
     * * `DE` - Germany
     * * `GH` - Ghana
     * * `GI` - Gibraltar
     * * `GR` - Greece
     * * `GL` - Greenland
     * * `GD` - Grenada
     * * `GP` - Guadeloupe
     * * `GU` - Guam
     * * `GT` - Guatemala
     * * `GG` - Guernsey
     * * `GN` - Guinea
     * * `GW` - Guinea-Bissau
     * * `GY` - Guyana
     * * `HT` - Haiti
     * * `HM` - Heard Island and McDonald Islands
     * * `VA` - Holy See
     * * `HN` - Honduras
     * * `HK` - Hong Kong
     * * `HU` - Hungary
     * * `IS` - Iceland
     * * `IN` - India
     * * `ID` - Indonesia
     * * `IR` - Iran
     * * `IQ` - Iraq
     * * `IE` - Ireland
     * * `IM` - Isle of Man
     * * `IL` - Israel
     * * `IT` - Italy
     * * `JM` - Jamaica
     * * `JP` - Japan
     * * `JE` - Jersey
     * * `JO` - Jordan
     * * `KZ` - Kazakhstan
     * * `KE` - Kenya
     * * `KI` - Kiribati
     * * `KW` - Kuwait
     * * `KG` - Kyrgyzstan
     * * `LA` - Laos
     * * `LV` - Latvia
     * * `LB` - Lebanon
     * * `LS` - Lesotho
     * * `LR` - Liberia
     * * `LY` - Libya
     * * `LI` - Liechtenstein
     * * `LT` - Lithuania
     * * `LU` - Luxembourg
     * * `MO` - Macao
     * * `MG` - Madagascar
     * * `MW` - Malawi
     * * `MY` - Malaysia
     * * `MV` - Maldives
     * * `ML` - Mali
     * * `MT` - Malta
     * * `MH` - Marshall Islands
     * * `MQ` - Martinique
     * * `MR` - Mauritania
     * * `MU` - Mauritius
     * * `YT` - Mayotte
     * * `MX` - Mexico
     * * `FM` - Micronesia
     * * `MD` - Moldova
     * * `MC` - Monaco
     * * `MN` - Mongolia
     * * `ME` - Montenegro
     * * `MS` - Montserrat
     * * `MA` - Morocco
     * * `MZ` - Mozambique
     * * `MM` - Myanmar
     * * `NA` - Namibia
     * * `NR` - Nauru
     * * `NP` - Nepal
     * * `NL` - Netherlands
     * * `NC` - New Caledonia
     * * `NZ` - New Zealand
     * * `NI` - Nicaragua
     * * `NE` - Niger
     * * `NG` - Nigeria
     * * `NU` - Niue
     * * `NF` - Norfolk Island
     * * `KP` - North Korea
     * * `MK` - North Macedonia
     * * `XI` - Northern Ireland
     * * `MP` - Northern Mariana Islands
     * * `NO` - Norway
     * * `OM` - Oman
     * * `PK` - Pakistan
     * * `PW` - Palau
     * * `PS` - Palestine, State of
     * * `PA` - Panama
     * * `PG` - Papua New Guinea
     * * `PY` - Paraguay
     * * `PE` - Peru
     * * `PH` - Philippines
     * * `PN` - Pitcairn
     * * `PL` - Poland
     * * `PT` - Portugal
     * * `PR` - Puerto Rico
     * * `QA` - Qatar
     * * `RE` - Réunion
     * * `RO` - Romania
     * * `RU` - Russia
     * * `RW` - Rwanda
     * * `BL` - Saint Barthélemy
     * * `SH` - Saint Helena, Ascension and Tristan da Cunha
     * * `KN` - Saint Kitts and Nevis
     * * `LC` - Saint Lucia
     * * `MF` - Saint Martin (French part)
     * * `PM` - Saint Pierre and Miquelon
     * * `VC` - Saint Vincent and the Grenadines
     * * `WS` - Samoa
     * * `SM` - San Marino
     * * `ST` - Sao Tome and Principe
     * * `SA` - Saudi Arabia
     * * `SN` - Senegal
     * * `RS` - Serbia
     * * `SC` - Seychelles
     * * `SL` - Sierra Leone
     * * `SG` - Singapore
     * * `SX` - Sint Maarten (Dutch part)
     * * `SK` - Slovakia
     * * `SI` - Slovenia
     * * `SB` - Solomon Islands
     * * `SO` - Somalia
     * * `ZA` - South Africa
     * * `GS` - South Georgia and the South Sandwich Islands
     * * `KR` - South Korea
     * * `SS` - South Sudan
     * * `ES` - Spain
     * * `LK` - Sri Lanka
     * * `SD` - Sudan
     * * `SR` - Suriname
     * * `SJ` - Svalbard and Jan Mayen
     * * `SE` - Sweden
     * * `CH` - Switzerland
     * * `SY` - Syria
     * * `TW` - Taiwan
     * * `TJ` - Tajikistan
     * * `TZ` - Tanzania
     * * `TH` - Thailand
     * * `TL` - Timor-Leste
     * * `TG` - Togo
     * * `TK` - Tokelau
     * * `TO` - Tonga
     * * `TT` - Trinidad and Tobago
     * * `TN` - Tunisia
     * * `TR` - Türkiye
     * * `TM` - Turkmenistan
     * * `TC` - Turks and Caicos Islands
     * * `TV` - Tuvalu
     * * `UG` - Uganda
     * * `UA` - Ukraine
     * * `AE` - United Arab Emirates
     * * `GB` - United Kingdom
     * * `UM` - United States Minor Outlying Islands
     * * `US` - United States of America
     * * `UY` - Uruguay
     * * `UZ` - Uzbekistan
     * * `VU` - Vanuatu
     * * `VE` - Venezuela
     * * `VN` - Vietnam
     * * `VG` - Virgin Islands (British)
     * * `VI` - Virgin Islands (U.S.)
     * * `WF` - Wallis and Futuna
     * * `EH` - Western Sahara
     * * `YE` - Yemen
     * * `ZM` - Zambia
     * * `ZW` - Zimbabwe
     */
    country: Country;
}

export interface KriyaOrderCreate {
    quotes: string[];
    /**
     * * `PAY30` - Kriya 30
     * * `PAY30EOFM` - Kriya Next Month
     */
    payment_method: KriyaPaymentMethod;
}

/**
 * * `PAY30` - Kriya 30
 * * `PAY30EOFM` - Kriya Next Month
 */
export enum KriyaPaymentMethod {
    PAY30 = "PAY30",
    PAY30EOFM = "PAY30EOFM",
}

/**
 * * `Draft` - Draft
 * * `Submitted` - Submitted
 * * `ReadyToAdvance` - Ready To Advance
 * * `Advanced` - Advanced
 * * `Repaid` - Repaid
 * * `Due` - Due
 * * `Overdue` - Overdue
 * * `Closed` - Closed
 * * `Cancelled` - Cancelled
 */
export enum KriyaStatusEnum {
    Draft = "Draft",
    Submitted = "Submitted",
    ReadyToAdvance = "ReadyToAdvance",
    Advanced = "Advanced",
    Repaid = "Repaid",
    Due = "Due",
    Overdue = "Overdue",
    Closed = "Closed",
    Cancelled = "Cancelled",
}

export interface LastLogin {
    user_full_name: string | null;
    /** @format date-time */
    last_login: string | null;
}

export interface Login {
    /** @format email */
    email: string;
    password: string;
}

export interface MarkRelatedCleared {
    /** @format uuid */
    related_object_uuid: string;
    related_object_type: string;
    /**
     * * `Mark samples as dispatched` - Mark Samples Dispatched
     * * `Reject samples request` - Reject Samples Request
     * * `Buyer marks samples as delivered` - Buyer Marks Samples Delivered
     * * `Order is marked as dispatched or Buyer marks order as delivered` - Order Marked As Dispatched Or Buyer Marks Order Delivered
     * * `Order has been delivered` - Order Delivered
     * * `Purchase Order is Accepted or Declined` - Purchase Order Accepted Or Declined
     * * `View the Request Details page` - View Request Details Page
     * * `Payment has been received` - Payment Received
     * * `Signed Agreement Uploaded` - Signed Agreement Uploaded
     * * `Purchase Order is placed` - Purchase Order Placed
     * * `Update quote or reject negotiation` - Update Quote Or Reject Negotiation
     * * `One or more ingredients are created` - Ingredients Created
     * * `One of more products are created` - Products Created
     * * `Samples are marked as received` - Samples Marked As Received
     * * `Marked as delivered` - Marked As Delivered
     * * `Quote for request or decline request` - Quote For Request Or Decline
     * * `Product list is uploaded` - Product List Uploaded
     * * `Respond to Request` - Respond To Request
     * * `Respond to negotiations` - Respond To Negotiations
     * * `Contract is created` - Contract Created
     * * `Buyer has created a request` - Buyer Created Request
     * * `Order or decline quote` - Order Or Decline Quote
     * * `Open contract details page` - Open Contract Details Page
     * * `Accept contract renewal` - Accept Contract Renewal
     * * `Decline contract renewal` - Decline Contract Renewal
     * * `Negotiate contract renewal` - Negotiate Contract Renewal
     * * `Place first request` - Place First Request
     * * `Feedback has been given for a sample` - Feedback Given For Sample
     * * `5 or more ingredients are created` - Upload Ingredient List
     * * `2 or more products are created` - Upload Product List
     */
    action_clear_trigger: ActionClearTriggerEnum;
}

export interface MarkRelatedRead {
    /** @format uuid */
    related_object_uuid: string;
    related_object_type: string;
}

export interface Message {
    /** @format uuid */
    uuid: string;
    text?: string | null;
    /** @format uri */
    attachment: string | null;
    sender: ShortUser;
    is_read: string;
    /** @format date-time */
    created: string;
    /** @format date-time */
    modified: string;
    channel: ChannelForMessage;
    buyer_name: string;
    supplier_name: string;
    file_name?: string;
}

/**
 * * `fl oz` - Fluid Ounces
 * * `g` - Grams
 * * `mg` - milligrams
 * * `gals` - Gallons
 * * `kg` - Kilograms
 * * `L` - Liters
 * * `tons` - Metric Tons
 * * `oz` - Ounces
 * * `lbs` - Pounds
 * * `pcs` - Pieces
 * * `pt` - Pints
 * * `ml` - Milliliter
 */
export enum MinStockUnitEnum {
    FlOz = "fl oz",
    G = "g",
    Mg = "mg",
    Gals = "gals",
    Kg = "kg",
    L = "L",
    Tons = "tons",
    Oz = "oz",
    Lbs = "lbs",
    Pcs = "pcs",
    Pt = "pt",
    Ml = "ml",
}

export interface MorKYBDetails {
    /**
     * * `Submitted` - Submitted
     * * `InReview` - In Review
     * * `Approved` - Approved
     * * `Rejected` - Rejected
     * * `OnHold` - Oh Hold
     */
    mor_kriya_kyb_status: DecisionStatusEnum;
    /**
     * * `Not started` - Not started
     * * `Started` - Started
     * * `In Review` - In Review
     * * `Completed` - Completed
     */
    mor_rapyd_kyb_status: KYBVerificationStatusResponseStatusEnum;
}

/**
 * * `0 USD - 199 USD` - 0 USD - 199 USD
 * * `200 USD - 499 USD` - 200 USD - 499 USD
 * * `500 USD - 2999 USD` - 500 USD - 2999 USD
 * * `3000+ USD` - 3000+ USD
 */
export enum MovEnum {
    Value0USD199USD = "0 USD - 199 USD",
    Value200USD499USD = "200 USD - 499 USD",
    Value500USD2999USD = "500 USD - 2999 USD",
    Value3000USD = "3000+ USD",
}

export interface Notification {
    /** @format uuid */
    uuid: string;
    /**
     * * `Kriya KYB Reminder` - Kriya Kyb Reminder
     * * `New Quote` - New Quote
     * * `New Quote Reminder` - New Quote Reminder
     * * `New Request` - New Request
     * * `New Request Reminder` - New Request Reminder
     * * `New Samples Request` - New Samples Request
     * * `Samples Request Reminder` - Samples Request Reminder
     * * `Samples Dispatched` - Samples Dispatched
     * * `Samples Received Reminder` - Samples Received Reminder
     * * `Samples Approved` - Samples Approved
     * * `Samples Not Approved` - Samples Not Approved
     * * `Buyer Started Negotiation` - Buyer Started Negotiation
     * * `Supplier Negotiation Response` - Supplier Negotiation Response
     * * `Quote updated` - Quote Updated
     * * `New Order` - New Order
     * * `Order Dispatched` - Order Dispatched
     * * `Payment Due` - Payment Due
     * * `Quote Proceeded` - Quote Proceeded
     * * `Create Contract Reminder` - Create Contract Reminder
     * * `Proceed with Contract` - Proceed With Contract
     * * `Reminder: Proceed with Contract` - Reminder Proceed With Contract
     * * `Confirm Contract` - Confirm Contract
     * * `Contract Created` - Contract Created
     * * `New Purchase Request` - New Purchase Request
     * * `New Purchase Request Reminder` - New Purchase Request Reminder
     * * `Response to Purchase Request` - Response To Purchase Request
     * * `Purchase Request Cancelled` - Purchase Request Cancelled
     * * `Quote Expiring` - Quote Expiring
     * * `Request Expiring` - Request Expiring
     * * `New Message` - New Message
     * * `New User` - New User
     * * `Contract Renewal Declined` - Contract Renewal Declined
     * * `Supplier Accepts Contract Renewal` - Contract Renewal Accepted
     * * `Sign New Contract Purchase Agreement` - Contract Renewal Accepted With Agreement
     * * `Contract Renewal Requested` - Contract Renewal Requested
     * * `Contract Renewal Terms accepted` - Contract Renewal Terms Accepted
     * * `Supplier Negotiates Contract Renewal` - Supplier Negotiates Contract Renewal
     * * `Re-order` - Reorder
     * * `Request for Re-order Declined` - Reorder Declined
     */
    title: TitleEnum;
    description: string;
    content: string;
    /** @format date-time */
    created_at: string;
    /** @format date-time */
    read_at?: string | null;
    /**
     * * `trigger-based` - Triggerbased
     * * `time-based` - Timebased
     * * `user-specific` - Userspecific
     * * `global` - Global
     */
    type: NotificationTypeEnum;
    /**
     * * `chats` - Chats
     * * `requests` - Requests
     * * `quotes` - Quotes
     * * `orders` - Orders
     * * `contracts` - Contracts
     * * `settings` - Settings
     * * `ingredients` - Ingredients
     * * `products` - Products
     */
    category: NotificationCategoryEnum;
    /**
     * @min -2147483648
     * @max 2147483647
     */
    priority?: number;
    duration?: string | null;
    /** @maxLength 255 */
    location?: string | null;
    receiver: ShortUser;
    sender: ShortUser;
    related_object_type: string;
    related_object_uuid: string;
    is_read: string;
    /**
     * * `general_notifications` - General Notifications
     * * `actions` - Actions
     */
    used_for?: UsedForEnum;
    action_type?: ActionTypeEnum | BlankEnum | NullEnum | null;
    action_clear_trigger?: ActionClearTriggerEnum | BlankEnum | NullEnum | null;
}

/**
 * * `chats` - Chats
 * * `requests` - Requests
 * * `quotes` - Quotes
 * * `orders` - Orders
 * * `contracts` - Contracts
 * * `settings` - Settings
 * * `ingredients` - Ingredients
 * * `products` - Products
 */
export enum NotificationCategoryEnum {
    Chats = "chats",
    Requests = "requests",
    Quotes = "quotes",
    Orders = "orders",
    Contracts = "contracts",
    Settings = "settings",
    Ingredients = "ingredients",
    Products = "products",
}

/**
 * * `trigger-based` - Triggerbased
 * * `time-based` - Timebased
 * * `user-specific` - Userspecific
 * * `global` - Global
 */
export enum NotificationTypeEnum {
    TriggerBased = "trigger-based",
    TimeBased = "time-based",
    UserSpecific = "user-specific",
    Global = "global",
}

export enum NullEnum {
    Null = null,
}

/**
 * * `0 - 20` - 0 - 20
 * * `21 - 100` - 21 - 100
 * * `101 - 500` - 101 - 500
 * * `500+` - 500+
 */
export enum NumOfEmployeesEnum {
    Value020 = "0 - 20",
    Value21100 = "21 - 100",
    Value101500 = "101 - 500",
    Value500 = "500+",
}

/**
 * * `0 - 10` - 0 - 10
 * * `10 - 100` - 10 - 100
 * * `100 - 500` - 100 - 500
 * * `500+` - 500+
 */
export enum NumOfSkusEnum {
    Value010 = "0 - 10",
    Value10100 = "10 - 100",
    Value100500 = "100 - 500",
    Value500 = "500+",
}

export interface OfflineOrderCreate {
    /** @format uuid */
    order_uuid: string;
    subject: string;
    message_text?: string | null;
    signup_link: string;
    /** @min 1 */
    company_referral: number;
    /** @min 1 */
    delivery_address: number;
    /** @format date */
    delivery_date: string;
    ingredients: OfflineOrderIngredient[];
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    currency: Currencies;
}

export interface OfflineOrderIngredient {
    name: string;
    /** @format uri */
    logo_url?: string;
    /** @min 0 */
    quantity: number;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement: UnitOfMeasurementEnum;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    unit_price: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    vat_rate: string;
    /** @format uuid */
    ingredient_uuid?: string | null;
}

export interface Order {
    /** @format uuid */
    uuid: string;
    /** @format date-time */
    created: string;
    customer_order_number?: string | null;
    /**
     * * `regular` - Regular
     * * `offline_order` - Offline Order
     * * `sales_order` - Sales Order
     */
    category?: OrderCategory;
    /**
     * * `checkout` - Checkout
     * * `confirmed` - confirmed
     * * `pending_payment` - pending_payment
     * * `canceled` - canceled
     * * `awaiting_approval` - awaiting_approval
     * * `on_hold` - on_hold
     * * `placed` - placed
     * * `dispatched` - dispatched
     * * `completed` - completed
     */
    state?: OrderState;
    payment_status?: PaymentStatusEnum | BlankEnum | NullEnum | null;
    kriya_status?: KriyaStatusEnum | BlankEnum | NullEnum | null;
    /**
     * if this is not None, then during payment was chosen not default currency and this field should be passed to the payment as selected_currency
     *
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    payment_currency?: Currencies | BlankEnum | NullEnum | null;
    /** @format date-time */
    payment_due_date?: string | null;
    /** @format date-time */
    kriya_payment_due_date?: string | null;
    payment_method?: PaymentMethod | NullEnum | null;
    payment_scenario: string;
    /**
     * * `pending_dispatch` - pending_dispatch
     * * `dispatched` - Dispatched
     * * `delivered` - Delivered
     */
    shipment_status?: ShipmentStatusEnum;
    /** @format date */
    dispatch_confirmation_date: string | null;
    /** @format date */
    delivery_confirmation_date?: string | null;
    is_new: boolean;
    order_number: string;
    number_of_quotes_ordered: number;
    total_price: number;
    total_price_currency: string;
    /** @format date */
    delivery_date: string;
    shipping_price: number;
    shipping_cost_no_vat: number;
    sum_to_pay: number;
    /** @format date */
    latest_delivery_date: string | null;
    discount_amount: number;
    sum_to_pay_no_discount: number;
    quotes_prices: PricesRetrieve[];
    vat_sum: number;
    sum_payout: number;
    total_price_for_sales_order: number;
    customer_name: string;
    /** @format date */
    order_date?: string | null;
    payment_delay: number | null;
    has_pending_purchase_request: boolean;
    is_recurring: boolean;
    /** @format uuid */
    related_contract_uuid: string;
    /** @format uuid */
    purchase_quote_uuid: string;
}

/**
 * * `regular` - Regular
 * * `offline_order` - Offline Order
 * * `sales_order` - Sales Order
 */
export enum OrderCategory {
    Regular = "regular",
    OfflineOrder = "offline_order",
    SalesOrder = "sales_order",
}

export interface OrderConfirmDelivered {
    /** @format uuid */
    quote_uuid: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    delivered_quantity: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    converted_quantity?: string | null;
    unit_of_measurement?: UnitOfMeasurementEnum | NullEnum | null;
    /** @default true */
    update_inventory?: boolean;
}

export interface OrderConfirmDispatched {
    tracking_code?: string | null;
    /** @format date */
    dispatch_confirmation_date: string;
    /** @format date */
    expected_delivery_date: string;
    shipping_provider: string;
}

export interface OrderCreateSwagger {
    quotes: string[];
}

export interface OrderDetail {
    /** @format uuid */
    uuid: string;
    /** @format date-time */
    created: string;
    customer_order_number?: string | null;
    /**
     * * `regular` - Regular
     * * `offline_order` - Offline Order
     * * `sales_order` - Sales Order
     */
    category?: OrderCategory;
    /**
     * * `checkout` - Checkout
     * * `confirmed` - confirmed
     * * `pending_payment` - pending_payment
     * * `canceled` - canceled
     * * `awaiting_approval` - awaiting_approval
     * * `on_hold` - on_hold
     * * `placed` - placed
     * * `dispatched` - dispatched
     * * `completed` - completed
     */
    state?: OrderState;
    payment_status?: PaymentStatusEnum | BlankEnum | NullEnum | null;
    kriya_status?: KriyaStatusEnum | BlankEnum | NullEnum | null;
    /**
     * if this is not None, then during payment was chosen not default currency and this field should be passed to the payment as selected_currency
     *
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    payment_currency?: Currencies | BlankEnum | NullEnum | null;
    /** @format date-time */
    payment_due_date?: string | null;
    /** @format date-time */
    kriya_payment_due_date?: string | null;
    payment_method?: PaymentMethod | NullEnum | null;
    payment_scenario: string;
    /**
     * * `pending_dispatch` - pending_dispatch
     * * `dispatched` - Dispatched
     * * `delivered` - Delivered
     */
    shipment_status?: ShipmentStatusEnum;
    /** @format date */
    dispatch_confirmation_date: string | null;
    /** @format date */
    delivery_confirmation_date?: string | null;
    is_new: boolean;
    order_number: string;
    number_of_quotes_ordered: number;
    total_price: number;
    total_price_currency: string;
    /** @format date */
    delivery_date: string;
    shipping_price: number;
    shipping_cost_no_vat: number;
    sum_to_pay: number;
    /** @format date */
    latest_delivery_date: string | null;
    discount_amount: number;
    sum_to_pay_no_discount: number;
    quotes_prices: PricesRetrieve[];
    vat_sum: number;
    sum_payout: number;
    total_price_for_sales_order: number;
    customer_name: string;
    /** @format date */
    order_date?: string | null;
    payment_delay: number | null;
    has_pending_purchase_request: boolean;
    is_recurring: boolean;
    /** @format uuid */
    related_contract_uuid: string;
    /** @format uuid */
    purchase_quote_uuid: string;
    /** @format date-time */
    state_updated: string;
    tracking_code?: string | null;
    selected_currency: string;
    virtual_bank_account: BankTransfer | null;
    quotes: QuoteOrderDetail[];
    delivery_address: DeliveryAddressResponse;
    terms?: string | null;
    /** @format uuid */
    customer_uuid: string;
    /** @format date */
    expected_delivery_date?: string | null;
    /** @maxLength 255 */
    shipping_provider?: string | null;
    delivery_contact: string;
    delivery_contact_number: string;
    /** @format uuid */
    contract_uuid: string;
}

export interface OrderDetailAdmin {
    /** @format uuid */
    uuid: string;
    order_number: string;
    /** @format date-time */
    created: string;
    buyer_name: string;
    supplier_name: string;
    /**
     * * `pending_dispatch` - pending_dispatch
     * * `dispatched` - Dispatched
     * * `delivered` - Delivered
     */
    shipment_status?: ShipmentStatusEnum;
    /**
     * * `regular` - Regular
     * * `offline_order` - Offline Order
     * * `sales_order` - Sales Order
     */
    category?: OrderCategory;
    /**
     * * `checkout` - Checkout
     * * `confirmed` - confirmed
     * * `pending_payment` - pending_payment
     * * `canceled` - canceled
     * * `awaiting_approval` - awaiting_approval
     * * `on_hold` - on_hold
     * * `placed` - placed
     * * `dispatched` - dispatched
     * * `completed` - completed
     */
    state?: OrderState;
    payment_status?: PaymentStatusEnum | BlankEnum | NullEnum | null;
    kriya_status?: KriyaStatusEnum | BlankEnum | NullEnum | null;
    /** @format date-time */
    kriya_payment_due_date?: string | null;
    /** @format date-time */
    payment_due_date?: string | null;
    payment_method?: PaymentMethod | NullEnum | null;
    /** @format date */
    confirmation_date?: string | null;
    quotes: QuoteOrderDetail[];
    created_by: string;
    payment_currency: string;
    tracking_code?: string | null;
    prices: OrderPrices;
}

export interface OrderDispute {
    /** @format uuid */
    uuid: string;
    /** @format date-time */
    created: string;
    reasons_for_dispute: string;
}

export interface OrderDisputeRequest {
    /** @format uuid */
    uuid: string;
    reasons_for_dispute: string;
    /** @format uri */
    documents: string;
}

export interface OrderDocument {
    /** @format uuid */
    uuid: string;
    /** @format uri */
    document: string;
    category: OrderDocumentCategoryEnum;
}

/**
 * * `uploaded_by_admin` - Uploaded By Admin
 * * `uploaded_by_supplier` - Uploaded By Supplier
 */
export enum OrderDocumentCategoryEnum {
    UploadedByAdmin = "uploaded_by_admin",
    UploadedBySupplier = "uploaded_by_supplier",
}

/**
 * * `weekly` - Weekly
 * * `monthly` - Monthly
 * * `quarterly` - Quarterly
 * * `annually` - Annually
 */
export enum OrderFrequencyEnum {
    Weekly = "weekly",
    Monthly = "monthly",
    Quarterly = "quarterly",
    Annually = "annually",
}

export interface OrderListAdmin {
    /** @format uuid */
    uuid: string;
    order_number: string;
    /** @format date-time */
    created: string;
    buyer_name: string;
    supplier_name: string;
    /**
     * * `pending_dispatch` - pending_dispatch
     * * `dispatched` - Dispatched
     * * `delivered` - Delivered
     */
    shipment_status?: ShipmentStatusEnum;
    /**
     * * `regular` - Regular
     * * `offline_order` - Offline Order
     * * `sales_order` - Sales Order
     */
    category?: OrderCategory;
    /**
     * * `checkout` - Checkout
     * * `confirmed` - confirmed
     * * `pending_payment` - pending_payment
     * * `canceled` - canceled
     * * `awaiting_approval` - awaiting_approval
     * * `on_hold` - on_hold
     * * `placed` - placed
     * * `dispatched` - dispatched
     * * `completed` - completed
     */
    state?: OrderState;
    payment_status?: PaymentStatusEnum | BlankEnum | NullEnum | null;
    kriya_status?: KriyaStatusEnum | BlankEnum | NullEnum | null;
    /** @format date-time */
    kriya_payment_due_date?: string | null;
    /** @format date-time */
    payment_due_date?: string | null;
    payment_method?: PaymentMethod | NullEnum | null;
    /** @format date */
    confirmation_date?: string | null;
}

export interface OrderPrices {
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    commission_payout_with_vat: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    shipping_cost_with_vat: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    sum_to_pay: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    sum_payout: string;
    quotes: PricesRetrieve[];
    /**
     * * `HUF` - HUF
     * * `SVC` - SVC
     * * `MZN` - MZN
     * * `BOB` - BOB
     * * `GHS` - GHS
     * * `MAD` - MAD
     * * `USD` - USD
     * * `ECS` - ECS
     * * `AFA` - AFA
     * * `ECV` - ECV
     * * `ZAR` - ZAR
     * * `CNH` - CNH
     * * `GEK` - GEK
     * * `TWD` - TWD
     * * `FRF` - FRF
     * * `SUR` - SUR
     * * `VNN` - VNN
     * * `TTD` - TTD
     * * `ZWD` - ZWD
     * * `ALL` - ALL
     * * `LTL` - LTL
     * * `LVL` - LVL
     * * `JOD` - JOD
     * * `CNY` - CNY
     * * `LBP` - LBP
     * * `BYB` - BYB
     * * `ARM` - ARM
     * * `KPW` - KPW
     * * `CYP` - CYP
     * * `AZN` - AZN
     * * `LTT` - LTT
     * * `XTS` - XTS
     * * `BYR` - BYR
     * * `CNX` - CNX
     * * `HKD` - HKD
     * * `RSD` - RSD
     * * `BGN` - BGN
     * * `ERN` - ERN
     * * `EEK` - EEK
     * * `GHC` - GHC
     * * `TJS` - TJS
     * * `SLL` - SLL
     * * `ARL` - ARL
     * * `BUK` - BUK
     * * `ARS` - ARS
     * * `SSP` - SSP
     * * `TOP` - TOP
     * * `XCD` - XCD
     * * `SEK` - SEK
     * * `PAB` - PAB
     * * `XAU` - XAU
     * * `KGS` - KGS
     * * `DJF` - DJF
     * * `XFU` - XFU
     * * `SIT` - SIT
     * * `AON` - AON
     * * `EUR` - EUR
     * * `NIO` - NIO
     * * `BGL` - BGL
     * * `AFN` - AFN
     * * `MMK` - MMK
     * * `USS` - USS
     * * `BOL` - BOL
     * * `BRE` - BRE
     * * `AUD` - AUD
     * * `UGS` - UGS
     * * `LUF` - LUF
     * * `JMD` - JMD
     * * `COU` - COU
     * * `GYD` - GYD
     * * `STD` - STD
     * * `MDL` - MDL
     * * `YUN` - YUN
     * * `DZD` - DZD
     * * `NGN` - NGN
     * * `UYU` - UYU
     * * `SYP` - SYP
     * * `ADP` - ADP
     * * `XBC` - XBC
     * * `MLF` - MLF
     * * `XUA` - XUA
     * * `XXX` - XXX
     * * `ISK` - ISK
     * * `BDT` - BDT
     * * `NZD` - NZD
     * * `ETB` - ETB
     * * `HTG` - HTG
     * * `BEC` - BEC
     * * `SAR` - SAR
     * * `AZM` - AZM
     * * `KWD` - KWD
     * * `CLP` - CLP
     * * `SDD` - SDD
     * * `INR` - INR
     * * `ATS` - ATS
     * * `AWG` - AWG
     * * `IEP` - IEP
     * * `GNF` - GNF
     * * `ILS` - ILS
     * * `SDP` - SDP
     * * `XOF` - XOF
     * * `UGX` - UGX
     * * `GEL` - GEL
     * * `YER` - YER
     * * `BRZ` - BRZ
     * * `ZWR` - ZWR
     * * `TMT` - TMT
     * * `XBB` - XBB
     * * `CRC` - CRC
     * * `MGA` - MGA
     * * `BOV` - BOV
     * * `SLE` - SLE
     * * `CUC` - CUC
     * * `CSD` - CSD
     * * `AOA` - AOA
     * * `SDG` - SDG
     * * `UYP` - UYP
     * * `MNT` - MNT
     * * `BRB` - BRB
     * * `YUM` - YUM
     * * `ZAL` - ZAL
     * * `XEU` - XEU
     * * `VEF` - VEF
     * * `PKR` - PKR
     * * `BHD` - BHD
     * * `PLN` - PLN
     * * `ITL` - ITL
     * * `TND` - TND
     * * `MWK` - MWK
     * * `LUC` - LUC
     * * `MDC` - MDC
     * * `BMD` - BMD
     * * `QAR` - QAR
     * * `PEI` - PEI
     * * `FKP` - FKP
     * * `GQE` - GQE
     * * `XPF` - XPF
     * * `CHW` - CHW
     * * `BRR` - BRR
     * * `SHP` - SHP
     * * `CHE` - CHE
     * * `HRD` - HRD
     * * `BGM` - BGM
     * * `LVR` - LVR
     * * `CLE` - CLE
     * * `ZRN` - ZRN
     * * `DEM` - DEM
     * * `AOK` - AOK
     * * `XDR` - XDR
     * * `TPE` - TPE
     * * `ILR` - ILR
     * * `MRU` - MRU
     * * `KHR` - KHR
     * * `MXV` - MXV
     * * `HRK` - HRK
     * * `SBD` - SBD
     * * `YUR` - YUR
     * * `PES` - PES
     * * `GNS` - GNS
     * * `MKD` - MKD
     * * `PTE` - PTE
     * * `BOP` - BOP
     * * `BGO` - BGO
     * * `CDF` - CDF
     * * `BYN` - BYN
     * * `CLF` - CLF
     * * `LRD` - LRD
     * * `IRR` - IRR
     * * `MRO` - MRO
     * * `MUR` - MUR
     * * `VES` - VES
     * * `VEB` - VEB
     * * `RHD` - RHD
     * * `GBP` - GBP
     * * `TRL` - TRL
     * * `RWF` - RWF
     * * `ZRZ` - ZRZ
     * * `TJR` - TJR
     * * `CAD` - CAD
     * * `HNL` - HNL
     * * `LYD` - LYD
     * * `FIM` - FIM
     * * `ESA` - ESA
     * * `ESB` - ESB
     * * `GWE` - GWE
     * * `AOR` - AOR
     * * `AED` - AED
     * * `IQD` - IQD
     * * `KRO` - KRO
     * * `THB` - THB
     * * `WST` - WST
     * * `LAK` - LAK
     * * `MVR` - MVR
     * * `IDR` - IDR
     * * `MYR` - MYR
     * * `BEF` - BEF
     * * `AMD` - AMD
     * * `XCG` - XCG
     * * `MTP` - MTP
     * * `SCR` - SCR
     * * `KRH` - KRH
     * * `PHP` - PHP
     * * `TMM` - TMM
     * * `UZS` - UZS
     * * `ZMW` - ZMW
     * * `STN` - STN
     * * `XSU` - XSU
     * * `UYI` - UYI
     * * `BRC` - BRC
     * * `CZK` - CZK
     * * `GTQ` - GTQ
     * * `PGK` - PGK
     * * `CVE` - CVE
     * * `KES` - KES
     * * `XRE` - XRE
     * * `XBA` - XBA
     * * `VUV` - VUV
     * * `RON` - RON
     * * `ESP` - ESP
     * * `NLG` - NLG
     * * `CUP` - CUP
     * * `LKR` - LKR
     * * `XPT` - XPT
     * * `LUL` - LUL
     * * `TZS` - TZS
     * * `XPD` - XPD
     * * `DKK` - DKK
     * * `MZM` - MZM
     * * `MVP` - MVP
     * * `XAG` - XAG
     * * `PLZ` - PLZ
     * * `MTL` - MTL
     * * `BRL` - BRL
     * * `PYG` - PYG
     * * `CHF` - CHF
     * * `UAK` - UAK
     * * `COP` - COP
     * * `SOS` - SOS
     * * `MKN` - MKN
     * * `SZL` - SZL
     * * `ANG` - ANG
     * * `GIP` - GIP
     * * `ALK` - ALK
     * * `YUD` - YUD
     * * `OMR` - OMR
     * * `LSL` - LSL
     * * `BAD` - BAD
     * * `SKK` - SKK
     * * `BWP` - BWP
     * * `YDD` - YDD
     * * `ROL` - ROL
     * * `GMD` - GMD
     * * `BEL` - BEL
     * * `DOP` - DOP
     * * `KYD` - KYD
     * * `MAF` - MAF
     * * `MOP` - MOP
     * * `NAD` - NAD
     * * `RUR` - RUR
     * * `BAM` - BAM
     * * `BZD` - BZD
     * * `VND` - VND
     * * `XBD` - XBD
     * * `BIF` - BIF
     * * `SRD` - SRD
     * * `ARP` - ARP
     * * `FJD` - FJD
     * * `KRW` - KRW
     * * `JPY` - JPY
     * * `MGF` - MGF
     * * `MXN` - MXN
     * * `USN` - USN
     * * `BND` - BND
     * * `MZE` - MZE
     * * `GWP` - GWP
     * * `XAF` - XAF
     * * `BSD` - BSD
     * * `BBD` - BBD
     * * `UAH` - UAH
     * * `TRY` - TRY
     * * `BRN` - BRN
     * * `DDM` - DDM
     * * `ZWL` - ZWL
     * * `ILP` - ILP
     * * `RUB` - RUB
     * * `CSK` - CSK
     * * `SGD` - SGD
     * * `XFO` - XFO
     * * `EGP` - EGP
     * * `NIC` - NIC
     * * `NOK` - NOK
     * * `KMF` - KMF
     * * `NPR` - NPR
     * * `UYW` - UYW
     * * `KZT` - KZT
     * * `VED` - VED
     * * `BTN` - BTN
     * * `BAN` - BAN
     * * `ISJ` - ISJ
     * * `PEN` - PEN
     * * `ZMK` - ZMK
     * * `MXP` - MXP
     * * `MCF` - MCF
     * * `ARA` - ARA
     * * `GRD` - GRD
     * * `SRG` - SRG
     */
    currency: AllCurrencies;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    commission_payout: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    payment_commission: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    total_price: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    total_price_commission: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    total_price_commission_vat: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    total_price_fx_fee: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    commission_payout_vat: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    buyer_total_price_sum_to_pay_without_fx: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    total_price_with_commission_with_vat: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    discount_amount?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    sum_to_pay_no_discount?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    unit_price_with_commission_with_vat?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    unit_price_no_vat_with_shipping?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    total_price_no_vat_with_shipping?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    unit_price_no_vat?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    total_price_no_vat?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    shipping_cost_no_vat?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    vat_sum?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    unit_price_with_vat_with_shipping?: string;
}

/**
 * * `checkout` - Checkout
 * * `confirmed` - confirmed
 * * `pending_payment` - pending_payment
 * * `canceled` - canceled
 * * `awaiting_approval` - awaiting_approval
 * * `on_hold` - on_hold
 * * `placed` - placed
 * * `dispatched` - dispatched
 * * `completed` - completed
 */
export enum OrderState {
    Checkout = "checkout",
    Confirmed = "confirmed",
    PendingPayment = "pending_payment",
    Canceled = "canceled",
    AwaitingApproval = "awaiting_approval",
    OnHold = "on_hold",
    Placed = "placed",
    Dispatched = "dispatched",
    Completed = "completed",
}

export interface PDFUrl {
    /** @format uri */
    pdf_url: string;
}

export interface PaginatedAdminCompanyDocumentList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: AdminCompanyDocument[];
}

export interface PaginatedAdminCompanyList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: AdminCompany[];
}

export interface PaginatedAdminIngredientResponseList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: AdminIngredientResponse[];
}

export interface PaginatedAdminMessagesResponseList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: AdminMessagesResponse[];
}

export interface PaginatedAdminProductsResponseList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: AdminProductsResponse[];
}

export interface PaginatedArticleList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: Article[];
}

export interface PaginatedChannelExpandedList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: ChannelExpanded[];
}

export interface PaginatedChannelList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: Channel[];
    /** @example 123 */
    unread_count?: number;
}

export interface PaginatedCompanyAddressList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: CompanyAddress[];
}

export interface PaginatedCompanyDocumentList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: CompanyDocument[];
}

export interface PaginatedCompanyList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: Company[];
}

export interface PaginatedCompanyMembersListList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: CompanyMembersList[];
}

export interface PaginatedCompanyUserAdminList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: CompanyUserAdmin[];
}

export interface PaginatedContractRenewNegotiationList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: ContractRenewNegotiation[];
}

export interface PaginatedCustomerList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: Customer[];
}

export interface PaginatedDashboardContractsListList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: DashboardContractsList[];
}

export interface PaginatedDashboardInquiriesForSupplierList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: DashboardInquiriesForSupplier[];
}

export interface PaginatedDashboardInquiriesList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: DashboardInquiries[];
}

export interface PaginatedDashboardOrdersList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: DashboardOrders[];
}

export interface PaginatedDirectorsList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: Directors[];
}

export interface PaginatedExistingSuppliersListList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: ExistingSuppliersList[];
}

export interface PaginatedForecastList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: Forecast[];
}

export interface PaginatedForecastMonthList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: ForecastMonth[];
}

export interface PaginatedIngredientInventoryList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: IngredientInventory[];
}

export interface PaginatedIngredientList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: Ingredient[];
}

export interface PaginatedIngredientsForProductListList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: IngredientsForProductList[];
}

export interface PaginatedInquiryAdminList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: InquiryAdmin[];
}

export interface PaginatedInquiryDocumentList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: InquiryDocument[];
}

export interface PaginatedInquiryList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: Inquiry[];
}

export interface PaginatedInquiryListList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: InquiryList[];
}

export interface PaginatedMessageList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: Message[];
    /** @example 123 */
    unread_count?: number;
}

export interface PaginatedNotificationList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: Notification[];
    /** @example 123 */
    unread_count?: number;
}

export interface PaginatedOrderDisputeList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: OrderDispute[];
}

export interface PaginatedOrderDocumentList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: OrderDocument[];
}

export interface PaginatedOrderList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: Order[];
}

export interface PaginatedOrderListAdminList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: OrderListAdmin[];
}

export interface PaginatedPricingSchemeList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: PricingScheme[];
}

export interface PaginatedProductForecastList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: ProductForecast[];
}

export interface PaginatedProductInventoryList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: ProductInventory[];
}

export interface PaginatedProductionRunList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: ProductionRun[];
}

export interface PaginatedProductionScheduleList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: ProductionSchedule[];
}

export interface PaginatedPurchaseRequestList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: PurchaseRequest[];
}

export interface PaginatedQuoteDetailList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: QuoteDetail[];
}

export interface PaginatedQuoteDetailSupplierList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: QuoteDetailSupplier[];
}

export interface PaginatedQuoteDocumentList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: QuoteDocument[];
}

export interface PaginatedQuoteFeesAdminList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: QuoteFeesAdmin[];
}

export interface PaginatedQuoteListAdminList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: QuoteListAdmin[];
}

export interface PaginatedQuoteListList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: QuoteList[];
}

export interface PaginatedQuoteNegotiationList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: QuoteNegotiation[];
}

export interface PaginatedQuoteRetrieveList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: QuoteRetrieve[];
}

export interface PaginatedShareholdersList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: Shareholders[];
}

export interface PaginatedSupplierCompanyList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: SupplierCompany[];
}

export interface PaginatedSupplierInquiryListList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: SupplierInquiryList[];
}

export interface PaginatedUploadedProductsCatalogueList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: UploadedProductsCatalogue[];
}

export interface PaginatedUserActionList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: UserAction[];
}

export interface PaginatedUserListList {
    /** @example 123 */
    count: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=4"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?page=2"
     */
    previous?: string | null;
    results: UserList[];
}

export interface ParagonAuth {
    token: string;
}

export interface PasswordResetCommit {
    key: string;
    password: string;
    confirm_password: string;
}

export interface PasswordResetInit {
    /** @format email */
    email: string;
}

export interface PatchedArticle {
    /** @format uuid */
    uuid?: string;
    inquiries?: Inquiry[];
    inventories?: ProductInventory[];
    /** @format date-time */
    created?: string;
    /** @format date-time */
    modified?: string;
    /** @maxLength 255 */
    title?: string;
    /**
     * @format uri
     * @maxLength 2048
     */
    logo_url?: string;
    description?: string | null;
    /** @maxLength 32 */
    sku?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    recommended_retail_price?: string | null;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    currency?: Currencies;
    /**
     * * `for_inquiries` - For Inquiries
     * * `product` - Product
     */
    category?: ArticleCategory;
    /**
     * @format decimal
     * @pattern ^-?\d{0,12}(?:\.\d{0,0})?$
     */
    stock_on_hand?: string | null;
    uploaded_by_admin?: boolean;
    company?: number;
}

export interface PatchedBrandQuoteUpdate {
    /**
     * * `available` - Available
     * * `requested` - Requested
     * * `sent` - Sent
     * * `received` - Received
     * * `accepted` - Accepted
     * * `rejected` - Rejected
     * * `declined` - Declined
     */
    samples_status?: SamplesStatusEnum;
    /** @format uuid */
    samples_delivery_address?: string;
    samples_feedback?: SamplesFeedbackEnum[] | null;
    samples_feedback_comment?: string;
    /** @format date */
    samples_received_date?: string | null;
    /** @format date */
    samples_accepted_date?: string | null;
    /** @format date */
    samples_rejected_date?: string | null;
    /** @format date */
    samples_declined_date?: string | null;
    /** @format date */
    samples_requested_date?: string | null;
    /**
     * * `in_advance` - In Advance
     * * `on_dispatch` - On Dispatch
     * * `7_days_after_dispatch` - Days After Dispatch 7
     * * `14_days_after_dispatch` - Days After Dispatch 14
     * * `30_days_after_dispatch` - Days After Dispatch 30
     * * `60_days_after_dispatch` - Days After Dispatch 60
     */
    requested_payment_terms?: RequestedPaymentTermsEnum;
    extension_accepted?: boolean | null;
}

export interface PatchedCompany {
    id?: number;
    /** @format uuid */
    uuid?: string;
    /** @maxLength 255 */
    website?: string | null;
    /** @maxLength 255 */
    name?: string;
    /** @format uri */
    logo?: string | null;
    /** @maxLength 100 */
    industry?: string | null;
    kyb_status?: string;
    /** @maxLength 50 */
    phone_number?: string | null;
    country?: string;
    state?: string;
    /**
     * * `brand` - Brand
     * * `supplier` - Supplier
     */
    company_type?: CompanyTypeEnum;
    /** @default ["h","r","e","o","t"] */
    product_type?: ProductTypeEnum[];
    /** @default ["h","r","e","o","t"] */
    activity_type?: ActivityTypeEnum[];
    /** @maxLength 255 */
    registration_number?: string | null;
    mongo_id?: string | null;
    referral_code?: string;
    phone_country_code?: PhoneCountryCodeEnum | BlankEnum | NullEnum | null;
    company_addresses?: CompanyAddress[];
    currency?: string;
    /**
     * rapyd payout method
     * @maxLength 45
     */
    payout_method_type?: string | null;
    bank_account_country?: Country | BlankEnum | NullEnum | null;
}

export interface PatchedCompanyAddress {
    id?: number;
    /** @format date-time */
    created?: string;
    /** @format date-time */
    modified?: string;
    /** @format uuid */
    uuid?: string;
    /** @maxLength 255 */
    name?: string;
    /**
     * * `billing_address` - Billing address
     * * `delivery_address` - Delivery address
     * * `dispatch_address` - Dispatch address
     * * `company_address` - Company address
     */
    address_type?: AddressTypeEnum;
    /** @maxLength 255 */
    contact_full_name?: string | null;
    /** @maxLength 50 */
    contact_phone?: string | null;
    /** @maxLength 100 */
    building?: string | null;
    /** @maxLength 255 */
    street_1?: string | null;
    /** @maxLength 255 */
    street_2?: string | null;
    /** @maxLength 100 */
    town?: string | null;
    /** @maxLength 100 */
    region?: string | null;
    /** @maxLength 100 */
    postcode?: string | null;
    country?: Country | BlankEnum | NullEnum | null;
    /** @maxLength 100 */
    place_id?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,4}(?:\.\d{0,15})?$
     */
    latitude?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,4}(?:\.\d{0,15})?$
     */
    longitude?: string | null;
    /** @maxLength 50 */
    vat_number?: string | null;
    vat_country_code?: Country | BlankEnum | NullEnum | null;
    vat_exempt?: boolean;
    phone_country_code?: PhoneCountryCodeEnum | BlankEnum | NullEnum | null;
    delivery_instructions?: string | null;
    collection_instructions?: string | null;
    /**
     * * `EU` - Eu
     * * `GB` - Gb
     * * `Other` - Other
     */
    country_type?: CountryTypeEnum;
    /** @maxLength 20 */
    eori_number?: string;
}

export interface PatchedContractRenewNegotiation {
    /** @format uuid */
    uuid?: string;
    /** @format uuid */
    company_uuid?: string;
    /**
     * Duration of the contract in months.
     * @min -2147483648
     * @max 2147483647
     */
    contract_length?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    quantity_per_order?: number;
    /**
     * @format decimal
     * @pattern ^-?\d{0,17}(?:\.\d{0,2})?$
     */
    total_forecast_volume?: string;
    /** @format date */
    first_order_delivery_date?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,10})?$
     */
    unit_price?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    price_per_order?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    shipping_cost_per_order?: string | null;
    /**
     * * `created` - Created
     * * `accepted` - Accepted
     * * `declined` - Declined
     * * `completed` - Completed
     */
    status?: QuoteNegotiationStatus;
    /** @format date */
    declined_date?: string | null;
    /** @format date */
    accepted_date?: string | null;
    /** @format date */
    confirmed_date?: string | null;
    /** @format uuid */
    new_contract_uuid?: string | null;
    /** @format date-time */
    created?: string;
    /** @format date-time */
    modified?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    payout_per_unit_no_shipping?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    sum_payout_no_shipping?: string;
}

export interface PatchedCustomer {
    delivery_address?: CustomerAddress;
    billing_address?: CustomerAddress;
    /** @format date-time */
    created?: string;
    /** @format date-time */
    modified?: string;
    /** @format uuid */
    uuid?: string;
    /** @maxLength 255 */
    company_name?: string;
    /** @maxLength 255 */
    contact_name?: string | null;
    /**
     * @format email
     * @maxLength 254
     */
    email?: string | null;
    /** @maxLength 50 */
    phone?: string | null;
}

export interface PatchedDirectors {
    id?: number;
    company?: number;
    /** @maxLength 255 */
    first_name?: string;
    /** @maxLength 255 */
    last_name?: string;
    /** @format date */
    date_of_birth?: string;
    residential_address?: KriyaKYBAddress;
}

export interface PatchedForecast {
    id?: number;
    /**
     * @min 0
     * @max 2147483647
     */
    months?: number | null;
    /** @format date */
    start_period?: string | null;
    /** @format date-time */
    modified_at?: string;
}

export interface PatchedForecastMonth {
    /** @format uuid */
    uuid?: string;
    /** @format uuid */
    product_forecast?: string;
    /**
     * @min -2147483648
     * @max 2147483647
     */
    opening_stock?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    demand_forecast?: number | null;
    /** @format date */
    date?: string | null;
    production_runs?: ProductionRun[];
}

export interface PatchedIngredient {
    id?: number;
    /** @format uuid */
    uuid?: string;
    /** @maxLength 255 */
    name?: string;
    /**
     * @format uri
     * @maxLength 2048
     */
    logo_url?: string;
    /**
     * @format decimal
     * @default "0.00"
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    current_unit_price?: string | null;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    currency?: Currencies;
    description?: string;
    /**
     * @format decimal
     * @default "0.00"
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    quantity?: string | null;
    /** @default "kg" */
    unit_of_measurement?: UnitOfMeasurementEnum | BlankEnum | NullEnum | null;
    allergen_requirements?: (AllergenRequirementsEnum | BlankEnum)[];
    allergen_declaration?: boolean;
    dietary_certificate?: boolean;
    diet_requirements?: (DietRequirementsEnum | BlankEnum)[];
    current_supplier?: string;
    frequency?: FrequencyEnum | BlankEnum | NullEnum | null;
    /** @format uuid */
    article?: string | null;
    existing_suppliers?: string[] | null;
    /** @format uuid */
    parent_uuid?: string | null;
    inventories?: IngredientInventory[];
    /**
     * @min -2147483648
     * @max 2147483647
     */
    supplier_coverage?: number | null;
}

export interface PatchedIngredientInventory {
    /** @format uuid */
    uuid?: string;
    /** @format uuid */
    ingredient?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,17}(?:\.\d{0,2})?$
     */
    on_hand?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,17}(?:\.\d{0,2})?$
     */
    allocated?: string;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement?: UnitOfMeasurementEnum;
    /**
     * @format decimal
     * @pattern ^-?\d{0,17}(?:\.\d{0,2})?$
     */
    min_stock?: string | null;
    min_stock_unit?: MinStockUnitEnum | BlankEnum | NullEnum | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,17}(?:\.\d{0,2})?$
     */
    storage_capacity?: string | null;
    storage_unit?: StorageUnitEnum | BlankEnum | NullEnum | null;
    /**
     * @min 0
     * @max 2147483647
     */
    shelf_life?: number | null;
    shelf_life_unit?: ShelfLifeUnitEnum | BlankEnum | NullEnum | null;
    /**
     * @min 0
     * @max 2147483647
     */
    lead_time?: number | null;
}

export interface PatchedInquiry {
    id?: number;
    /** @format uuid */
    uuid?: string;
    /**
     * * `awaiting_quotes` - Awaiting Quotes
     * * `quotes_available` - Quotes Available
     * * `ordered` - Ordered
     * * `expired` - Expired
     * * `proceeded` - Proceeded
     * * `contract_confirmed` - Contract Confirmed
     * * `supplier_decline_to_quote` - Supplier Decline To Quote
     * * `cancelled` - Cancelled
     */
    state?: InquiryState;
    /**
     * * `non_recurring` - Non Recurring
     * * `recurring` - Recurring
     * * `purchase_request` - Purchase Request
     * * `ingredient` - Ingredient
     * * `ingredient_copy` - Ingredient Copy
     * * `offline_inquiry` - Offline Inquiry
     * * `sales_inquiry` - Sales Inquiry
     */
    category?: InquiryCategory;
    /** @maxLength 255 */
    name?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    current_unit_price?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    target_unit_price?: string;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    currency?: Currencies;
    description?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    quantity?: string;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement?: UnitOfMeasurementEnum;
    allergen_requirements?: (AllergenRequirementsEnum | BlankEnum)[];
    allergen_declaration?: boolean;
    dietary_certificate?: boolean;
    diet_requirements?: (DietRequirementsEnum | BlankEnum)[];
    forecast?: ForecastEnum | BlankEnum | NullEnum | null;
    /** @format date */
    due_date?: string;
    delivery_address?: number | null;
    /** @maxItems 250 */
    supplier_countries?: (Country | BlankEnum)[];
    current_supplier?: string;
    /**
     * Datetime when the inquiry becomes published. Leave empty to keep it unpublished.
     * @format date-time
     */
    published?: string | null;
    /**
     * @format uri
     * @maxLength 2048
     */
    logo_url?: string;
    is_new?: boolean;
    /** @format uuid */
    article?: string | null;
    existing_suppliers?: string[] | null;
    supply_priorities?: SupplyPriorities[];
    /** @format date */
    first_order_delivery_date?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    opply_brand_commission?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    opply_supplier_commission?: string;
    /** @default false */
    is_recurring?: boolean;
    frequency?: FrequencyEnum | BlankEnum | NullEnum | null;
    /** @min 0 */
    desired_contract_length?: number | null;
    is_delivery_date_flexible?: boolean;
    /** @format uuid */
    parent_uuid?: string | null;
    ingredient?: number | null;
    reason_for_request?: string | null;
    desired_packaging_format?: string;
    desired_packaging_format_is_flexible?: boolean;
    ai_generated?: boolean;
    documents_to_copy?: string[] | null;
}

export interface PatchedInquiryDeliveryAddress {
    id?: number;
    /** @maxLength 255 */
    name?: string;
    /** @maxLength 255 */
    street_1?: string | null;
    /** @maxLength 255 */
    street_2?: string | null;
    /** @maxLength 255 */
    contact_full_name?: string | null;
    /** @maxLength 50 */
    contact_phone?: string | null;
    phone_country_code?: PhoneCountryCodeEnum | BlankEnum | NullEnum | null;
    /** @maxLength 100 */
    town?: string | null;
    /** @maxLength 100 */
    region?: string | null;
    /** @maxLength 100 */
    postcode?: string | null;
    /** @maxLength 20 */
    eori_number?: string;
    delivery_instructions?: string | null;
    /** @maxLength 100 */
    building?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,4}(?:\.\d{0,15})?$
     */
    latitude?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,4}(?:\.\d{0,15})?$
     */
    longitude?: string | null;
    collection_instructions?: string | null;
    /** @format uuid */
    uuid?: string;
    country?: Country | NullEnum | null;
    place_id?: string | null;
    vat_number?: string | null;
    vat_country_code?: Country | NullEnum | null;
    vat_exempt?: boolean;
    country_type?: CountryTypeEnum;
}

export interface PatchedOrderDetail {
    /** @format uuid */
    uuid?: string;
    /** @format date-time */
    created?: string;
    customer_order_number?: string | null;
    /**
     * * `regular` - Regular
     * * `offline_order` - Offline Order
     * * `sales_order` - Sales Order
     */
    category?: OrderCategory;
    /**
     * * `checkout` - Checkout
     * * `confirmed` - confirmed
     * * `pending_payment` - pending_payment
     * * `canceled` - canceled
     * * `awaiting_approval` - awaiting_approval
     * * `on_hold` - on_hold
     * * `placed` - placed
     * * `dispatched` - dispatched
     * * `completed` - completed
     */
    state?: OrderState;
    payment_status?: PaymentStatusEnum | BlankEnum | NullEnum | null;
    kriya_status?: KriyaStatusEnum | BlankEnum | NullEnum | null;
    /**
     * if this is not None, then during payment was chosen not default currency and this field should be passed to the payment as selected_currency
     *
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    payment_currency?: Currencies | BlankEnum | NullEnum | null;
    /** @format date-time */
    payment_due_date?: string | null;
    /** @format date-time */
    kriya_payment_due_date?: string | null;
    payment_method?: PaymentMethod | NullEnum | null;
    payment_scenario?: string;
    /**
     * * `pending_dispatch` - pending_dispatch
     * * `dispatched` - Dispatched
     * * `delivered` - Delivered
     */
    shipment_status?: ShipmentStatusEnum;
    /** @format date */
    dispatch_confirmation_date?: string | null;
    /** @format date */
    delivery_confirmation_date?: string | null;
    is_new?: boolean;
    order_number?: string;
    number_of_quotes_ordered?: number;
    total_price?: number;
    total_price_currency?: string;
    /** @format date */
    delivery_date?: string;
    shipping_price?: number;
    shipping_cost_no_vat?: number;
    sum_to_pay?: number;
    /** @format date */
    latest_delivery_date?: string | null;
    discount_amount?: number;
    sum_to_pay_no_discount?: number;
    quotes_prices?: PricesRetrieve[];
    vat_sum?: number;
    sum_payout?: number;
    total_price_for_sales_order?: number;
    customer_name?: string;
    /** @format date */
    order_date?: string | null;
    payment_delay?: number | null;
    has_pending_purchase_request?: boolean;
    is_recurring?: boolean;
    /** @format uuid */
    related_contract_uuid?: string;
    /** @format uuid */
    purchase_quote_uuid?: string;
    /** @format date-time */
    state_updated?: string;
    tracking_code?: string | null;
    selected_currency?: string;
    virtual_bank_account?: BankTransfer | null;
    quotes?: QuoteOrderDetail[];
    delivery_address?: DeliveryAddressResponse;
    terms?: string | null;
    /** @format uuid */
    customer_uuid?: string;
    /** @format date */
    expected_delivery_date?: string | null;
    /** @maxLength 255 */
    shipping_provider?: string | null;
    delivery_contact?: string;
    delivery_contact_number?: string;
    /** @format uuid */
    contract_uuid?: string;
}

export interface PatchedOrderDispute {
    /** @format uuid */
    uuid?: string;
    /** @format date-time */
    created?: string;
    reasons_for_dispute?: string;
}

export interface PatchedProductForecast {
    /** @format uuid */
    uuid?: string;
    /** @format uuid */
    product?: string;
    /**
     * @min 0
     * @max 2147483647
     */
    production_frequency?: number | null;
    custom_frequency?: boolean;
    is_modified?: boolean;
    /** @format date */
    first_production_run?: string | null;
    /**
     * @min 0
     * @max 2147483647
     */
    forecast_unit_sales?: number | null;
    /** @format date-time */
    modified_at?: string;
}

export interface PatchedProductInventory {
    /** @format uuid */
    uuid?: string;
    /** @format uuid */
    product?: string;
    /**
     * @min -2147483648
     * @max 2147483647
     */
    in_stock?: number;
    /**
     * @min -2147483648
     * @max 2147483647
     */
    in_production?: number;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement?: UnitOfMeasurementEnum;
}

export interface PatchedProductionRun {
    /** @format uuid */
    uuid?: string;
    /** @format uuid */
    forecast_month?: string;
    /** @format date */
    run_date?: string | null;
    /**
     * @min 0
     * @max 2147483647
     */
    amount?: number | null;
}

export interface PatchedQuoteDispatchAddress {
    id?: number;
    /** @maxLength 255 */
    name?: string;
    /** @maxLength 255 */
    street_1?: string | null;
    /** @maxLength 255 */
    street_2?: string | null;
    /** @maxLength 255 */
    contact_full_name?: string | null;
    /** @maxLength 50 */
    contact_phone?: string | null;
    phone_country_code?: PhoneCountryCodeEnum | BlankEnum | NullEnum | null;
    /** @maxLength 100 */
    town?: string | null;
    /** @maxLength 100 */
    region?: string | null;
    /** @maxLength 100 */
    postcode?: string | null;
    /** @maxLength 20 */
    eori_number?: string;
    delivery_instructions?: string | null;
    /** @maxLength 100 */
    building?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,4}(?:\.\d{0,15})?$
     */
    latitude?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,4}(?:\.\d{0,15})?$
     */
    longitude?: string | null;
    collection_instructions?: string | null;
    /** @format uuid */
    uuid?: string;
    country?: Country | NullEnum | null;
    place_id?: string | null;
    vat_number?: string | null;
    vat_country_code?: Country | NullEnum | null;
    vat_exempt?: boolean;
    country_type?: CountryTypeEnum;
}

export interface PatchedQuoteFeesAdmin {
    id?: number;
    /** @format uuid */
    uuid?: string;
    quote?: number;
    /** @maxLength 255 */
    title?: string;
    description?: string | null;
    /**
     * * `buyer_to_opply` - Buyer To Opply
     * * `buyer_to_supplier` - Buyer To Supplier
     * * `supplier_to_buyer` - Supplier To Buyer
     * * `supplier_to_opply` - Supplier To Opply
     * * `opply_to_supplier` - Opply To Supplier
     */
    category?: QuoteFeesAdminCategoryEnum;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,10})?$
     */
    amount?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,1}(?:\.\d{0,4})?$
     */
    vat_rate?: string;
    currency?: Currencies;
}

export interface PatchedShareholders {
    id?: number;
    company?: number;
    /** @maxLength 255 */
    first_name?: string;
    /** @maxLength 255 */
    last_name?: string;
    /** @format date */
    date_of_birth?: string;
    /**
     * @min 25
     * @max 100
     */
    ownership_percentage?: number;
    residential_address?: KriyaKYBAddress;
}

export interface PatchedSupplierCompany {
    id?: number;
    /** @format uuid */
    uuid?: string;
    /** @format uri */
    website?: string;
    /** @maxLength 255 */
    name?: string;
    /** @format uri */
    logo?: string | null;
    /** @maxLength 100 */
    industry?: string | null;
    kyb_status?: string;
    /** @maxLength 50 */
    phone_number?: string | null;
    country?: string;
    state?: string;
    /**
     * * `brand` - Brand
     * * `supplier` - Supplier
     */
    company_type?: CompanyTypeEnum;
    /** @default ["h","r","e","o","t"] */
    product_type?: ProductTypeEnum[];
    /** @default ["h","r","e","o","t"] */
    activity_type?: ActivityTypeEnum[];
    /** @maxLength 255 */
    registration_number?: string | null;
    mongo_id?: string | null;
    referral_code?: string;
    phone_country_code?: PhoneCountryCodeEnum | BlankEnum | NullEnum | null;
    company_addresses?: CompanyAddress[];
    currency?: string;
    /**
     * rapyd payout method
     * @maxLength 45
     */
    payout_method_type?: string | null;
    bank_account_country?: Country | BlankEnum | NullEnum | null;
}

export interface PatchedSupplierQuoteUpdate {
    company?: Company;
    /** @maxLength 255 */
    product_name?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
     */
    quantity?: string;
    /** @format date */
    delivery_date?: string;
    /**
     * * `in_advance` - In Advance
     * * `on_dispatch` - On Dispatch
     */
    payment_scenario?: PaymentScenarioEnum;
    /**
     * @min 0
     * @max 365
     */
    payment_delay?: number;
    /**
     * @min 0
     * @default 0
     */
    shelf_life?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    quote_valid_for?: number | null;
    /**
     * The maximum time a supplier agrees to deliver the product within (days)
     * @min 0
     * @max 2147483647
     */
    lead_time?: number;
    product_description?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    shipping_cost?: string | null;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    currency?: Currencies;
    /**
     * Datetime when the quote becomes published. Leave empty to keep it unpublished.
     * @format date-time
     */
    published?: string | null;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement?: UnitOfMeasurementEnum;
    /** @format date-time */
    created_date?: string;
    /**
     * @format uri
     * @maxLength 2048
     */
    logo_url?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,10})?$
     */
    total_price?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,10})?$
     */
    unit_price?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,1}(?:\.\d{0,4})?$
     */
    vat_rate?: string | null;
    is_reverse_charge?: boolean;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    commission_payout_vat_rate?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    commission_payin_vat_rate?: string;
    commission_is_reverse_charge?: boolean;
    /** @maxLength 32 */
    sku?: string | null;
    /** @default "delivery_duty_paid" */
    incoterms?: IncotermsEnum | NullEnum | null;
    dispatch_address?: number | null;
    /**
     * * `available` - Available
     * * `requested` - Requested
     * * `sent` - Sent
     * * `received` - Received
     * * `accepted` - Accepted
     * * `rejected` - Rejected
     * * `declined` - Declined
     */
    samples_status?: SamplesStatusEnum;
    samples_delivery_address?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    recurring_order_lead_time?: number | null;
    /**
     * Duration of the contract in months.
     * @min -2147483648
     * @max 2147483647
     */
    contract_length?: number | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,17}(?:\.\d{0,2})?$
     */
    case_size?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,17}(?:\.\d{0,2})?$
     */
    minimum_order_quantity?: string | null;
    agreement_required?: boolean;
    account_form_required?: boolean;
    /**
     * * `non_recurring` - Non Recurring
     * * `recurring` - Recurring
     * * `purchase_request_quote` - Purchase Request Quote
     * * `offline_quote` - Offline Quote
     * * `sales_quote` - Sales Quote
     */
    category?: QuoteCategory;
    /** @format date */
    samples_declined_date?: string | null;
    samples_feedback?: SamplesFeedbackEnum[] | null;
    samples_feedback_comment?: string;
    /**
     * * `in_advance` - In Advance
     * * `on_dispatch` - On Dispatch
     * * `7_days_after_dispatch` - Days After Dispatch 7
     * * `14_days_after_dispatch` - Days After Dispatch 14
     * * `30_days_after_dispatch` - Days After Dispatch 30
     * * `60_days_after_dispatch` - Days After Dispatch 60
     */
    payment_terms?: PaymentTermsEnum;
    is_custom_contract?: boolean;
    /** @format date */
    samples_dispatch_date?: string | null;
    /** @format date */
    samples_sent_date?: string | null;
    samples_tracking_code?: string | null;
    /** @format date */
    samples_expected_delivery_date?: string | null;
    /** @maxLength 255 */
    samples_shipping_provider?: string | null;
    autoconfirm_orders?: boolean;
    /**
     * @min 0
     * @max 2147483647
     */
    minimum_lead_time?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    order_in_multiples_of?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    minimum_autoconfirm_order_quantity?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    maximum_autoconfirm_order_quantity?: number | null;
    packaging_format?: string;
    show_to_other_buyers?: boolean;
    description_blocks?: QuoteDescriptionBlockCreate[] | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,1}(?:\.\d{0,4})?$
     */
    shipping_vat_rate?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
     */
    total_forecast_quantity?: string | null;
    /**
     * * `total` - total
     * * `unit` - unit
     */
    selected_price_option?: SelectedPriceOptionEnum;
}

export interface PatchedUpdatePurchaseRequest {
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    quantity?: string;
    /** @format date */
    due_date?: string;
    delivery_address?: number | null;
    /** @format uuid */
    purchase_quote_uuid?: string;
}

export interface PatchedUploadedProductsCatalogue {
    id?: number;
    /** @format uuid */
    uuid?: string;
    /**
     * * `pending` - Pending
     * * `being_processed` - Is being processed
     * * `processed` - Processed
     * * `failed` - Failed
     */
    status?: UploadedProductsCatalogueStatus;
    /** @format uri */
    catalogue?: string | null;
}

export interface PatchedUser {
    id?: number;
    /** @format uuid */
    uuid?: string;
    /** @format email */
    email?: string;
    /** @maxLength 150 */
    first_name?: string;
    /** @maxLength 150 */
    last_name?: string;
    /** @maxLength 255 */
    job_title?: string | null;
    /** @format double */
    tc_version?: number;
}

export interface PatchedUserList {
    /** @format uuid */
    uuid?: string;
    /** @maxLength 150 */
    first_name?: string;
    /** @maxLength 150 */
    last_name?: string;
    /**
     * @format email
     * @maxLength 254
     */
    email?: string;
    company_name?: string | null;
    last_login?: UserAction;
}

export interface PaymentAvailability {
    /** @format uuid */
    order_id: string;
}

export interface PaymentDetailsInvoice {
    /**
     * * `card` - Card
     * * `bank_transfer` - Bank Transfer
     * * `PAY30` - Kriya 30
     * * `PAY30EOFM` - Kriya Next Month
     */
    payment_method: PaymentMethod;
    /**
     * * `in_advance` - In Advance
     * * `on_dispatch` - On Dispatch
     */
    payment_scenario: PaymentScenarioEnum;
    bank_transfer_details: any;
}

/**
 * * `card` - Card
 * * `bank_transfer` - Bank Transfer
 * * `PAY30` - Kriya 30
 * * `PAY30EOFM` - Kriya Next Month
 */
export enum PaymentMethod {
    Card = "card",
    BankTransfer = "bank_transfer",
    PAY30 = "PAY30",
    PAY30EOFM = "PAY30EOFM",
}

/**
 * * `in_advance` - In Advance
 * * `on_dispatch` - On Dispatch
 */
export enum PaymentScenarioEnum {
    InAdvance = "in_advance",
    OnDispatch = "on_dispatch",
}

/**
 * * `paid` - paid
 * * `payment_upcoming` - payment_upcoming
 * * `payment_processing` - payment_processing
 * * `payment_due` - payment_due
 */
export enum PaymentStatusEnum {
    Paid = "paid",
    PaymentUpcoming = "payment_upcoming",
    PaymentProcessing = "payment_processing",
    PaymentDue = "payment_due",
}

/**
 * * `in_advance` - In Advance
 * * `on_dispatch` - On Dispatch
 * * `7_days_after_dispatch` - Days After Dispatch 7
 * * `14_days_after_dispatch` - Days After Dispatch 14
 * * `30_days_after_dispatch` - Days After Dispatch 30
 * * `60_days_after_dispatch` - Days After Dispatch 60
 */
export enum PaymentTermsEnum {
    InAdvance = "in_advance",
    OnDispatch = "on_dispatch",
    Value7DaysAfterDispatch = "7_days_after_dispatch",
    Value14DaysAfterDispatch = "14_days_after_dispatch",
    Value30DaysAfterDispatch = "30_days_after_dispatch",
    Value60DaysAfterDispatch = "60_days_after_dispatch",
}

export interface PayoutCallback {
    beneficiary_id: string;
    payout_method_type: string;
    merchant_reference_id: string;
}

export interface PayoutCompanyType {
    /**
     * * `Sole Proprietor` - Sole Proprietor
     * * `Private Limited Company` - Private Limited Company
     */
    company_type: PayoutCompanyTypeCompanyTypeEnum;
}

/**
 * * `Sole Proprietor` - Sole Proprietor
 * * `Private Limited Company` - Private Limited Company
 */
export enum PayoutCompanyTypeCompanyTypeEnum {
    SoleProprietor = "Sole Proprietor",
    PrivateLimitedCompany = "Private Limited Company",
}

/**
 * * `+376` - +376
 * * `+971` - +971
 * * `+93` - +93
 * * `+1268` - +1268
 * * `+1264` - +1264
 * * `+355` - +355
 * * `+374` - +374
 * * `+244` - +244
 * * `+672` - +672
 * * `+54` - +54
 * * `+1684` - +1684
 * * `+43` - +43
 * * `+61` - +61
 * * `+297` - +297
 * * `+994` - +994
 * * `+387` - +387
 * * `+1246` - +1246
 * * `+880` - +880
 * * `+32` - +32
 * * `+226` - +226
 * * `+359` - +359
 * * `+973` - +973
 * * `+257` - +257
 * * `+229` - +229
 * * `+590` - +590
 * * `+1441` - +1441
 * * `+673` - +673
 * * `+591` - +591
 * * `+55` - +55
 * * `+1242` - +1242
 * * `+975` - +975
 * * `+267` - +267
 * * `+375` - +375
 * * `+501` - +501
 * * `+1` - +1
 * * `+243` - +243
 * * `+236` - +236
 * * `+242` - +242
 * * `+41` - +41
 * * `+225` - +225
 * * `+682` - +682
 * * `+56` - +56
 * * `+237` - +237
 * * `+86` - +86
 * * `+57` - +57
 * * `+506` - +506
 * * `+53` - +53
 * * `+238` - +238
 * * `+357` - +357
 * * `+420` - +420
 * * `+49` - +49
 * * `+253` - +253
 * * `+45` - +45
 * * `+1767` - +1767
 * * `+1809` - +1809
 * * `+213` - +213
 * * `+593` - +593
 * * `+372` - +372
 * * `+20` - +20
 * * `+212` - +212
 * * `+291` - +291
 * * `+34` - +34
 * * `+251` - +251
 * * `+358` - +358
 * * `+679` - +679
 * * `+500` - +500
 * * `+691` - +691
 * * `+298` - +298
 * * `+33` - +33
 * * `+241` - +241
 * * `+44` - +44
 * * `+1473` - +1473
 * * `+995` - +995
 * * `+594` - +594
 * * `+441481` - +441481
 * * `+233` - +233
 * * `+350` - +350
 * * `+299` - +299
 * * `+220` - +220
 * * `+224` - +224
 * * `+240` - +240
 * * `+30` - +30
 * * `+502` - +502
 * * `+1671` - +1671
 * * `+245` - +245
 * * `+595` - +595
 * * `+852` - +852
 * * `+504` - +504
 * * `+385` - +385
 * * `+509` - +509
 * * `+36` - +36
 * * `+62` - +62
 * * `+353` - +353
 * * `+972` - +972
 * * `+441624` - +441624
 * * `+91` - +91
 * * `+246` - +246
 * * `+964` - +964
 * * `+98` - +98
 * * `+354` - +354
 * * `+39` - +39
 * * `+441534` - +441534
 * * `+1876` - +1876
 * * `+962` - +962
 * * `+81` - +81
 * * `+254` - +254
 * * `+996` - +996
 * * `+855` - +855
 * * `+686` - +686
 * * `+269` - +269
 * * `+1869` - +1869
 * * `+850` - +850
 * * `+82` - +82
 * * `+965` - +965
 * * `+345` - +345
 * * `+7` - +7
 * * `+856` - +856
 * * `+961` - +961
 * * `+1758` - +1758
 * * `+423` - +423
 * * `+94` - +94
 * * `+231` - +231
 * * `+266` - +266
 * * `+370` - +370
 * * `+352` - +352
 * * `+371` - +371
 * * `+218` - +218
 * * `+377` - +377
 * * `+373` - +373
 * * `+382` - +382
 * * `+261` - +261
 * * `+692` - +692
 * * `+389` - +389
 * * `+223` - +223
 * * `+95` - +95
 * * `+976` - +976
 * * `+853` - +853
 * * `+1670` - +1670
 * * `+596` - +596
 * * `+222` - +222
 * * `+1664` - +1664
 * * `+356` - +356
 * * `+230` - +230
 * * `+960` - +960
 * * `+265` - +265
 * * `+52` - +52
 * * `+60` - +60
 * * `+258` - +258
 * * `+264` - +264
 * * `+687` - +687
 * * `+227` - +227
 * * `+234` - +234
 * * `+505` - +505
 * * `+31` - +31
 * * `+47` - +47
 * * `+977` - +977
 * * `+674` - +674
 * * `+683` - +683
 * * `+64` - +64
 * * `+968` - +968
 * * `+507` - +507
 * * `+51` - +51
 * * `+689` - +689
 * * `+675` - +675
 * * `+63` - +63
 * * `+92` - +92
 * * `+48` - +48
 * * `+508` - +508
 * * `+1787` - +1787
 * * `+970` - +970
 * * `+351` - +351
 * * `+680` - +680
 * * `+974` - +974
 * * `+262` - +262
 * * `+40` - +40
 * * `+381` - +381
 * * `+250` - +250
 * * `+966` - +966
 * * `+677` - +677
 * * `+248` - +248
 * * `+249` - +249
 * * `+46` - +46
 * * `+65` - +65
 * * `+290` - +290
 * * `+386` - +386
 * * `+421` - +421
 * * `+232` - +232
 * * `+378` - +378
 * * `+221` - +221
 * * `+252` - +252
 * * `+597` - +597
 * * `+239` - +239
 * * `+503` - +503
 * * `+963` - +963
 * * `+268` - +268
 * * `+1649` - +1649
 * * `+235` - +235
 * * `+228` - +228
 * * `+66` - +66
 * * `+992` - +992
 * * `+690` - +690
 * * `+670` - +670
 * * `+993` - +993
 * * `+216` - +216
 * * `+676` - +676
 * * `+90` - +90
 * * `+1868` - +1868
 * * `+688` - +688
 * * `+886` - +886
 * * `+255` - +255
 * * `+380` - +380
 * * `+256` - +256
 * * `+598` - +598
 * * `+998` - +998
 * * `+379` - +379
 * * `+1784` - +1784
 * * `+58` - +58
 * * `+1284` - +1284
 * * `+1340` - +1340
 * * `+84` - +84
 * * `+678` - +678
 * * `+681` - +681
 * * `+685` - +685
 * * `+967` - +967
 * * `+27` - +27
 * * `+260` - +260
 * * `+263` - +263
 */
export enum PhoneCountryCodeEnum {
    Value376 = "+376",
    Value971 = "+971",
    Value93 = "+93",
    Value1268 = "+1268",
    Value1264 = "+1264",
    Value355 = "+355",
    Value374 = "+374",
    Value244 = "+244",
    Value672 = "+672",
    Value54 = "+54",
    Value1684 = "+1684",
    Value43 = "+43",
    Value61 = "+61",
    Value297 = "+297",
    Value994 = "+994",
    Value387 = "+387",
    Value1246 = "+1246",
    Value880 = "+880",
    Value32 = "+32",
    Value226 = "+226",
    Value359 = "+359",
    Value973 = "+973",
    Value257 = "+257",
    Value229 = "+229",
    Value590 = "+590",
    Value1441 = "+1441",
    Value673 = "+673",
    Value591 = "+591",
    Value55 = "+55",
    Value1242 = "+1242",
    Value975 = "+975",
    Value267 = "+267",
    Value375 = "+375",
    Value501 = "+501",
    Value1 = "+1",
    Value243 = "+243",
    Value236 = "+236",
    Value242 = "+242",
    Value41 = "+41",
    Value225 = "+225",
    Value682 = "+682",
    Value56 = "+56",
    Value237 = "+237",
    Value86 = "+86",
    Value57 = "+57",
    Value506 = "+506",
    Value53 = "+53",
    Value238 = "+238",
    Value357 = "+357",
    Value420 = "+420",
    Value49 = "+49",
    Value253 = "+253",
    Value45 = "+45",
    Value1767 = "+1767",
    Value1809 = "+1809",
    Value213 = "+213",
    Value593 = "+593",
    Value372 = "+372",
    Value20 = "+20",
    Value212 = "+212",
    Value291 = "+291",
    Value34 = "+34",
    Value251 = "+251",
    Value358 = "+358",
    Value679 = "+679",
    Value500 = "+500",
    Value691 = "+691",
    Value298 = "+298",
    Value33 = "+33",
    Value241 = "+241",
    Value44 = "+44",
    Value1473 = "+1473",
    Value995 = "+995",
    Value594 = "+594",
    Value441481 = "+441481",
    Value233 = "+233",
    Value350 = "+350",
    Value299 = "+299",
    Value220 = "+220",
    Value224 = "+224",
    Value240 = "+240",
    Value30 = "+30",
    Value502 = "+502",
    Value1671 = "+1671",
    Value245 = "+245",
    Value595 = "+595",
    Value852 = "+852",
    Value504 = "+504",
    Value385 = "+385",
    Value509 = "+509",
    Value36 = "+36",
    Value62 = "+62",
    Value353 = "+353",
    Value972 = "+972",
    Value441624 = "+441624",
    Value91 = "+91",
    Value246 = "+246",
    Value964 = "+964",
    Value98 = "+98",
    Value354 = "+354",
    Value39 = "+39",
    Value441534 = "+441534",
    Value1876 = "+1876",
    Value962 = "+962",
    Value81 = "+81",
    Value254 = "+254",
    Value996 = "+996",
    Value855 = "+855",
    Value686 = "+686",
    Value269 = "+269",
    Value1869 = "+1869",
    Value850 = "+850",
    Value82 = "+82",
    Value965 = "+965",
    Value345 = "+345",
    Value7 = "+7",
    Value856 = "+856",
    Value961 = "+961",
    Value1758 = "+1758",
    Value423 = "+423",
    Value94 = "+94",
    Value231 = "+231",
    Value266 = "+266",
    Value370 = "+370",
    Value352 = "+352",
    Value371 = "+371",
    Value218 = "+218",
    Value377 = "+377",
    Value373 = "+373",
    Value382 = "+382",
    Value261 = "+261",
    Value692 = "+692",
    Value389 = "+389",
    Value223 = "+223",
    Value95 = "+95",
    Value976 = "+976",
    Value853 = "+853",
    Value1670 = "+1670",
    Value596 = "+596",
    Value222 = "+222",
    Value1664 = "+1664",
    Value356 = "+356",
    Value230 = "+230",
    Value960 = "+960",
    Value265 = "+265",
    Value52 = "+52",
    Value60 = "+60",
    Value258 = "+258",
    Value264 = "+264",
    Value687 = "+687",
    Value227 = "+227",
    Value234 = "+234",
    Value505 = "+505",
    Value31 = "+31",
    Value47 = "+47",
    Value977 = "+977",
    Value674 = "+674",
    Value683 = "+683",
    Value64 = "+64",
    Value968 = "+968",
    Value507 = "+507",
    Value51 = "+51",
    Value689 = "+689",
    Value675 = "+675",
    Value63 = "+63",
    Value92 = "+92",
    Value48 = "+48",
    Value508 = "+508",
    Value1787 = "+1787",
    Value970 = "+970",
    Value351 = "+351",
    Value680 = "+680",
    Value974 = "+974",
    Value262 = "+262",
    Value40 = "+40",
    Value381 = "+381",
    Value250 = "+250",
    Value966 = "+966",
    Value677 = "+677",
    Value248 = "+248",
    Value249 = "+249",
    Value46 = "+46",
    Value65 = "+65",
    Value290 = "+290",
    Value386 = "+386",
    Value421 = "+421",
    Value232 = "+232",
    Value378 = "+378",
    Value221 = "+221",
    Value252 = "+252",
    Value597 = "+597",
    Value239 = "+239",
    Value503 = "+503",
    Value963 = "+963",
    Value268 = "+268",
    Value1649 = "+1649",
    Value235 = "+235",
    Value228 = "+228",
    Value66 = "+66",
    Value992 = "+992",
    Value690 = "+690",
    Value670 = "+670",
    Value993 = "+993",
    Value216 = "+216",
    Value676 = "+676",
    Value90 = "+90",
    Value1868 = "+1868",
    Value688 = "+688",
    Value886 = "+886",
    Value255 = "+255",
    Value380 = "+380",
    Value256 = "+256",
    Value598 = "+598",
    Value998 = "+998",
    Value379 = "+379",
    Value1784 = "+1784",
    Value58 = "+58",
    Value1284 = "+1284",
    Value1340 = "+1340",
    Value84 = "+84",
    Value678 = "+678",
    Value681 = "+681",
    Value685 = "+685",
    Value967 = "+967",
    Value27 = "+27",
    Value260 = "+260",
    Value263 = "+263",
}

export interface PricesRetrieve {
    currency?: string;
    name?: string;
    /** @format uuid */
    quote_uuid?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    sum_to_pay: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    unit_price: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    total_price_commission_with_vat: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    sum_payout: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    total_shipping_cost: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    total_price: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    total_price_with_vat: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    unit_price_with_vat: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    total_price_with_vat_with_shipping_with_vat: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    unit_price_with_commission_with_vat: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    unit_price_no_vat_with_shipping: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    total_price_no_vat_with_shipping: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    unit_price_no_vat: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    total_price_no_vat: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    shipping_cost_no_vat: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    vat_sum: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    unit_price_with_vat_with_shipping: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    total_price_for_sales_order: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    discount_for_sales_orders: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    payout_per_unit: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    sum_payout_no_shipping: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    payout_per_unit_no_shipping: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    vat_sum_no_commission: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    additional_buyer_to_opply_fees_no_vat: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    additional_buyer_to_opply_fees_with_vat: string;
    additional_fees_vat_rate: string[];
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    vat_sum_for_supplier: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    vat_sum_no_commission_for_supplier: string;
}

export interface PricingScheme {
    /**
     * * `PAY30` - Kriya 30
     * * `PAY30EOFM` - Kriya Next Month
     */
    identifier: KriyaPaymentMethod;
    /** @maxLength 50 */
    name: string;
    /** @maxLength 255 */
    description: string;
    is_enabled: boolean;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    fee_percentage: string;
}

/**
 * * `price` - Price
 * * `quality` - Quality
 * * `service` - Service
 */
export enum PriorityNameEnum {
    Price = "price",
    Quality = "quality",
    Service = "service",
}

export interface ProcessCSV {
    /** @format uuid */
    company_document_uuid: string;
}

/**
 * * `fresh_fruits_vegetables` - Fresh Fruit & Vegetables
 * * `nuts_seeds_dried_fruit` - Nuts, Seeds & Dried Fruit
 * * `herbs_spices_seasoning` - Herbs, Spices & Seasonings
 * * `meat_fish` - Meat & Fish
 * * `dairy_alts` - Dairy & Dairy Alternatives
 * * `beans_pulses_grains` - Beans, Pulses & Grains
 * * `flav_colours` - Flavourings & Colours
 * * `flours_baking_ing` - Flours & Baking Ingredients
 * * `sugars_sweeteners` - Sugars & Sweeteners
 * * `fats_oils` - Fats & Oils
 * * `tea_coffee` - Tea & Coffee
 * * `additives_preservatives` - Additives & Preservatives
 * * `juices_concentrates` - Juices & Concentrates
 * * `extracts_tinctures` - Extracts & Tinctures
 * * `pastes_purees` - Pastes & Purees
 * * `whole_food_powders` - Whole Food Powders
 * * `other` - Other
 */
export enum ProductCategoryEnum {
    FreshFruitsVegetables = "fresh_fruits_vegetables",
    NutsSeedsDriedFruit = "nuts_seeds_dried_fruit",
    HerbsSpicesSeasoning = "herbs_spices_seasoning",
    MeatFish = "meat_fish",
    DairyAlts = "dairy_alts",
    BeansPulsesGrains = "beans_pulses_grains",
    FlavColours = "flav_colours",
    FloursBakingIng = "flours_baking_ing",
    SugarsSweeteners = "sugars_sweeteners",
    FatsOils = "fats_oils",
    TeaCoffee = "tea_coffee",
    AdditivesPreservatives = "additives_preservatives",
    JuicesConcentrates = "juices_concentrates",
    ExtractsTinctures = "extracts_tinctures",
    PastesPurees = "pastes_purees",
    WholeFoodPowders = "whole_food_powders",
    Other = "other",
}

export interface ProductDetails {
    name: string;
    quantity: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    unit_final_price: string;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement: UnitOfMeasurementEnum;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    total_price: string;
    sku: string;
    vat_rate?: string;
    description_blocks?: QuoteDescriptionBlockCreate[] | null;
    /** @default "0.00" */
    shipping_vat_rate?: string;
}

export interface ProductForecast {
    /** @format uuid */
    uuid: string;
    /** @format uuid */
    product: string;
    /**
     * @min 0
     * @max 2147483647
     */
    production_frequency?: number | null;
    custom_frequency?: boolean;
    is_modified?: boolean;
    /** @format date */
    first_production_run?: string | null;
    /**
     * @min 0
     * @max 2147483647
     */
    forecast_unit_sales?: number | null;
    /** @format date-time */
    modified_at: string;
}

export interface ProductInventory {
    /** @format uuid */
    uuid?: string;
    /** @format uuid */
    product: string;
    /**
     * @min -2147483648
     * @max 2147483647
     */
    in_stock?: number;
    /**
     * @min -2147483648
     * @max 2147483647
     */
    in_production?: number;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement?: UnitOfMeasurementEnum;
}

/**
 * * `confect_snacks` - Confectionery & Snacks
 * * `conv_food` - Convenience Food
 * * `bread_cereals` - Bread & Cereals
 * * `sauces_spices` - Sauces & Spices
 * * `spreads` - Spreads
 * * `tea` - Tea
 * * `coffee` - Coffee
 * * `water` - Water
 * * `carb_drinks` - Carbonated Drinks
 * * `alc_drinks` - Alcoholic Drinks
 * * `juice_drinks` - Juice Drinks
 * * `energy_drinks` - Energy Drinks
 * * `cosmetics` - Cosmetics
 * * `milk_dairy` - Milk & Dairy
 * * `free_form_alt` - Free Form Alternative
 * * `other` - Other
 */
export enum ProductTypeEnum {
    ConfectSnacks = "confect_snacks",
    ConvFood = "conv_food",
    BreadCereals = "bread_cereals",
    SaucesSpices = "sauces_spices",
    Spreads = "spreads",
    Tea = "tea",
    Coffee = "coffee",
    Water = "water",
    CarbDrinks = "carb_drinks",
    AlcDrinks = "alc_drinks",
    JuiceDrinks = "juice_drinks",
    EnergyDrinks = "energy_drinks",
    Cosmetics = "cosmetics",
    MilkDairy = "milk_dairy",
    FreeFormAlt = "free_form_alt",
    Other = "other",
}

export interface ProductionComplete {
    quantity_complete: number;
}

export interface ProductionRun {
    /** @format uuid */
    uuid: string;
    /** @format uuid */
    forecast_month: string;
    /** @format date */
    run_date?: string | null;
    /**
     * @min 0
     * @max 2147483647
     */
    amount?: number | null;
}

export interface ProductionSchedule {
    /** @format uuid */
    uuid: string;
    /** @format uuid */
    product: string;
    /**
     * @min 0
     * @max 2147483647
     */
    production_frequency?: number | null;
    custom_frequency?: boolean;
    is_modified?: boolean;
    /** @format date */
    first_production_run?: string | null;
    /**
     * @min 0
     * @max 2147483647
     */
    forecast_unit_sales?: number | null;
    /** @format date-time */
    modified_at: string;
    months: ForecastMonth[];
}

export interface PurchaseOrder {
    order_number: string;
    /** @format date-time */
    created: string;
    /** @format date-time */
    payment_due_date: string;
    shipping: PurchaseOrderShipping;
    /**
     * * `HUF` - HUF
     * * `SVC` - SVC
     * * `MZN` - MZN
     * * `BOB` - BOB
     * * `GHS` - GHS
     * * `MAD` - MAD
     * * `USD` - USD
     * * `ECS` - ECS
     * * `AFA` - AFA
     * * `ECV` - ECV
     * * `ZAR` - ZAR
     * * `CNH` - CNH
     * * `GEK` - GEK
     * * `TWD` - TWD
     * * `FRF` - FRF
     * * `SUR` - SUR
     * * `VNN` - VNN
     * * `TTD` - TTD
     * * `ZWD` - ZWD
     * * `ALL` - ALL
     * * `LTL` - LTL
     * * `LVL` - LVL
     * * `JOD` - JOD
     * * `CNY` - CNY
     * * `LBP` - LBP
     * * `BYB` - BYB
     * * `ARM` - ARM
     * * `KPW` - KPW
     * * `CYP` - CYP
     * * `AZN` - AZN
     * * `LTT` - LTT
     * * `XTS` - XTS
     * * `BYR` - BYR
     * * `CNX` - CNX
     * * `HKD` - HKD
     * * `RSD` - RSD
     * * `BGN` - BGN
     * * `ERN` - ERN
     * * `EEK` - EEK
     * * `GHC` - GHC
     * * `TJS` - TJS
     * * `SLL` - SLL
     * * `ARL` - ARL
     * * `BUK` - BUK
     * * `ARS` - ARS
     * * `SSP` - SSP
     * * `TOP` - TOP
     * * `XCD` - XCD
     * * `SEK` - SEK
     * * `PAB` - PAB
     * * `XAU` - XAU
     * * `KGS` - KGS
     * * `DJF` - DJF
     * * `XFU` - XFU
     * * `SIT` - SIT
     * * `AON` - AON
     * * `EUR` - EUR
     * * `NIO` - NIO
     * * `BGL` - BGL
     * * `AFN` - AFN
     * * `MMK` - MMK
     * * `USS` - USS
     * * `BOL` - BOL
     * * `BRE` - BRE
     * * `AUD` - AUD
     * * `UGS` - UGS
     * * `LUF` - LUF
     * * `JMD` - JMD
     * * `COU` - COU
     * * `GYD` - GYD
     * * `STD` - STD
     * * `MDL` - MDL
     * * `YUN` - YUN
     * * `DZD` - DZD
     * * `NGN` - NGN
     * * `UYU` - UYU
     * * `SYP` - SYP
     * * `ADP` - ADP
     * * `XBC` - XBC
     * * `MLF` - MLF
     * * `XUA` - XUA
     * * `XXX` - XXX
     * * `ISK` - ISK
     * * `BDT` - BDT
     * * `NZD` - NZD
     * * `ETB` - ETB
     * * `HTG` - HTG
     * * `BEC` - BEC
     * * `SAR` - SAR
     * * `AZM` - AZM
     * * `KWD` - KWD
     * * `CLP` - CLP
     * * `SDD` - SDD
     * * `INR` - INR
     * * `ATS` - ATS
     * * `AWG` - AWG
     * * `IEP` - IEP
     * * `GNF` - GNF
     * * `ILS` - ILS
     * * `SDP` - SDP
     * * `XOF` - XOF
     * * `UGX` - UGX
     * * `GEL` - GEL
     * * `YER` - YER
     * * `BRZ` - BRZ
     * * `ZWR` - ZWR
     * * `TMT` - TMT
     * * `XBB` - XBB
     * * `CRC` - CRC
     * * `MGA` - MGA
     * * `BOV` - BOV
     * * `SLE` - SLE
     * * `CUC` - CUC
     * * `CSD` - CSD
     * * `AOA` - AOA
     * * `SDG` - SDG
     * * `UYP` - UYP
     * * `MNT` - MNT
     * * `BRB` - BRB
     * * `YUM` - YUM
     * * `ZAL` - ZAL
     * * `XEU` - XEU
     * * `VEF` - VEF
     * * `PKR` - PKR
     * * `BHD` - BHD
     * * `PLN` - PLN
     * * `ITL` - ITL
     * * `TND` - TND
     * * `MWK` - MWK
     * * `LUC` - LUC
     * * `MDC` - MDC
     * * `BMD` - BMD
     * * `QAR` - QAR
     * * `PEI` - PEI
     * * `FKP` - FKP
     * * `GQE` - GQE
     * * `XPF` - XPF
     * * `CHW` - CHW
     * * `BRR` - BRR
     * * `SHP` - SHP
     * * `CHE` - CHE
     * * `HRD` - HRD
     * * `BGM` - BGM
     * * `LVR` - LVR
     * * `CLE` - CLE
     * * `ZRN` - ZRN
     * * `DEM` - DEM
     * * `AOK` - AOK
     * * `XDR` - XDR
     * * `TPE` - TPE
     * * `ILR` - ILR
     * * `MRU` - MRU
     * * `KHR` - KHR
     * * `MXV` - MXV
     * * `HRK` - HRK
     * * `SBD` - SBD
     * * `YUR` - YUR
     * * `PES` - PES
     * * `GNS` - GNS
     * * `MKD` - MKD
     * * `PTE` - PTE
     * * `BOP` - BOP
     * * `BGO` - BGO
     * * `CDF` - CDF
     * * `BYN` - BYN
     * * `CLF` - CLF
     * * `LRD` - LRD
     * * `IRR` - IRR
     * * `MRO` - MRO
     * * `MUR` - MUR
     * * `VES` - VES
     * * `VEB` - VEB
     * * `RHD` - RHD
     * * `GBP` - GBP
     * * `TRL` - TRL
     * * `RWF` - RWF
     * * `ZRZ` - ZRZ
     * * `TJR` - TJR
     * * `CAD` - CAD
     * * `HNL` - HNL
     * * `LYD` - LYD
     * * `FIM` - FIM
     * * `ESA` - ESA
     * * `ESB` - ESB
     * * `GWE` - GWE
     * * `AOR` - AOR
     * * `AED` - AED
     * * `IQD` - IQD
     * * `KRO` - KRO
     * * `THB` - THB
     * * `WST` - WST
     * * `LAK` - LAK
     * * `MVR` - MVR
     * * `IDR` - IDR
     * * `MYR` - MYR
     * * `BEF` - BEF
     * * `AMD` - AMD
     * * `XCG` - XCG
     * * `MTP` - MTP
     * * `SCR` - SCR
     * * `KRH` - KRH
     * * `PHP` - PHP
     * * `TMM` - TMM
     * * `UZS` - UZS
     * * `ZMW` - ZMW
     * * `STN` - STN
     * * `XSU` - XSU
     * * `UYI` - UYI
     * * `BRC` - BRC
     * * `CZK` - CZK
     * * `GTQ` - GTQ
     * * `PGK` - PGK
     * * `CVE` - CVE
     * * `KES` - KES
     * * `XRE` - XRE
     * * `XBA` - XBA
     * * `VUV` - VUV
     * * `RON` - RON
     * * `ESP` - ESP
     * * `NLG` - NLG
     * * `CUP` - CUP
     * * `LKR` - LKR
     * * `XPT` - XPT
     * * `LUL` - LUL
     * * `TZS` - TZS
     * * `XPD` - XPD
     * * `DKK` - DKK
     * * `MZM` - MZM
     * * `MVP` - MVP
     * * `XAG` - XAG
     * * `PLZ` - PLZ
     * * `MTL` - MTL
     * * `BRL` - BRL
     * * `PYG` - PYG
     * * `CHF` - CHF
     * * `UAK` - UAK
     * * `COP` - COP
     * * `SOS` - SOS
     * * `MKN` - MKN
     * * `SZL` - SZL
     * * `ANG` - ANG
     * * `GIP` - GIP
     * * `ALK` - ALK
     * * `YUD` - YUD
     * * `OMR` - OMR
     * * `LSL` - LSL
     * * `BAD` - BAD
     * * `SKK` - SKK
     * * `BWP` - BWP
     * * `YDD` - YDD
     * * `ROL` - ROL
     * * `GMD` - GMD
     * * `BEL` - BEL
     * * `DOP` - DOP
     * * `KYD` - KYD
     * * `MAF` - MAF
     * * `MOP` - MOP
     * * `NAD` - NAD
     * * `RUR` - RUR
     * * `BAM` - BAM
     * * `BZD` - BZD
     * * `VND` - VND
     * * `XBD` - XBD
     * * `BIF` - BIF
     * * `SRD` - SRD
     * * `ARP` - ARP
     * * `FJD` - FJD
     * * `KRW` - KRW
     * * `JPY` - JPY
     * * `MGF` - MGF
     * * `MXN` - MXN
     * * `USN` - USN
     * * `BND` - BND
     * * `MZE` - MZE
     * * `GWP` - GWP
     * * `XAF` - XAF
     * * `BSD` - BSD
     * * `BBD` - BBD
     * * `UAH` - UAH
     * * `TRY` - TRY
     * * `BRN` - BRN
     * * `DDM` - DDM
     * * `ZWL` - ZWL
     * * `ILP` - ILP
     * * `RUB` - RUB
     * * `CSK` - CSK
     * * `SGD` - SGD
     * * `XFO` - XFO
     * * `EGP` - EGP
     * * `NIC` - NIC
     * * `NOK` - NOK
     * * `KMF` - KMF
     * * `NPR` - NPR
     * * `UYW` - UYW
     * * `KZT` - KZT
     * * `VED` - VED
     * * `BTN` - BTN
     * * `BAN` - BAN
     * * `ISJ` - ISJ
     * * `PEN` - PEN
     * * `ZMK` - ZMK
     * * `MXP` - MXP
     * * `MCF` - MCF
     * * `ARA` - ARA
     * * `GRD` - GRD
     * * `SRG` - SRG
     */
    currency: AllCurrencies;
    products: ProductDetails[];
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    shipping_price: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    total_price: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    vat_sum_no_commission: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    sum_payout: string;
    supplier: PurchaseOrderCompanyInfo;
    buyer: PurchaseOrderCompanyInfo;
}

export interface PurchaseOrderCompanyInfo {
    name: string;
    contacts: string | null;
    address: string | null;
}

export interface PurchaseOrderShipping {
    /** @format date */
    delivery_date: string;
    delivery_address: string;
    contact_full_name: string | null;
    contact_phone_number: string | null;
    eori_number: string | null;
    delivery_instructions: string | null;
    delivery_contact: string | null;
}

export interface PurchaseRequest {
    id: number;
    /** @format uuid */
    uuid: string;
    /**
     * * `awaiting_quotes` - Awaiting Quotes
     * * `quotes_available` - Quotes Available
     * * `ordered` - Ordered
     * * `expired` - Expired
     * * `proceeded` - Proceeded
     * * `contract_confirmed` - Contract Confirmed
     * * `supplier_decline_to_quote` - Supplier Decline To Quote
     * * `cancelled` - Cancelled
     */
    state?: InquiryState;
    /**
     * * `non_recurring` - Non Recurring
     * * `recurring` - Recurring
     * * `purchase_request` - Purchase Request
     * * `ingredient` - Ingredient
     * * `ingredient_copy` - Ingredient Copy
     * * `offline_inquiry` - Offline Inquiry
     * * `sales_inquiry` - Sales Inquiry
     */
    category?: InquiryCategory;
    /** @maxLength 255 */
    name: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    current_unit_price: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    target_unit_price: string;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    currency?: Currencies;
    description: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    quantity: string;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement?: UnitOfMeasurementEnum;
    allergen_requirements?: (AllergenRequirementsEnum | BlankEnum)[];
    allergen_declaration?: boolean;
    dietary_certificate?: boolean;
    diet_requirements?: (DietRequirementsEnum | BlankEnum)[];
    forecast?: ForecastEnum | BlankEnum | NullEnum | null;
    /** @format date */
    due_date: string;
    delivery_address: InquiryDeliveryAddress;
    /** @maxItems 250 */
    supplier_countries: (Country | BlankEnum)[];
    current_supplier?: string;
    /**
     * Datetime when the inquiry becomes published. Leave empty to keep it unpublished.
     * @format date-time
     */
    published?: string | null;
    /**
     * @format uri
     * @maxLength 2048
     */
    logo_url?: string;
    is_new: boolean;
    /** @format uuid */
    article?: string | null;
    existing_suppliers: string[] | null;
    supply_priorities?: SupplyPriorities[];
    /** @format date */
    first_order_delivery_date?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    opply_brand_commission?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    opply_supplier_commission?: string;
    /** @default false */
    is_recurring?: boolean;
    frequency?: FrequencyEnum | BlankEnum | NullEnum | null;
    /** @min 0 */
    desired_contract_length?: number | null;
    is_delivery_date_flexible?: boolean;
    /** @format uuid */
    parent_uuid?: string | null;
    ingredient?: number | null;
    reason_for_request?: string | null;
    desired_packaging_format?: string;
    desired_packaging_format_is_flexible?: boolean;
    ai_generated?: boolean;
    documents_to_copy?: string[] | null;
    declined: string;
    decline_reason: string;
}

export interface PurchaseRequestCreate {
    /** @format uuid */
    quote_uuid: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    quantity: string;
    /** @format date */
    delivery_date: string;
    delivery_address: number;
    /** @format uuid */
    ingredient_uuid?: string | null;
}

export interface PurchaseRequestResponse {
    /** @format uuid */
    uuid: string;
    /** @format date */
    due_date: string;
    related_contract: number;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    quantity: string;
    /** @format uuid */
    quote_uuid?: string | null;
}

/**
 * * `non_recurring` - Non Recurring
 * * `recurring` - Recurring
 * * `purchase_request_quote` - Purchase Request Quote
 * * `offline_quote` - Offline Quote
 * * `sales_quote` - Sales Quote
 */
export enum QuoteCategory {
    NonRecurring = "non_recurring",
    Recurring = "recurring",
    PurchaseRequestQuote = "purchase_request_quote",
    OfflineQuote = "offline_quote",
    SalesQuote = "sales_quote",
}

export interface QuoteCreate {
    company: Company;
    /** @maxLength 255 */
    product_name: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
     */
    quantity: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,10})?$
     */
    unit_price?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,10})?$
     */
    total_price?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,1}(?:\.\d{0,4})?$
     */
    vat_rate?: string | null;
    /** @format date */
    delivery_date: string;
    /**
     * * `in_advance` - In Advance
     * * `on_dispatch` - On Dispatch
     */
    payment_scenario?: PaymentScenarioEnum;
    /**
     * @min 0
     * @max 365
     */
    payment_delay?: number;
    /**
     * @min 0
     * @default 0
     */
    shelf_life?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    quote_valid_for?: number | null;
    /**
     * The maximum time a supplier agrees to deliver the product within (days)
     * @min 0
     * @max 2147483647
     */
    lead_time: number;
    product_description?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    shipping_cost?: string | null;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    currency?: Currencies;
    /**
     * * `available` - available
     * * `ordered` - ordered
     * * `expired` - expired
     * * `declined` - declined
     * * `pending_payment` - pending_payment
     * * `proceeded` - proceeded
     * * `contract_created` - Contract Created
     * * `contract_proceeded` - contract_proceeded
     * * `contract_confirmed` - contract_confirmed
     */
    status?: QuoteState;
    /**
     * Datetime when the quote becomes published. Leave empty to keep it unpublished.
     * @format date-time
     */
    published?: string | null;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement?: UnitOfMeasurementEnum;
    dispatch_address?: number | null;
    inquiry: number;
    created_by: number;
    is_reverse_charge?: boolean;
    vat_charged?: boolean;
    vat_remitted?: boolean;
    vat_exempt?: boolean;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    commission_payout_vat_rate?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    commission_payin_vat_rate?: string;
    commission_is_reverse_charge?: boolean;
    sku?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,6})?$
     */
    fx_rate?: string;
    /**
     * @format uri
     * @maxLength 2048
     */
    logo_url?: string;
    incoterms?: IncotermsEnum | NullEnum | null;
    /** @default false */
    is_recurring?: boolean;
    recurring_order_lead_time?: number | null;
    contract_length?: number | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,17}(?:\.\d{0,2})?$
     */
    case_size?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,17}(?:\.\d{0,2})?$
     */
    minimum_order_quantity?: string | null;
    /** @default false */
    agreement_required?: boolean;
    account_form_required?: boolean;
    /**
     * * `non_recurring` - Non Recurring
     * * `recurring` - Recurring
     * * `purchase_request_quote` - Purchase Request Quote
     * * `offline_quote` - Offline Quote
     * * `sales_quote` - Sales Quote
     */
    category?: QuoteCategory;
    is_custom_contract?: boolean;
    show_to_other_buyers?: boolean;
    /**
     * @format decimal
     * @pattern ^-?\d{0,17}(?:\.\d{0,2})?$
     */
    minimum_order_quantity_for_other_buyers?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,17}(?:\.\d{0,2})?$
     */
    maximum_order_quantity_for_other_buyers?: string | null;
    autoconfirm_orders?: boolean;
    /**
     * @min 0
     * @max 2147483647
     */
    minimum_lead_time?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    order_in_multiples_of?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    minimum_autoconfirm_order_quantity?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    maximum_autoconfirm_order_quantity?: number | null;
    packaging_format?: string;
    description_blocks?: QuoteDescriptionBlockCreate[] | null;
    documents_to_copy?: string[] | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,1}(?:\.\d{0,4})?$
     */
    shipping_vat_rate?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
     */
    total_forecast_quantity?: string | null;
    /**
     * * `total` - total
     * * `unit` - unit
     */
    selected_price_option?: SelectedPriceOptionEnum;
}

/**
 * * `pricing` - pricing
 * * `delivery_date` - delivery_date
 * * `incorrect_quantities` - incorrect_quantities
 * * `sample_quality` - sample_quality
 * * `specification_not_met` - specification_not_met
 * * `other` - other
 */
export enum QuoteDeclineReason {
    Pricing = "pricing",
    DeliveryDate = "delivery_date",
    IncorrectQuantities = "incorrect_quantities",
    SampleQuality = "sample_quality",
    SpecificationNotMet = "specification_not_met",
    Other = "other",
}

export interface QuoteDeclined {
    decline_reason: QuoteDeclineReason[];
    decline_reason_extended?: string | null;
}

export interface QuoteDescriptionBlock {
    /** @format uuid */
    uuid: string;
    description: string;
}

export interface QuoteDescriptionBlockCreate {
    description: string;
}

export interface QuoteDetail {
    /** @format uuid */
    uuid: string;
    /** @maxLength 255 */
    product_name: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
     */
    quantity: string;
    /** @format date */
    delivery_date: string;
    /**
     * * `in_advance` - In Advance
     * * `on_dispatch` - On Dispatch
     */
    payment_scenario?: PaymentScenarioEnum;
    /**
     * @min 0
     * @max 365
     */
    payment_delay?: number;
    /**
     * @min 0
     * @max 2147483647
     */
    quote_valid_for?: number | null;
    /**
     * The maximum time a supplier agrees to deliver the product within (days)
     * @min 0
     * @max 2147483647
     */
    lead_time: number;
    product_description?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    shipping_cost?: string | null;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    currency?: Currencies;
    /**
     * * `available` - available
     * * `ordered` - ordered
     * * `expired` - expired
     * * `declined` - declined
     * * `pending_payment` - pending_payment
     * * `proceeded` - proceeded
     * * `contract_created` - Contract Created
     * * `contract_proceeded` - contract_proceeded
     * * `contract_confirmed` - contract_confirmed
     */
    status?: QuoteState;
    /**
     * Datetime when the quote becomes published. Leave empty to keep it unpublished.
     * @format date-time
     */
    published?: string | null;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement?: UnitOfMeasurementEnum;
    inquiry_unit_of_measurement: string;
    /** @format date-time */
    created_date: string;
    /** @format date */
    expiry_date?: string | null;
    /** @format uuid */
    inquiry: string;
    /**
     * @format uri
     * @maxLength 2048
     */
    logo_url?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,10})?$
     */
    total_price?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,10})?$
     */
    unit_price?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,1}(?:\.\d{0,4})?$
     */
    vat_rate?: string | null;
    is_reverse_charge?: boolean;
    vat_charged?: boolean;
    vat_remitted?: boolean;
    vat_exempt?: boolean;
    /**
     * @format decimal
     * @pattern ^-?\d{0,1}(?:\.\d{0,4})?$
     */
    fx_fee?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,6})?$
     */
    fx_rate?: string;
    prices: PricesRetrieve;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    commission_payout_vat_rate?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    commission_payin_vat_rate?: string;
    commission_is_reverse_charge?: boolean;
    inquiry_currency: string;
    is_new: boolean;
    /** @maxLength 32 */
    sku?: string | null;
    /**
     * * `non_recurring` - Non Recurring
     * * `recurring` - Recurring
     * * `purchase_request_quote` - Purchase Request Quote
     * * `offline_quote` - Offline Quote
     * * `sales_quote` - Sales Quote
     */
    category?: QuoteCategory;
    order_frequency: OrderFrequencyEnum;
    total_forecast_volume: string;
    is_contract_expired: boolean;
    /** @format uuid */
    inquiry_uuid: string;
    /** @format date */
    contract_end_date?: string | null;
    contract_status: string;
    total_forecast_price: string;
    /** @format double */
    total_forecast_price_for_supplier: number;
    number_of_recurring_orders: string;
    is_custom_contract?: boolean;
    custom_contract_frequency: string;
    /**
     * Duration of the contract in months.
     * @min -2147483648
     * @max 2147483647
     */
    contract_length?: number | null;
    buyer_company_name: string;
    agreement_required?: boolean;
    is_filled: boolean;
    total_forecast_payout: string;
    /** @format uuid */
    original_quote_uuid: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,1}(?:\.\d{0,4})?$
     */
    shipping_vat_rate?: string | null;
    expires_in: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
     */
    total_forecast_quantity?: string | null;
    /**
     * * `total` - total
     * * `unit` - unit
     */
    selected_price_option?: SelectedPriceOptionEnum;
    update_requested?: boolean;
    /** @default "delivery_duty_paid" */
    incoterms?: IncotermsEnum | NullEnum | null;
    kyb_status: string;
    dispatch_address: number | null;
    /**
     * * `available` - Available
     * * `requested` - Requested
     * * `sent` - Sent
     * * `received` - Received
     * * `accepted` - Accepted
     * * `rejected` - Rejected
     * * `declined` - Declined
     */
    samples_status?: SamplesStatusEnum;
    samples_delivery_address: CompanyAddress;
    samples_feedback?: SamplesFeedbackEnum[] | null;
    samples_feedback_comment?: string;
    supplier: SectionSupplier | null;
    is_recurring: string;
    /**
     * @min 0
     * @max 2147483647
     */
    recurring_order_lead_time?: number | null;
    account_form_required?: boolean;
    payout_per_order: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,17}(?:\.\d{0,2})?$
     */
    case_size?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,17}(?:\.\d{0,2})?$
     */
    minimum_order_quantity?: string | null;
    inquiry_frequency: string;
    delivery_address: DeliveryAddressResponse;
    delivery_address_id: number;
    /**
     * * `in_advance` - In Advance
     * * `on_dispatch` - On Dispatch
     * * `7_days_after_dispatch` - Days After Dispatch 7
     * * `14_days_after_dispatch` - Days After Dispatch 14
     * * `30_days_after_dispatch` - Days After Dispatch 30
     * * `60_days_after_dispatch` - Days After Dispatch 60
     */
    requested_payment_terms?: RequestedPaymentTermsEnum;
    /**
     * * `in_advance` - In Advance
     * * `on_dispatch` - On Dispatch
     * * `7_days_after_dispatch` - Days After Dispatch 7
     * * `14_days_after_dispatch` - Days After Dispatch 14
     * * `30_days_after_dispatch` - Days After Dispatch 30
     * * `60_days_after_dispatch` - Days After Dispatch 60
     */
    payment_terms?: PaymentTermsEnum;
    is_opply_supplier: string;
    ingredient: IngredientsForQuote;
    /** @format date */
    samples_dispatch_date?: string | null;
    /** @format date */
    samples_sent_date?: string | null;
    /** @format date */
    samples_received_date?: string | null;
    /** @format date */
    samples_accepted_date?: string | null;
    /** @format date */
    samples_rejected_date?: string | null;
    /** @format date */
    samples_requested_date?: string | null;
    /** @format date */
    samples_declined_date?: string | null;
    samples_tracking_code?: string | null;
    /** @format date */
    samples_expected_delivery_date?: string | null;
    /** @maxLength 255 */
    samples_shipping_provider?: string | null;
    autoconfirm_orders?: boolean;
    /**
     * @min 0
     * @max 2147483647
     */
    minimum_lead_time?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    order_in_multiples_of?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    minimum_autoconfirm_order_quantity?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    maximum_autoconfirm_order_quantity?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    extension_length?: number | null;
    extension_accepted?: boolean | null;
    packaging_format?: string;
    original_quote: string;
    description_blocks?: QuoteDescriptionBlock[] | null;
}

export interface QuoteDetailDispatch {
    /** @format uuid */
    uuid: string;
    /** @maxLength 255 */
    product_name: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
     */
    quantity: string;
    /** @format date */
    delivery_date: string;
    /**
     * * `in_advance` - In Advance
     * * `on_dispatch` - On Dispatch
     */
    payment_scenario?: PaymentScenarioEnum;
    /**
     * @min 0
     * @max 365
     */
    payment_delay?: number;
    /**
     * @min 0
     * @max 2147483647
     */
    quote_valid_for?: number | null;
    /**
     * The maximum time a supplier agrees to deliver the product within (days)
     * @min 0
     * @max 2147483647
     */
    lead_time: number;
    product_description?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    shipping_cost?: string | null;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    currency?: Currencies;
    /**
     * * `available` - available
     * * `ordered` - ordered
     * * `expired` - expired
     * * `declined` - declined
     * * `pending_payment` - pending_payment
     * * `proceeded` - proceeded
     * * `contract_created` - Contract Created
     * * `contract_proceeded` - contract_proceeded
     * * `contract_confirmed` - contract_confirmed
     */
    status?: QuoteState;
    /**
     * Datetime when the quote becomes published. Leave empty to keep it unpublished.
     * @format date-time
     */
    published?: string | null;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement?: UnitOfMeasurementEnum;
    inquiry_unit_of_measurement: string;
    /** @format date-time */
    created_date: string;
    /** @format date */
    expiry_date?: string | null;
    /** @format uuid */
    inquiry: string;
    /**
     * @format uri
     * @maxLength 2048
     */
    logo_url?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,10})?$
     */
    total_price?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,10})?$
     */
    unit_price?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,1}(?:\.\d{0,4})?$
     */
    vat_rate?: string | null;
    is_reverse_charge?: boolean;
    vat_charged?: boolean;
    vat_remitted?: boolean;
    vat_exempt?: boolean;
    /**
     * @format decimal
     * @pattern ^-?\d{0,1}(?:\.\d{0,4})?$
     */
    fx_fee?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,6})?$
     */
    fx_rate?: string;
    prices: PricesRetrieve;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    commission_payout_vat_rate?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    commission_payin_vat_rate?: string;
    commission_is_reverse_charge?: boolean;
    inquiry_currency: string;
    is_new: boolean;
    /** @maxLength 32 */
    sku?: string | null;
    /**
     * * `non_recurring` - Non Recurring
     * * `recurring` - Recurring
     * * `purchase_request_quote` - Purchase Request Quote
     * * `offline_quote` - Offline Quote
     * * `sales_quote` - Sales Quote
     */
    category?: QuoteCategory;
    order_frequency: OrderFrequencyEnum;
    total_forecast_volume: string;
    is_contract_expired: boolean;
    /** @format uuid */
    inquiry_uuid: string;
    /** @format date */
    contract_end_date?: string | null;
    contract_status: string;
    total_forecast_price: string;
    /** @format double */
    total_forecast_price_for_supplier: number;
    number_of_recurring_orders: string;
    is_custom_contract?: boolean;
    custom_contract_frequency: string;
    /**
     * Duration of the contract in months.
     * @min -2147483648
     * @max 2147483647
     */
    contract_length?: number | null;
    buyer_company_name: string;
    agreement_required?: boolean;
    is_filled: boolean;
    total_forecast_payout: string;
    /** @format uuid */
    original_quote_uuid: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,1}(?:\.\d{0,4})?$
     */
    shipping_vat_rate?: string | null;
    expires_in: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
     */
    total_forecast_quantity?: string | null;
    /**
     * * `total` - total
     * * `unit` - unit
     */
    selected_price_option?: SelectedPriceOptionEnum;
    update_requested?: boolean;
    incoterms?: IncotermsEnum | NullEnum | null;
    kyb_status: string;
    dispatch_address?: number | null;
    /**
     * * `available` - Available
     * * `requested` - Requested
     * * `sent` - Sent
     * * `received` - Received
     * * `accepted` - Accepted
     * * `rejected` - Rejected
     * * `declined` - Declined
     */
    samples_status?: SamplesStatusEnum;
    samples_delivery_address: CompanyAddress;
    samples_feedback?: SamplesFeedbackEnum[] | null;
    samples_feedback_comment?: string;
    supplier: SectionSupplier | null;
    is_recurring: string;
    /**
     * @min 0
     * @max 2147483647
     */
    recurring_order_lead_time?: number | null;
    account_form_required?: boolean;
    payout_per_order: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,17}(?:\.\d{0,2})?$
     */
    case_size?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,17}(?:\.\d{0,2})?$
     */
    minimum_order_quantity?: string | null;
    inquiry_frequency: string;
    delivery_address: DeliveryAddressResponse;
    delivery_address_id: number;
    /**
     * * `in_advance` - In Advance
     * * `on_dispatch` - On Dispatch
     * * `7_days_after_dispatch` - Days After Dispatch 7
     * * `14_days_after_dispatch` - Days After Dispatch 14
     * * `30_days_after_dispatch` - Days After Dispatch 30
     * * `60_days_after_dispatch` - Days After Dispatch 60
     */
    requested_payment_terms?: RequestedPaymentTermsEnum;
    /**
     * * `in_advance` - In Advance
     * * `on_dispatch` - On Dispatch
     * * `7_days_after_dispatch` - Days After Dispatch 7
     * * `14_days_after_dispatch` - Days After Dispatch 14
     * * `30_days_after_dispatch` - Days After Dispatch 30
     * * `60_days_after_dispatch` - Days After Dispatch 60
     */
    payment_terms?: PaymentTermsEnum;
    is_opply_supplier: string;
    ingredient: IngredientsForQuote;
    /** @format date */
    samples_dispatch_date?: string | null;
    /** @format date */
    samples_sent_date?: string | null;
    /** @format date */
    samples_received_date?: string | null;
    /** @format date */
    samples_accepted_date?: string | null;
    /** @format date */
    samples_rejected_date?: string | null;
    /** @format date */
    samples_requested_date?: string | null;
    /** @format date */
    samples_declined_date?: string | null;
    samples_tracking_code?: string | null;
    /** @format date */
    samples_expected_delivery_date?: string | null;
    /** @maxLength 255 */
    samples_shipping_provider?: string | null;
    autoconfirm_orders?: boolean;
    /**
     * @min 0
     * @max 2147483647
     */
    minimum_lead_time?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    order_in_multiples_of?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    minimum_autoconfirm_order_quantity?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    maximum_autoconfirm_order_quantity?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    extension_length?: number | null;
    extension_accepted?: boolean | null;
    packaging_format?: string;
    original_quote: string;
    description_blocks?: QuoteDescriptionBlock[] | null;
    company: Company;
    company_address: CompanyAddress;
}

export interface QuoteDetailSupplier {
    /** @format uuid */
    uuid: string;
    /** @maxLength 255 */
    product_name: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
     */
    quantity: string;
    /** @format date */
    delivery_date: string;
    /**
     * * `in_advance` - In Advance
     * * `on_dispatch` - On Dispatch
     */
    payment_scenario?: PaymentScenarioEnum;
    /**
     * @min 0
     * @max 365
     */
    payment_delay?: number;
    /**
     * @min 0
     * @max 2147483647
     */
    quote_valid_for?: number | null;
    /**
     * The maximum time a supplier agrees to deliver the product within (days)
     * @min 0
     * @max 2147483647
     */
    lead_time: number;
    product_description?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    shipping_cost?: string | null;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    currency?: Currencies;
    /**
     * * `available` - available
     * * `ordered` - ordered
     * * `expired` - expired
     * * `declined` - declined
     * * `pending_payment` - pending_payment
     * * `proceeded` - proceeded
     * * `contract_created` - Contract Created
     * * `contract_proceeded` - contract_proceeded
     * * `contract_confirmed` - contract_confirmed
     */
    status?: QuoteState;
    /**
     * Datetime when the quote becomes published. Leave empty to keep it unpublished.
     * @format date-time
     */
    published?: string | null;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement?: UnitOfMeasurementEnum;
    inquiry_unit_of_measurement: string;
    /** @format date-time */
    created_date: string;
    /** @format date */
    expiry_date?: string | null;
    /** @format uuid */
    inquiry: string;
    /**
     * @format uri
     * @maxLength 2048
     */
    logo_url?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,10})?$
     */
    total_price?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,10})?$
     */
    unit_price?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,1}(?:\.\d{0,4})?$
     */
    vat_rate?: string | null;
    is_reverse_charge?: boolean;
    vat_charged?: boolean;
    vat_remitted?: boolean;
    vat_exempt?: boolean;
    /**
     * @format decimal
     * @pattern ^-?\d{0,1}(?:\.\d{0,4})?$
     */
    fx_fee?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,6})?$
     */
    fx_rate?: string;
    prices: PricesRetrieve;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    commission_payout_vat_rate?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    commission_payin_vat_rate?: string;
    commission_is_reverse_charge?: boolean;
    inquiry_currency: string;
    is_new: boolean;
    /** @maxLength 32 */
    sku?: string | null;
    /**
     * * `non_recurring` - Non Recurring
     * * `recurring` - Recurring
     * * `purchase_request_quote` - Purchase Request Quote
     * * `offline_quote` - Offline Quote
     * * `sales_quote` - Sales Quote
     */
    category?: QuoteCategory;
    order_frequency: OrderFrequencyEnum;
    total_forecast_volume: string;
    is_contract_expired: boolean;
    /** @format uuid */
    inquiry_uuid: string;
    /** @format date */
    contract_end_date?: string | null;
    contract_status: string;
    total_forecast_price: string;
    /** @format double */
    total_forecast_price_for_supplier: number;
    number_of_recurring_orders: string;
    is_custom_contract?: boolean;
    custom_contract_frequency: string;
    /**
     * Duration of the contract in months.
     * @min -2147483648
     * @max 2147483647
     */
    contract_length?: number | null;
    buyer_company_name: string;
    agreement_required?: boolean;
    is_filled: boolean;
    total_forecast_payout: string;
    /** @format uuid */
    original_quote_uuid: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,1}(?:\.\d{0,4})?$
     */
    shipping_vat_rate?: string | null;
    expires_in: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
     */
    total_forecast_quantity?: string | null;
    /**
     * * `total` - total
     * * `unit` - unit
     */
    selected_price_option?: SelectedPriceOptionEnum;
    update_requested?: boolean;
    incoterms?: IncotermsEnum | NullEnum | null;
    kyb_status: string;
    dispatch_address?: number | null;
    /**
     * * `available` - Available
     * * `requested` - Requested
     * * `sent` - Sent
     * * `received` - Received
     * * `accepted` - Accepted
     * * `rejected` - Rejected
     * * `declined` - Declined
     */
    samples_status?: SamplesStatusEnum;
    samples_delivery_address: CompanyAddress;
    samples_feedback?: SamplesFeedbackEnum[] | null;
    samples_feedback_comment?: string;
    supplier: SectionSupplier | null;
    is_recurring: string;
    /**
     * @min 0
     * @max 2147483647
     */
    recurring_order_lead_time?: number | null;
    account_form_required?: boolean;
    payout_per_order: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,17}(?:\.\d{0,2})?$
     */
    case_size?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,17}(?:\.\d{0,2})?$
     */
    minimum_order_quantity?: string | null;
    inquiry_frequency: string;
    delivery_address: DeliveryAddressResponse;
    delivery_address_id: number;
    /**
     * * `in_advance` - In Advance
     * * `on_dispatch` - On Dispatch
     * * `7_days_after_dispatch` - Days After Dispatch 7
     * * `14_days_after_dispatch` - Days After Dispatch 14
     * * `30_days_after_dispatch` - Days After Dispatch 30
     * * `60_days_after_dispatch` - Days After Dispatch 60
     */
    requested_payment_terms?: RequestedPaymentTermsEnum;
    /**
     * * `in_advance` - In Advance
     * * `on_dispatch` - On Dispatch
     * * `7_days_after_dispatch` - Days After Dispatch 7
     * * `14_days_after_dispatch` - Days After Dispatch 14
     * * `30_days_after_dispatch` - Days After Dispatch 30
     * * `60_days_after_dispatch` - Days After Dispatch 60
     */
    payment_terms?: PaymentTermsEnum;
    is_opply_supplier: string;
    ingredient: IngredientsForQuote;
    /** @format date */
    samples_dispatch_date?: string | null;
    /** @format date */
    samples_sent_date?: string | null;
    /** @format date */
    samples_received_date?: string | null;
    /** @format date */
    samples_accepted_date?: string | null;
    /** @format date */
    samples_rejected_date?: string | null;
    /** @format date */
    samples_requested_date?: string | null;
    /** @format date */
    samples_declined_date?: string | null;
    samples_tracking_code?: string | null;
    /** @format date */
    samples_expected_delivery_date?: string | null;
    /** @maxLength 255 */
    samples_shipping_provider?: string | null;
    autoconfirm_orders?: boolean;
    /**
     * @min 0
     * @max 2147483647
     */
    minimum_lead_time?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    order_in_multiples_of?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    minimum_autoconfirm_order_quantity?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    maximum_autoconfirm_order_quantity?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    extension_length?: number | null;
    extension_accepted?: boolean | null;
    packaging_format?: string;
    original_quote: string;
    description_blocks?: QuoteDescriptionBlock[] | null;
    company: Company;
    company_address: CompanyAddress;
}

export interface QuoteDispatchAddress {
    id: number;
    /** @maxLength 255 */
    name: string;
    /** @maxLength 255 */
    street_1?: string | null;
    /** @maxLength 255 */
    street_2?: string | null;
    /** @maxLength 255 */
    contact_full_name?: string | null;
    /** @maxLength 50 */
    contact_phone?: string | null;
    phone_country_code?: PhoneCountryCodeEnum | BlankEnum | NullEnum | null;
    /** @maxLength 100 */
    town?: string | null;
    /** @maxLength 100 */
    region?: string | null;
    /** @maxLength 100 */
    postcode?: string | null;
    /** @maxLength 20 */
    eori_number?: string;
    delivery_instructions?: string | null;
    /** @maxLength 100 */
    building?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,4}(?:\.\d{0,15})?$
     */
    latitude?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,4}(?:\.\d{0,15})?$
     */
    longitude?: string | null;
    collection_instructions?: string | null;
    /** @format uuid */
    uuid: string;
    country: Country | NullEnum | null;
    place_id: string | null;
    vat_number: string | null;
    vat_country_code: Country | NullEnum | null;
    vat_exempt: boolean;
    country_type: CountryTypeEnum;
}

export interface QuoteDocument {
    /**
     * Accepts multiple files.
     * @format uri
     */
    documents: string;
    /** @format uuid */
    uuid: string;
    /** @default "other" */
    document_category?: QuoteDocumentDocumentCategoryEnum;
    /** @default false */
    is_filled?: boolean;
}

/**
 * * `other` - Other
 * * `account_form` - Account Form
 * * `agreement` - Agreement
 */
export enum QuoteDocumentDocumentCategoryEnum {
    Other = "other",
    AccountForm = "account_form",
    Agreement = "agreement",
}

export interface QuoteExtension {
    /**
     * @min 0
     * @max 2147483647
     */
    extension_length?: number | null;
}

export interface QuoteFeesAdmin {
    id: number;
    /** @format uuid */
    uuid: string;
    quote: number;
    /** @maxLength 255 */
    title: string;
    description?: string | null;
    /**
     * * `buyer_to_opply` - Buyer To Opply
     * * `buyer_to_supplier` - Buyer To Supplier
     * * `supplier_to_buyer` - Supplier To Buyer
     * * `supplier_to_opply` - Supplier To Opply
     * * `opply_to_supplier` - Opply To Supplier
     */
    category?: QuoteFeesAdminCategoryEnum;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,10})?$
     */
    amount?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,1}(?:\.\d{0,4})?$
     */
    vat_rate?: string;
    currency: Currencies;
}

/**
 * * `buyer_to_opply` - Buyer To Opply
 * * `buyer_to_supplier` - Buyer To Supplier
 * * `supplier_to_buyer` - Supplier To Buyer
 * * `supplier_to_opply` - Supplier To Opply
 * * `opply_to_supplier` - Opply To Supplier
 */
export enum QuoteFeesAdminCategoryEnum {
    BuyerToOpply = "buyer_to_opply",
    BuyerToSupplier = "buyer_to_supplier",
    SupplierToBuyer = "supplier_to_buyer",
    SupplierToOpply = "supplier_to_opply",
    OpplyToSupplier = "opply_to_supplier",
}

export interface QuoteForPurchaseInquiryCreate {
    /**
     * @format decimal
     * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
     */
    quantity: string;
    /** @format date */
    delivery_date: string;
}

export interface QuoteForRetrieveInquiryAdmin {
    /** @format uuid */
    uuid: string;
    company: CompanyNameAndId;
    /** @maxLength 255 */
    product_name: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
     */
    quantity: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
     */
    total_forecast_quantity?: string | null;
    sum_to_pay: string;
    /** @format date-time */
    created_date: string;
    /**
     * * `available` - available
     * * `ordered` - ordered
     * * `expired` - expired
     * * `declined` - declined
     * * `pending_payment` - pending_payment
     * * `proceeded` - proceeded
     * * `contract_created` - Contract Created
     * * `contract_proceeded` - contract_proceeded
     * * `contract_confirmed` - contract_confirmed
     */
    status?: QuoteState;
    /**
     * @format uri
     * @maxLength 2048
     */
    logo_url?: string;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement?: UnitOfMeasurementEnum;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    currency?: Currencies;
}

export interface QuoteList {
    /** @format uuid */
    uuid: string;
    /** @maxLength 255 */
    product_name: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
     */
    quantity: string;
    /** @format date */
    delivery_date: string;
    /**
     * * `in_advance` - In Advance
     * * `on_dispatch` - On Dispatch
     */
    payment_scenario?: PaymentScenarioEnum;
    /**
     * @min 0
     * @max 365
     */
    payment_delay?: number;
    /**
     * @min 0
     * @max 2147483647
     */
    quote_valid_for?: number | null;
    /**
     * The maximum time a supplier agrees to deliver the product within (days)
     * @min 0
     * @max 2147483647
     */
    lead_time: number;
    product_description?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    shipping_cost?: string | null;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    currency?: Currencies;
    /**
     * * `available` - available
     * * `ordered` - ordered
     * * `expired` - expired
     * * `declined` - declined
     * * `pending_payment` - pending_payment
     * * `proceeded` - proceeded
     * * `contract_created` - Contract Created
     * * `contract_proceeded` - contract_proceeded
     * * `contract_confirmed` - contract_confirmed
     */
    status?: QuoteState;
    /**
     * Datetime when the quote becomes published. Leave empty to keep it unpublished.
     * @format date-time
     */
    published?: string | null;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement?: UnitOfMeasurementEnum;
    inquiry_unit_of_measurement: string;
    /** @format date-time */
    created_date: string;
    /** @format date */
    expiry_date?: string | null;
    inquiry: number;
    /**
     * @format uri
     * @maxLength 2048
     */
    logo_url?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,10})?$
     */
    total_price?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,10})?$
     */
    unit_price?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,1}(?:\.\d{0,4})?$
     */
    vat_rate?: string | null;
    is_reverse_charge?: boolean;
    vat_charged?: boolean;
    vat_remitted?: boolean;
    vat_exempt?: boolean;
    /**
     * @format decimal
     * @pattern ^-?\d{0,1}(?:\.\d{0,4})?$
     */
    fx_fee?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,6})?$
     */
    fx_rate?: string;
    prices: PricesRetrieve;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    commission_payout_vat_rate?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    commission_payin_vat_rate?: string;
    commission_is_reverse_charge?: boolean;
    inquiry_currency: string;
    is_new: boolean;
    /** @maxLength 32 */
    sku?: string | null;
    /**
     * * `non_recurring` - Non Recurring
     * * `recurring` - Recurring
     * * `purchase_request_quote` - Purchase Request Quote
     * * `offline_quote` - Offline Quote
     * * `sales_quote` - Sales Quote
     */
    category?: QuoteCategory;
    order_frequency: OrderFrequencyEnum;
    total_forecast_volume: string;
    is_contract_expired: boolean;
    /** @format uuid */
    inquiry_uuid: string;
    /** @format date */
    contract_end_date?: string | null;
    contract_status: string;
    total_forecast_price: string;
    /** @format double */
    total_forecast_price_for_supplier: number;
    number_of_recurring_orders: string;
    is_custom_contract?: boolean;
    custom_contract_frequency: string;
    /**
     * Duration of the contract in months.
     * @min -2147483648
     * @max 2147483647
     */
    contract_length?: number | null;
    buyer_company_name: string;
    agreement_required?: boolean;
    is_filled: boolean;
    total_forecast_payout: string;
    /** @format uuid */
    original_quote_uuid: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,1}(?:\.\d{0,4})?$
     */
    shipping_vat_rate?: string | null;
    expires_in: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
     */
    total_forecast_quantity?: string | null;
    /**
     * * `total` - total
     * * `unit` - unit
     */
    selected_price_option?: SelectedPriceOptionEnum;
    update_requested?: boolean;
}

export interface QuoteListAdmin {
    /** @format uuid */
    uuid: string;
    /** @maxLength 255 */
    product_name: string;
    supplier_name: string;
    buyer_name: string;
    request_id: string;
    /** @format date-time */
    created_date: string;
    /**
     * * `available` - available
     * * `ordered` - ordered
     * * `expired` - expired
     * * `declined` - declined
     * * `pending_payment` - pending_payment
     * * `proceeded` - proceeded
     * * `contract_created` - Contract Created
     * * `contract_proceeded` - contract_proceeded
     * * `contract_confirmed` - contract_confirmed
     */
    status?: QuoteState;
    /**
     * @format uri
     * @maxLength 2048
     */
    logo_url?: string;
    /**
     * Datetime when the quote becomes published. Leave empty to keep it unpublished.
     * @format date-time
     */
    published?: string | null;
    autoconfirm_orders?: boolean;
    /**
     * @min 0
     * @max 2147483647
     */
    minimum_lead_time?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    order_in_multiples_of?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    minimum_autoconfirm_order_quantity?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    maximum_autoconfirm_order_quantity?: number | null;
    /**
     * * `available` - Available
     * * `requested` - Requested
     * * `sent` - Sent
     * * `received` - Received
     * * `accepted` - Accepted
     * * `rejected` - Rejected
     * * `declined` - Declined
     */
    samples_status?: SamplesStatusEnum;
}

export interface QuoteNegotiation {
    /** @format uuid */
    uuid: string;
    /** @format uuid */
    quote_uuid: string;
    quote: number;
    /**
     * @min 0
     * @max 2147483647
     */
    quantity: number;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    unit_price: string;
    /** @format date */
    delivery_date?: string | null;
    /**
     * Duration of the contract in months.
     * @min -2147483648
     * @max 2147483647
     */
    contract_length?: number | null;
    /** @format date */
    first_order_delivery_date?: string | null;
    /**
     * * `created` - Created
     * * `accepted` - Accepted
     * * `declined` - Declined
     * * `completed` - Completed
     */
    status?: QuoteNegotiationStatus;
    /** @format date */
    declined_date?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,10})?$
     */
    unit_price_with_commission_with_vat: string;
    /** @format date */
    accepted_date?: string | null;
}

/**
 * * `created` - Created
 * * `accepted` - Accepted
 * * `declined` - Declined
 * * `completed` - Completed
 */
export enum QuoteNegotiationStatus {
    Created = "created",
    Accepted = "accepted",
    Declined = "declined",
    Completed = "completed",
}

export interface QuoteOrderDetail {
    /** @format uuid */
    uuid: string;
    /** @maxLength 255 */
    product_name: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
     */
    quantity: string;
    /** @format date */
    delivery_date: string;
    /**
     * * `in_advance` - In Advance
     * * `on_dispatch` - On Dispatch
     */
    payment_scenario?: PaymentScenarioEnum;
    /**
     * @min 0
     * @max 365
     */
    payment_delay?: number;
    /**
     * @min 0
     * @max 2147483647
     */
    quote_valid_for?: number | null;
    /**
     * The maximum time a supplier agrees to deliver the product within (days)
     * @min 0
     * @max 2147483647
     */
    lead_time: number;
    product_description?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    shipping_cost?: string | null;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    currency?: Currencies;
    /**
     * * `available` - available
     * * `ordered` - ordered
     * * `expired` - expired
     * * `declined` - declined
     * * `pending_payment` - pending_payment
     * * `proceeded` - proceeded
     * * `contract_created` - Contract Created
     * * `contract_proceeded` - contract_proceeded
     * * `contract_confirmed` - contract_confirmed
     */
    status?: QuoteState;
    /**
     * Datetime when the quote becomes published. Leave empty to keep it unpublished.
     * @format date-time
     */
    published?: string | null;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement?: UnitOfMeasurementEnum;
    inquiry_unit_of_measurement: string;
    /** @format date-time */
    created_date: string;
    /** @format date */
    expiry_date?: string | null;
    /** @format uuid */
    inquiry: string;
    /**
     * @format uri
     * @maxLength 2048
     */
    logo_url?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,10})?$
     */
    total_price?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,10})?$
     */
    unit_price?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,1}(?:\.\d{0,4})?$
     */
    vat_rate?: string | null;
    is_reverse_charge?: boolean;
    vat_charged?: boolean;
    vat_remitted?: boolean;
    vat_exempt?: boolean;
    /**
     * @format decimal
     * @pattern ^-?\d{0,1}(?:\.\d{0,4})?$
     */
    fx_fee?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,6})?$
     */
    fx_rate?: string;
    prices: PricesRetrieve;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    commission_payout_vat_rate?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    commission_payin_vat_rate?: string;
    commission_is_reverse_charge?: boolean;
    inquiry_currency: string;
    is_new: boolean;
    /** @maxLength 32 */
    sku?: string | null;
    /**
     * * `non_recurring` - Non Recurring
     * * `recurring` - Recurring
     * * `purchase_request_quote` - Purchase Request Quote
     * * `offline_quote` - Offline Quote
     * * `sales_quote` - Sales Quote
     */
    category?: QuoteCategory;
    order_frequency: OrderFrequencyEnum;
    total_forecast_volume: string;
    is_contract_expired: boolean;
    /** @format uuid */
    inquiry_uuid: string;
    /** @format date */
    contract_end_date?: string | null;
    contract_status: string;
    total_forecast_price: string;
    /** @format double */
    total_forecast_price_for_supplier: number;
    number_of_recurring_orders: string;
    is_custom_contract?: boolean;
    custom_contract_frequency: string;
    /**
     * Duration of the contract in months.
     * @min -2147483648
     * @max 2147483647
     */
    contract_length?: number | null;
    buyer_company_name: string;
    agreement_required?: boolean;
    is_filled: boolean;
    total_forecast_payout: string;
    /** @format uuid */
    original_quote_uuid: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,1}(?:\.\d{0,4})?$
     */
    shipping_vat_rate?: string | null;
    expires_in: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
     */
    total_forecast_quantity?: string | null;
    /**
     * * `total` - total
     * * `unit` - unit
     */
    selected_price_option?: SelectedPriceOptionEnum;
    update_requested?: boolean;
    incoterms?: IncotermsEnum | NullEnum | null;
    kyb_status: string;
    dispatch_address: CompanyAddress;
    /**
     * * `available` - Available
     * * `requested` - Requested
     * * `sent` - Sent
     * * `received` - Received
     * * `accepted` - Accepted
     * * `rejected` - Rejected
     * * `declined` - Declined
     */
    samples_status?: SamplesStatusEnum;
    samples_delivery_address: CompanyAddress;
    samples_feedback?: SamplesFeedbackEnum[] | null;
    samples_feedback_comment?: string;
    supplier: SectionSupplier | null;
    is_recurring: string;
    /**
     * @min 0
     * @max 2147483647
     */
    recurring_order_lead_time?: number | null;
    account_form_required?: boolean;
    payout_per_order: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,17}(?:\.\d{0,2})?$
     */
    case_size?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,17}(?:\.\d{0,2})?$
     */
    minimum_order_quantity?: string | null;
    inquiry_frequency: string;
    delivery_address: DeliveryAddressResponse;
    delivery_address_id: number;
    /**
     * * `in_advance` - In Advance
     * * `on_dispatch` - On Dispatch
     * * `7_days_after_dispatch` - Days After Dispatch 7
     * * `14_days_after_dispatch` - Days After Dispatch 14
     * * `30_days_after_dispatch` - Days After Dispatch 30
     * * `60_days_after_dispatch` - Days After Dispatch 60
     */
    requested_payment_terms?: RequestedPaymentTermsEnum;
    /**
     * * `in_advance` - In Advance
     * * `on_dispatch` - On Dispatch
     * * `7_days_after_dispatch` - Days After Dispatch 7
     * * `14_days_after_dispatch` - Days After Dispatch 14
     * * `30_days_after_dispatch` - Days After Dispatch 30
     * * `60_days_after_dispatch` - Days After Dispatch 60
     */
    payment_terms?: PaymentTermsEnum;
    is_opply_supplier: string;
    ingredient: IngredientsForQuote;
    /** @format date */
    samples_dispatch_date?: string | null;
    /** @format date */
    samples_sent_date?: string | null;
    /** @format date */
    samples_received_date?: string | null;
    /** @format date */
    samples_accepted_date?: string | null;
    /** @format date */
    samples_rejected_date?: string | null;
    /** @format date */
    samples_requested_date?: string | null;
    /** @format date */
    samples_declined_date?: string | null;
    samples_tracking_code?: string | null;
    /** @format date */
    samples_expected_delivery_date?: string | null;
    /** @maxLength 255 */
    samples_shipping_provider?: string | null;
    autoconfirm_orders?: boolean;
    /**
     * @min 0
     * @max 2147483647
     */
    minimum_lead_time?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    order_in_multiples_of?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    minimum_autoconfirm_order_quantity?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    maximum_autoconfirm_order_quantity?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    extension_length?: number | null;
    extension_accepted?: boolean | null;
    packaging_format?: string;
    original_quote: string;
    description_blocks?: QuoteDescriptionBlock[] | null;
    /** @format uuid */
    product_uuid: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    discount?: string | null;
}

export interface QuoteRetrieve {
    /** @format uuid */
    uuid: string;
    /** @maxLength 255 */
    product_name: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
     */
    quantity: string;
    /** @format date */
    delivery_date: string;
    /**
     * * `in_advance` - In Advance
     * * `on_dispatch` - On Dispatch
     */
    payment_scenario?: PaymentScenarioEnum;
    /**
     * @min 0
     * @max 365
     */
    payment_delay?: number;
    /**
     * @min 0
     * @max 2147483647
     */
    quote_valid_for?: number | null;
    /**
     * The maximum time a supplier agrees to deliver the product within (days)
     * @min 0
     * @max 2147483647
     */
    lead_time: number;
    product_description?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    shipping_cost?: string | null;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    currency?: Currencies;
    /**
     * * `available` - available
     * * `ordered` - ordered
     * * `expired` - expired
     * * `declined` - declined
     * * `pending_payment` - pending_payment
     * * `proceeded` - proceeded
     * * `contract_created` - Contract Created
     * * `contract_proceeded` - contract_proceeded
     * * `contract_confirmed` - contract_confirmed
     */
    status?: QuoteState;
    /**
     * Datetime when the quote becomes published. Leave empty to keep it unpublished.
     * @format date-time
     */
    published?: string | null;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement?: UnitOfMeasurementEnum;
    inquiry_unit_of_measurement: string;
    /** @format date-time */
    created_date: string;
    /** @format date */
    expiry_date?: string | null;
    /** @format uuid */
    inquiry: string;
    /**
     * @format uri
     * @maxLength 2048
     */
    logo_url?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,10})?$
     */
    total_price?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,10})?$
     */
    unit_price?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,1}(?:\.\d{0,4})?$
     */
    vat_rate?: string | null;
    is_reverse_charge?: boolean;
    vat_charged?: boolean;
    vat_remitted?: boolean;
    vat_exempt?: boolean;
    /**
     * @format decimal
     * @pattern ^-?\d{0,1}(?:\.\d{0,4})?$
     */
    fx_fee?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,6})?$
     */
    fx_rate?: string;
    prices: PricesRetrieve;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    commission_payout_vat_rate?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    commission_payin_vat_rate?: string;
    commission_is_reverse_charge?: boolean;
    inquiry_currency: string;
    is_new: boolean;
    /** @maxLength 32 */
    sku?: string | null;
    /**
     * * `non_recurring` - Non Recurring
     * * `recurring` - Recurring
     * * `purchase_request_quote` - Purchase Request Quote
     * * `offline_quote` - Offline Quote
     * * `sales_quote` - Sales Quote
     */
    category?: QuoteCategory;
    order_frequency: OrderFrequencyEnum;
    total_forecast_volume: string;
    is_contract_expired: boolean;
    /** @format uuid */
    inquiry_uuid: string;
    /** @format date */
    contract_end_date?: string | null;
    contract_status: string;
    total_forecast_price: string;
    /** @format double */
    total_forecast_price_for_supplier: number;
    number_of_recurring_orders: string;
    is_custom_contract?: boolean;
    custom_contract_frequency: string;
    /**
     * Duration of the contract in months.
     * @min -2147483648
     * @max 2147483647
     */
    contract_length?: number | null;
    buyer_company_name: string;
    agreement_required?: boolean;
    is_filled: boolean;
    total_forecast_payout: string;
    /** @format uuid */
    original_quote_uuid: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,1}(?:\.\d{0,4})?$
     */
    shipping_vat_rate?: string | null;
    expires_in: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
     */
    total_forecast_quantity?: string | null;
    /**
     * * `total` - total
     * * `unit` - unit
     */
    selected_price_option?: SelectedPriceOptionEnum;
    update_requested?: boolean;
    incoterms?: IncotermsEnum | NullEnum | null;
    kyb_status: string;
    dispatch_address?: number | null;
    /**
     * * `available` - Available
     * * `requested` - Requested
     * * `sent` - Sent
     * * `received` - Received
     * * `accepted` - Accepted
     * * `rejected` - Rejected
     * * `declined` - Declined
     */
    samples_status?: SamplesStatusEnum;
    samples_delivery_address: CompanyAddress;
    samples_feedback?: SamplesFeedbackEnum[] | null;
    samples_feedback_comment?: string;
    supplier: SectionSupplier | null;
    is_recurring: string;
    /**
     * @min 0
     * @max 2147483647
     */
    recurring_order_lead_time?: number | null;
    account_form_required?: boolean;
    payout_per_order: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,17}(?:\.\d{0,2})?$
     */
    case_size?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,17}(?:\.\d{0,2})?$
     */
    minimum_order_quantity?: string | null;
    inquiry_frequency: string;
    delivery_address: DeliveryAddressResponse;
    delivery_address_id: number;
    /**
     * * `in_advance` - In Advance
     * * `on_dispatch` - On Dispatch
     * * `7_days_after_dispatch` - Days After Dispatch 7
     * * `14_days_after_dispatch` - Days After Dispatch 14
     * * `30_days_after_dispatch` - Days After Dispatch 30
     * * `60_days_after_dispatch` - Days After Dispatch 60
     */
    requested_payment_terms?: RequestedPaymentTermsEnum;
    /**
     * * `in_advance` - In Advance
     * * `on_dispatch` - On Dispatch
     * * `7_days_after_dispatch` - Days After Dispatch 7
     * * `14_days_after_dispatch` - Days After Dispatch 14
     * * `30_days_after_dispatch` - Days After Dispatch 30
     * * `60_days_after_dispatch` - Days After Dispatch 60
     */
    payment_terms?: PaymentTermsEnum;
    is_opply_supplier: string;
    ingredient: IngredientsForQuote;
    /** @format date */
    samples_dispatch_date?: string | null;
    /** @format date */
    samples_sent_date?: string | null;
    /** @format date */
    samples_received_date?: string | null;
    /** @format date */
    samples_accepted_date?: string | null;
    /** @format date */
    samples_rejected_date?: string | null;
    /** @format date */
    samples_requested_date?: string | null;
    /** @format date */
    samples_declined_date?: string | null;
    samples_tracking_code?: string | null;
    /** @format date */
    samples_expected_delivery_date?: string | null;
    /** @maxLength 255 */
    samples_shipping_provider?: string | null;
    autoconfirm_orders?: boolean;
    /**
     * @min 0
     * @max 2147483647
     */
    minimum_lead_time?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    order_in_multiples_of?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    minimum_autoconfirm_order_quantity?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    maximum_autoconfirm_order_quantity?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    extension_length?: number | null;
    extension_accepted?: boolean | null;
    packaging_format?: string;
    original_quote: string;
    description_blocks?: QuoteDescriptionBlock[] | null;
    company: Company;
    company_address: CompanyAddress;
    order_uuid: string | null;
    inquiry_supplier_status: string;
    decline_reason: QuoteDeclineReason[];
    decline_reason_extended?: string | null;
    supplier_kyb_verification_status: string;
    beneficiary_verification_status: string;
    supplier_kriya_status: string;
    buyer_name: string;
    buyer_contact_name: string;
    buyer_company_address: string;
    supplier_contact_name: string;
    supplier_full_contact_name: string;
    show_to_other_buyers?: boolean;
}

export interface QuoteRetrieveAdmin {
    /** @format uuid */
    uuid: string;
    /** @maxLength 255 */
    product_name: string;
    supplier_name: string;
    buyer_name: string;
    request_id: string;
    /** @format date-time */
    created_date: string;
    /**
     * * `available` - available
     * * `ordered` - ordered
     * * `expired` - expired
     * * `declined` - declined
     * * `pending_payment` - pending_payment
     * * `proceeded` - proceeded
     * * `contract_created` - Contract Created
     * * `contract_proceeded` - contract_proceeded
     * * `contract_confirmed` - contract_confirmed
     */
    status?: QuoteState;
    /**
     * @format uri
     * @maxLength 2048
     */
    logo_url?: string;
    /**
     * Datetime when the quote becomes published. Leave empty to keep it unpublished.
     * @format date-time
     */
    published?: string | null;
    autoconfirm_orders?: boolean;
    /**
     * @min 0
     * @max 2147483647
     */
    minimum_lead_time?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    order_in_multiples_of?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    minimum_autoconfirm_order_quantity?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    maximum_autoconfirm_order_quantity?: number | null;
    /**
     * * `available` - Available
     * * `requested` - Requested
     * * `sent` - Sent
     * * `received` - Received
     * * `accepted` - Accepted
     * * `rejected` - Rejected
     * * `declined` - Declined
     */
    samples_status?: SamplesStatusEnum;
    company: CompanyNameAndId;
    created_by: User;
    /**
     * @format decimal
     * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
     */
    quantity: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,10})?$
     */
    unit_price?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,10})?$
     */
    total_price?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,1}(?:\.\d{0,4})?$
     */
    vat_rate?: string | null;
    /** @format date */
    delivery_date: string;
    /**
     * * `in_advance` - In Advance
     * * `on_dispatch` - On Dispatch
     */
    payment_scenario?: PaymentScenarioEnum;
    /**
     * @min 0
     * @max 365
     */
    payment_delay?: number;
    /**
     * Minimum Shelf Life on Delivery (days)
     * @min 0
     * @max 2147483647
     */
    shelf_life?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    quote_valid_for?: number | null;
    /**
     * The maximum time a supplier agrees to deliver the product within (days)
     * @min 0
     * @max 2147483647
     */
    lead_time: number;
    dispatch_address: CompanyAddress;
    incoterms?: IncotermsEnum | NullEnum | null;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    currency?: Currencies;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    buyer_currency: Currencies;
    /**
     * * `non_recurring` - Non Recurring
     * * `recurring` - Recurring
     * * `purchase_request_quote` - Purchase Request Quote
     * * `offline_quote` - Offline Quote
     * * `sales_quote` - Sales Quote
     */
    category?: QuoteCategory;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,6})?$
     */
    fx_rate?: string;
    product_description?: string | null;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement?: UnitOfMeasurementEnum;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    shipping_cost?: string | null;
    inquiry: InquiryAdmin;
    prices: PricesRetrieve;
    decline_reason: QuoteDeclineReason[];
    decline_reason_extended?: string | null;
    documents: QuoteDocument[];
    /** @maxLength 32 */
    sku?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,17}(?:\.\d{0,2})?$
     */
    minimum_order_quantity?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,17}(?:\.\d{0,2})?$
     */
    case_size?: string | null;
    order_frequency: string;
    total_forecast_volume: string;
    total_forecast_payout: string;
    total_forecast_price: string;
    total_forecast_price_no_fx: number;
    payout_per_order: string;
    /**
     * @min 0
     * @max 2147483647
     */
    recurring_order_lead_time?: number | null;
    /** @format date */
    expiry_date?: string | null;
    /**
     * Duration of the contract in months.
     * @min -2147483648
     * @max 2147483647
     */
    contract_length?: number | null;
    agreement_required?: boolean;
    is_opply_supplier: string;
    account_form_required?: boolean;
    vat_charged?: boolean;
    vat_remitted?: boolean;
    is_reverse_charge?: boolean;
    supplier_commission_per_contract: number;
    supplier_commission_per_order: number;
    buyer_price_per_order: number;
    buyer_price_per_order_no_fx: number;
    buyer_commission_per_order: number;
    buyer_commission_per_order_no_fx: number;
    buyer_commission_per_contract: number;
    is_recurring: string;
    buyer_unit_price_no_vat: number;
    buyer_unit_price_with_vat: number;
    is_custom_contract?: boolean;
    custom_contract_frequency: string;
    number_of_recurring_orders: string;
    /** @format uuid */
    recurring_inquiry_uuid: string;
    /** @format date */
    proceeded_date: string | null;
    /** @format date */
    contract_created_date: string | null;
    /** @format date */
    contract_proceeded_date: string | null;
    /** @format date */
    contract_confirmed_date: string | null;
    show_to_other_buyers?: boolean;
    /**
     * @format decimal
     * @pattern ^-?\d{0,17}(?:\.\d{0,2})?$
     */
    minimum_order_quantity_for_other_buyers?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,17}(?:\.\d{0,2})?$
     */
    maximum_order_quantity_for_other_buyers?: string | null;
    /** @format date */
    samples_requested_date?: string | null;
    /** @format date */
    samples_dispatch_date?: string | null;
    /** @format date */
    samples_received_date?: string | null;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    supplier_currency: Currencies;
    /**
     * @format decimal
     * @pattern ^-?\d{0,1}(?:\.\d{0,4})?$
     */
    shipping_vat_rate?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
     */
    total_forecast_quantity?: string | null;
}

/**
 * * `available` - available
 * * `ordered` - ordered
 * * `expired` - expired
 * * `declined` - declined
 * * `pending_payment` - pending_payment
 * * `proceeded` - proceeded
 * * `contract_created` - Contract Created
 * * `contract_proceeded` - contract_proceeded
 * * `contract_confirmed` - contract_confirmed
 */
export enum QuoteState {
    Available = "available",
    Ordered = "ordered",
    Expired = "expired",
    Declined = "declined",
    PendingPayment = "pending_payment",
    Proceeded = "proceeded",
    ContractCreated = "contract_created",
    ContractProceeded = "contract_proceeded",
    ContractConfirmed = "contract_confirmed",
}

export interface RapydCheckout {
    /** @format uuid */
    order_id: string;
    /**
     * * `EUR` - EUR
     * * `GBP` - GBP
     * * `USD` - USD
     * * `CAD` - CAD
     */
    selected_currency?: SupportedCurrencies;
}

export interface RapydCheckoutData {
    checkout_id: string;
    /** @format uri */
    redirect_url: string;
    /** @format uri */
    complete_checkout_url: string;
    prices: OrderPrices;
    virtual_bank_account: BankTransfer;
}

export interface RapydPostCheckout {
    /** @format uuid */
    order_id: string;
    /**
     * * `EUR` - EUR
     * * `GBP` - GBP
     * * `USD` - USD
     * * `CAD` - CAD
     */
    selected_currency?: SupportedCurrencies;
    /**
     * * `card` - Card
     * * `bank_transfer` - Bank Transfer
     * * `PAY30` - Kriya 30
     * * `PAY30EOFM` - Kriya Next Month
     */
    payment_method: PaymentMethod;
    /**
     * * `in_advance` - In Advance
     * * `on_dispatch` - On Dispatch
     */
    payment_scenario: PaymentScenarioEnum;
}

export interface RapydSavePayment {
    fingerprint_token?: string;
    customer_token?: string;
    payment_id: string;
    order_id: string;
}

export interface RapydSupplierTermsCard {
    /** @format uri */
    redirect_url: string;
}

export interface RemoveMember {
    /** @format email */
    email: string;
}

/**
 * * `in_advance` - In Advance
 * * `on_dispatch` - On Dispatch
 * * `7_days_after_dispatch` - Days After Dispatch 7
 * * `14_days_after_dispatch` - Days After Dispatch 14
 * * `30_days_after_dispatch` - Days After Dispatch 30
 * * `60_days_after_dispatch` - Days After Dispatch 60
 */
export enum RequestedPaymentTermsEnum {
    InAdvance = "in_advance",
    OnDispatch = "on_dispatch",
    Value7DaysAfterDispatch = "7_days_after_dispatch",
    Value14DaysAfterDispatch = "14_days_after_dispatch",
    Value30DaysAfterDispatch = "30_days_after_dispatch",
    Value60DaysAfterDispatch = "60_days_after_dispatch",
}

/**
 * * `< £1M` - < £1M
 * * `£1M - £5M` - £1M - £5M
 * * `£5M - £10M` - £5M - £10M
 * * `£10M +` - £10M +
 */
export enum RevenueEnum {
    Value1M = "< £1M",
    Value1M5M = "£1M - £5M",
    Value5M10M = "£5M - £10M",
    Value10M = "£10M +",
}

export interface SalesOrderCreate {
    /** @format uuid */
    order_uuid: string;
    /** @format uuid */
    customer_uuid: string;
    order_number: string;
    /** @format date */
    order_date: string;
    /** @format date */
    delivery_date: string;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    currency: Currencies;
    /**
     * @format decimal
     * @default "0.00"
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    shipping_cost?: string;
    terms?: string | null;
    /** @format date */
    dispatch_date?: string | null;
    products: SalesOrderProduct[];
}

export interface SalesOrderProduct {
    /** @min 0 */
    quantity: number;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    unit_price: string;
    /**
     * @format decimal
     * @default "0.00"
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    vat_rate?: string;
    /**
     * @format decimal
     * @default "0.00"
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    discount?: string;
    /** @format uuid */
    product_uuid: string;
}

/**
 * * `ingredient_quality` - Ingredient Quality
 * * `quote_price` - Quote Price
 * * `supplier_communication` - Supplier Communication
 * * `quality_not_as_expected` - Quality Not As Expected
 * * `too_expensive` - Too Expensive
 * * `not_suitable_for_recipe` - Not Suitable For Recipe
 * * `not_needed_anymore` - Not Needed Anymore
 * * `dont_provided_samples` - Dont Provide Samples
 * * `ingredient_out_of_stock` - Ingredient Out Of Stock
 * * `other` - Other
 */
export enum SamplesFeedbackEnum {
    IngredientQuality = "ingredient_quality",
    QuotePrice = "quote_price",
    SupplierCommunication = "supplier_communication",
    QualityNotAsExpected = "quality_not_as_expected",
    TooExpensive = "too_expensive",
    NotSuitableForRecipe = "not_suitable_for_recipe",
    NotNeededAnymore = "not_needed_anymore",
    DontProvidedSamples = "dont_provided_samples",
    IngredientOutOfStock = "ingredient_out_of_stock",
    Other = "other",
}

/**
 * * `available` - Available
 * * `requested` - Requested
 * * `sent` - Sent
 * * `received` - Received
 * * `accepted` - Accepted
 * * `rejected` - Rejected
 * * `declined` - Declined
 */
export enum SamplesStatusEnum {
    Available = "available",
    Requested = "requested",
    Sent = "sent",
    Received = "received",
    Accepted = "accepted",
    Rejected = "rejected",
    Declined = "declined",
}

export interface SearchMessagesRequest {
    text: string;
}

export interface SearchMessagesResponse {
    /** @format uuid */
    uuid: string;
    matched_text: string;
    text?: string | null;
    /** @format uri */
    attachment?: string | null;
    sender: number;
    sender_name: string;
    /** @format date-time */
    created: string;
    /** @format date-time */
    modified: string;
    channel: ChannelForMessage;
    page_number: string;
}

export interface SectionSupplier {
    is_existing: boolean;
    company_name: string;
    city?: string | null;
    /**
     * * `AF` - AF
     * * `AX` - AX
     * * `AL` - AL
     * * `DZ` - DZ
     * * `AS` - AS
     * * `AD` - AD
     * * `AO` - AO
     * * `AI` - AI
     * * `AQ` - AQ
     * * `AG` - AG
     * * `AR` - AR
     * * `AM` - AM
     * * `AW` - AW
     * * `AU` - AU
     * * `AT` - AT
     * * `AZ` - AZ
     * * `BS` - BS
     * * `BH` - BH
     * * `BD` - BD
     * * `BB` - BB
     * * `BY` - BY
     * * `BE` - BE
     * * `BZ` - BZ
     * * `BJ` - BJ
     * * `BM` - BM
     * * `BT` - BT
     * * `BO` - BO
     * * `BQ` - BQ
     * * `BA` - BA
     * * `BW` - BW
     * * `BV` - BV
     * * `BR` - BR
     * * `IO` - IO
     * * `BN` - BN
     * * `BG` - BG
     * * `BF` - BF
     * * `BI` - BI
     * * `CV` - CV
     * * `KH` - KH
     * * `CM` - CM
     * * `CA` - CA
     * * `KY` - KY
     * * `CF` - CF
     * * `TD` - TD
     * * `CL` - CL
     * * `CN` - CN
     * * `CX` - CX
     * * `CC` - CC
     * * `CO` - CO
     * * `KM` - KM
     * * `CG` - CG
     * * `CD` - CD
     * * `CK` - CK
     * * `CR` - CR
     * * `CI` - CI
     * * `HR` - HR
     * * `CU` - CU
     * * `CW` - CW
     * * `CY` - CY
     * * `CZ` - CZ
     * * `DK` - DK
     * * `DJ` - DJ
     * * `DM` - DM
     * * `DO` - DO
     * * `EC` - EC
     * * `EG` - EG
     * * `SV` - SV
     * * `GQ` - GQ
     * * `ER` - ER
     * * `EE` - EE
     * * `SZ` - SZ
     * * `ET` - ET
     * * `FK` - FK
     * * `FO` - FO
     * * `FJ` - FJ
     * * `FI` - FI
     * * `FR` - FR
     * * `GF` - GF
     * * `PF` - PF
     * * `TF` - TF
     * * `GA` - GA
     * * `GM` - GM
     * * `GE` - GE
     * * `DE` - DE
     * * `GH` - GH
     * * `GI` - GI
     * * `GR` - GR
     * * `GL` - GL
     * * `GD` - GD
     * * `GP` - GP
     * * `GU` - GU
     * * `GT` - GT
     * * `GG` - GG
     * * `GN` - GN
     * * `GW` - GW
     * * `GY` - GY
     * * `HT` - HT
     * * `HM` - HM
     * * `VA` - VA
     * * `HN` - HN
     * * `HK` - HK
     * * `HU` - HU
     * * `IS` - IS
     * * `IN` - IN
     * * `ID` - ID
     * * `IR` - IR
     * * `IQ` - IQ
     * * `IE` - IE
     * * `IM` - IM
     * * `IL` - IL
     * * `IT` - IT
     * * `JM` - JM
     * * `JP` - JP
     * * `JE` - JE
     * * `JO` - JO
     * * `KZ` - KZ
     * * `KE` - KE
     * * `KI` - KI
     * * `KW` - KW
     * * `KG` - KG
     * * `LA` - LA
     * * `LV` - LV
     * * `LB` - LB
     * * `LS` - LS
     * * `LR` - LR
     * * `LY` - LY
     * * `LI` - LI
     * * `LT` - LT
     * * `LU` - LU
     * * `MO` - MO
     * * `MG` - MG
     * * `MW` - MW
     * * `MY` - MY
     * * `MV` - MV
     * * `ML` - ML
     * * `MT` - MT
     * * `MH` - MH
     * * `MQ` - MQ
     * * `MR` - MR
     * * `MU` - MU
     * * `YT` - YT
     * * `MX` - MX
     * * `FM` - FM
     * * `MD` - MD
     * * `MC` - MC
     * * `MN` - MN
     * * `ME` - ME
     * * `MS` - MS
     * * `MA` - MA
     * * `MZ` - MZ
     * * `MM` - MM
     * * `NA` - NA
     * * `NR` - NR
     * * `NP` - NP
     * * `NL` - NL
     * * `NC` - NC
     * * `NZ` - NZ
     * * `NI` - NI
     * * `NE` - NE
     * * `NG` - NG
     * * `NU` - NU
     * * `NF` - NF
     * * `KP` - KP
     * * `MK` - MK
     * * `XI` - XI
     * * `MP` - MP
     * * `NO` - NO
     * * `OM` - OM
     * * `PK` - PK
     * * `PW` - PW
     * * `PS` - PS
     * * `PA` - PA
     * * `PG` - PG
     * * `PY` - PY
     * * `PE` - PE
     * * `PH` - PH
     * * `PN` - PN
     * * `PL` - PL
     * * `PT` - PT
     * * `PR` - PR
     * * `QA` - QA
     * * `RE` - RE
     * * `RO` - RO
     * * `RU` - RU
     * * `RW` - RW
     * * `BL` - BL
     * * `SH` - SH
     * * `KN` - KN
     * * `LC` - LC
     * * `MF` - MF
     * * `PM` - PM
     * * `VC` - VC
     * * `WS` - WS
     * * `SM` - SM
     * * `ST` - ST
     * * `SA` - SA
     * * `SN` - SN
     * * `RS` - RS
     * * `SC` - SC
     * * `SL` - SL
     * * `SG` - SG
     * * `SX` - SX
     * * `SK` - SK
     * * `SI` - SI
     * * `SB` - SB
     * * `SO` - SO
     * * `ZA` - ZA
     * * `GS` - GS
     * * `KR` - KR
     * * `SS` - SS
     * * `ES` - ES
     * * `LK` - LK
     * * `SD` - SD
     * * `SR` - SR
     * * `SJ` - SJ
     * * `SE` - SE
     * * `CH` - CH
     * * `SY` - SY
     * * `TW` - TW
     * * `TJ` - TJ
     * * `TZ` - TZ
     * * `TH` - TH
     * * `TL` - TL
     * * `TG` - TG
     * * `TK` - TK
     * * `TO` - TO
     * * `TT` - TT
     * * `TN` - TN
     * * `TR` - TR
     * * `TM` - TM
     * * `TC` - TC
     * * `TV` - TV
     * * `UG` - UG
     * * `UA` - UA
     * * `AE` - AE
     * * `GB` - GB
     * * `UM` - UM
     * * `US` - US
     * * `UY` - UY
     * * `UZ` - UZ
     * * `VU` - VU
     * * `VE` - VE
     * * `VN` - VN
     * * `VG` - VG
     * * `VI` - VI
     * * `WF` - WF
     * * `EH` - EH
     * * `YE` - YE
     * * `ZM` - ZM
     * * `ZW` - ZW
     */
    country: SectionSupplierCountryEnum;
    /** @format uuid */
    uuid: string;
}

/**
 * * `AF` - AF
 * * `AX` - AX
 * * `AL` - AL
 * * `DZ` - DZ
 * * `AS` - AS
 * * `AD` - AD
 * * `AO` - AO
 * * `AI` - AI
 * * `AQ` - AQ
 * * `AG` - AG
 * * `AR` - AR
 * * `AM` - AM
 * * `AW` - AW
 * * `AU` - AU
 * * `AT` - AT
 * * `AZ` - AZ
 * * `BS` - BS
 * * `BH` - BH
 * * `BD` - BD
 * * `BB` - BB
 * * `BY` - BY
 * * `BE` - BE
 * * `BZ` - BZ
 * * `BJ` - BJ
 * * `BM` - BM
 * * `BT` - BT
 * * `BO` - BO
 * * `BQ` - BQ
 * * `BA` - BA
 * * `BW` - BW
 * * `BV` - BV
 * * `BR` - BR
 * * `IO` - IO
 * * `BN` - BN
 * * `BG` - BG
 * * `BF` - BF
 * * `BI` - BI
 * * `CV` - CV
 * * `KH` - KH
 * * `CM` - CM
 * * `CA` - CA
 * * `KY` - KY
 * * `CF` - CF
 * * `TD` - TD
 * * `CL` - CL
 * * `CN` - CN
 * * `CX` - CX
 * * `CC` - CC
 * * `CO` - CO
 * * `KM` - KM
 * * `CG` - CG
 * * `CD` - CD
 * * `CK` - CK
 * * `CR` - CR
 * * `CI` - CI
 * * `HR` - HR
 * * `CU` - CU
 * * `CW` - CW
 * * `CY` - CY
 * * `CZ` - CZ
 * * `DK` - DK
 * * `DJ` - DJ
 * * `DM` - DM
 * * `DO` - DO
 * * `EC` - EC
 * * `EG` - EG
 * * `SV` - SV
 * * `GQ` - GQ
 * * `ER` - ER
 * * `EE` - EE
 * * `SZ` - SZ
 * * `ET` - ET
 * * `FK` - FK
 * * `FO` - FO
 * * `FJ` - FJ
 * * `FI` - FI
 * * `FR` - FR
 * * `GF` - GF
 * * `PF` - PF
 * * `TF` - TF
 * * `GA` - GA
 * * `GM` - GM
 * * `GE` - GE
 * * `DE` - DE
 * * `GH` - GH
 * * `GI` - GI
 * * `GR` - GR
 * * `GL` - GL
 * * `GD` - GD
 * * `GP` - GP
 * * `GU` - GU
 * * `GT` - GT
 * * `GG` - GG
 * * `GN` - GN
 * * `GW` - GW
 * * `GY` - GY
 * * `HT` - HT
 * * `HM` - HM
 * * `VA` - VA
 * * `HN` - HN
 * * `HK` - HK
 * * `HU` - HU
 * * `IS` - IS
 * * `IN` - IN
 * * `ID` - ID
 * * `IR` - IR
 * * `IQ` - IQ
 * * `IE` - IE
 * * `IM` - IM
 * * `IL` - IL
 * * `IT` - IT
 * * `JM` - JM
 * * `JP` - JP
 * * `JE` - JE
 * * `JO` - JO
 * * `KZ` - KZ
 * * `KE` - KE
 * * `KI` - KI
 * * `KW` - KW
 * * `KG` - KG
 * * `LA` - LA
 * * `LV` - LV
 * * `LB` - LB
 * * `LS` - LS
 * * `LR` - LR
 * * `LY` - LY
 * * `LI` - LI
 * * `LT` - LT
 * * `LU` - LU
 * * `MO` - MO
 * * `MG` - MG
 * * `MW` - MW
 * * `MY` - MY
 * * `MV` - MV
 * * `ML` - ML
 * * `MT` - MT
 * * `MH` - MH
 * * `MQ` - MQ
 * * `MR` - MR
 * * `MU` - MU
 * * `YT` - YT
 * * `MX` - MX
 * * `FM` - FM
 * * `MD` - MD
 * * `MC` - MC
 * * `MN` - MN
 * * `ME` - ME
 * * `MS` - MS
 * * `MA` - MA
 * * `MZ` - MZ
 * * `MM` - MM
 * * `NA` - NA
 * * `NR` - NR
 * * `NP` - NP
 * * `NL` - NL
 * * `NC` - NC
 * * `NZ` - NZ
 * * `NI` - NI
 * * `NE` - NE
 * * `NG` - NG
 * * `NU` - NU
 * * `NF` - NF
 * * `KP` - KP
 * * `MK` - MK
 * * `XI` - XI
 * * `MP` - MP
 * * `NO` - NO
 * * `OM` - OM
 * * `PK` - PK
 * * `PW` - PW
 * * `PS` - PS
 * * `PA` - PA
 * * `PG` - PG
 * * `PY` - PY
 * * `PE` - PE
 * * `PH` - PH
 * * `PN` - PN
 * * `PL` - PL
 * * `PT` - PT
 * * `PR` - PR
 * * `QA` - QA
 * * `RE` - RE
 * * `RO` - RO
 * * `RU` - RU
 * * `RW` - RW
 * * `BL` - BL
 * * `SH` - SH
 * * `KN` - KN
 * * `LC` - LC
 * * `MF` - MF
 * * `PM` - PM
 * * `VC` - VC
 * * `WS` - WS
 * * `SM` - SM
 * * `ST` - ST
 * * `SA` - SA
 * * `SN` - SN
 * * `RS` - RS
 * * `SC` - SC
 * * `SL` - SL
 * * `SG` - SG
 * * `SX` - SX
 * * `SK` - SK
 * * `SI` - SI
 * * `SB` - SB
 * * `SO` - SO
 * * `ZA` - ZA
 * * `GS` - GS
 * * `KR` - KR
 * * `SS` - SS
 * * `ES` - ES
 * * `LK` - LK
 * * `SD` - SD
 * * `SR` - SR
 * * `SJ` - SJ
 * * `SE` - SE
 * * `CH` - CH
 * * `SY` - SY
 * * `TW` - TW
 * * `TJ` - TJ
 * * `TZ` - TZ
 * * `TH` - TH
 * * `TL` - TL
 * * `TG` - TG
 * * `TK` - TK
 * * `TO` - TO
 * * `TT` - TT
 * * `TN` - TN
 * * `TR` - TR
 * * `TM` - TM
 * * `TC` - TC
 * * `TV` - TV
 * * `UG` - UG
 * * `UA` - UA
 * * `AE` - AE
 * * `GB` - GB
 * * `UM` - UM
 * * `US` - US
 * * `UY` - UY
 * * `UZ` - UZ
 * * `VU` - VU
 * * `VE` - VE
 * * `VN` - VN
 * * `VG` - VG
 * * `VI` - VI
 * * `WF` - WF
 * * `EH` - EH
 * * `YE` - YE
 * * `ZM` - ZM
 * * `ZW` - ZW
 */
export enum SectionSupplierCountryEnum {
    AF = "AF",
    AX = "AX",
    AL = "AL",
    DZ = "DZ",
    AS = "AS",
    AD = "AD",
    AO = "AO",
    AI = "AI",
    AQ = "AQ",
    AG = "AG",
    AR = "AR",
    AM = "AM",
    AW = "AW",
    AU = "AU",
    AT = "AT",
    AZ = "AZ",
    BS = "BS",
    BH = "BH",
    BD = "BD",
    BB = "BB",
    BY = "BY",
    BE = "BE",
    BZ = "BZ",
    BJ = "BJ",
    BM = "BM",
    BT = "BT",
    BO = "BO",
    BQ = "BQ",
    BA = "BA",
    BW = "BW",
    BV = "BV",
    BR = "BR",
    IO = "IO",
    BN = "BN",
    BG = "BG",
    BF = "BF",
    BI = "BI",
    CV = "CV",
    KH = "KH",
    CM = "CM",
    CA = "CA",
    KY = "KY",
    CF = "CF",
    TD = "TD",
    CL = "CL",
    CN = "CN",
    CX = "CX",
    CC = "CC",
    CO = "CO",
    KM = "KM",
    CG = "CG",
    CD = "CD",
    CK = "CK",
    CR = "CR",
    CI = "CI",
    HR = "HR",
    CU = "CU",
    CW = "CW",
    CY = "CY",
    CZ = "CZ",
    DK = "DK",
    DJ = "DJ",
    DM = "DM",
    DO = "DO",
    EC = "EC",
    EG = "EG",
    SV = "SV",
    GQ = "GQ",
    ER = "ER",
    EE = "EE",
    SZ = "SZ",
    ET = "ET",
    FK = "FK",
    FO = "FO",
    FJ = "FJ",
    FI = "FI",
    FR = "FR",
    GF = "GF",
    PF = "PF",
    TF = "TF",
    GA = "GA",
    GM = "GM",
    GE = "GE",
    DE = "DE",
    GH = "GH",
    GI = "GI",
    GR = "GR",
    GL = "GL",
    GD = "GD",
    GP = "GP",
    GU = "GU",
    GT = "GT",
    GG = "GG",
    GN = "GN",
    GW = "GW",
    GY = "GY",
    HT = "HT",
    HM = "HM",
    VA = "VA",
    HN = "HN",
    HK = "HK",
    HU = "HU",
    IS = "IS",
    IN = "IN",
    ID = "ID",
    IR = "IR",
    IQ = "IQ",
    IE = "IE",
    IM = "IM",
    IL = "IL",
    IT = "IT",
    JM = "JM",
    JP = "JP",
    JE = "JE",
    JO = "JO",
    KZ = "KZ",
    KE = "KE",
    KI = "KI",
    KW = "KW",
    KG = "KG",
    LA = "LA",
    LV = "LV",
    LB = "LB",
    LS = "LS",
    LR = "LR",
    LY = "LY",
    LI = "LI",
    LT = "LT",
    LU = "LU",
    MO = "MO",
    MG = "MG",
    MW = "MW",
    MY = "MY",
    MV = "MV",
    ML = "ML",
    MT = "MT",
    MH = "MH",
    MQ = "MQ",
    MR = "MR",
    MU = "MU",
    YT = "YT",
    MX = "MX",
    FM = "FM",
    MD = "MD",
    MC = "MC",
    MN = "MN",
    ME = "ME",
    MS = "MS",
    MA = "MA",
    MZ = "MZ",
    MM = "MM",
    NA = "NA",
    NR = "NR",
    NP = "NP",
    NL = "NL",
    NC = "NC",
    NZ = "NZ",
    NI = "NI",
    NE = "NE",
    NG = "NG",
    NU = "NU",
    NF = "NF",
    KP = "KP",
    MK = "MK",
    XI = "XI",
    MP = "MP",
    NO = "NO",
    OM = "OM",
    PK = "PK",
    PW = "PW",
    PS = "PS",
    PA = "PA",
    PG = "PG",
    PY = "PY",
    PE = "PE",
    PH = "PH",
    PN = "PN",
    PL = "PL",
    PT = "PT",
    PR = "PR",
    QA = "QA",
    RE = "RE",
    RO = "RO",
    RU = "RU",
    RW = "RW",
    BL = "BL",
    SH = "SH",
    KN = "KN",
    LC = "LC",
    MF = "MF",
    PM = "PM",
    VC = "VC",
    WS = "WS",
    SM = "SM",
    ST = "ST",
    SA = "SA",
    SN = "SN",
    RS = "RS",
    SC = "SC",
    SL = "SL",
    SG = "SG",
    SX = "SX",
    SK = "SK",
    SI = "SI",
    SB = "SB",
    SO = "SO",
    ZA = "ZA",
    GS = "GS",
    KR = "KR",
    SS = "SS",
    ES = "ES",
    LK = "LK",
    SD = "SD",
    SR = "SR",
    SJ = "SJ",
    SE = "SE",
    CH = "CH",
    SY = "SY",
    TW = "TW",
    TJ = "TJ",
    TZ = "TZ",
    TH = "TH",
    TL = "TL",
    TG = "TG",
    TK = "TK",
    TO = "TO",
    TT = "TT",
    TN = "TN",
    TR = "TR",
    TM = "TM",
    TC = "TC",
    TV = "TV",
    UG = "UG",
    UA = "UA",
    AE = "AE",
    GB = "GB",
    UM = "UM",
    US = "US",
    UY = "UY",
    UZ = "UZ",
    VU = "VU",
    VE = "VE",
    VN = "VN",
    VG = "VG",
    VI = "VI",
    WF = "WF",
    EH = "EH",
    YE = "YE",
    ZM = "ZM",
    ZW = "ZW",
}

/**
 * * `total` - total
 * * `unit` - unit
 */
export enum SelectedPriceOptionEnum {
    Total = "total",
    Unit = "unit",
}

export interface Shareholders {
    id: number;
    company: number;
    /** @maxLength 255 */
    first_name: string;
    /** @maxLength 255 */
    last_name: string;
    /** @format date */
    date_of_birth: string;
    /**
     * @min 25
     * @max 100
     */
    ownership_percentage: number;
    residential_address: KriyaKYBAddress;
}

/**
 * * `day` - Day
 * * `week` - Week
 * * `month` - Month
 */
export enum ShelfLifeUnitEnum {
    Day = "day",
    Week = "week",
    Month = "month",
}

/**
 * * `pending_dispatch` - pending_dispatch
 * * `dispatched` - Dispatched
 * * `delivered` - Delivered
 */
export enum ShipmentStatusEnum {
    PendingDispatch = "pending_dispatch",
    Dispatched = "dispatched",
    Delivered = "delivered",
}

export interface ShortUser {
    /** @format uuid */
    uuid: string;
    /** @maxLength 150 */
    first_name?: string;
    last_name_short: string;
}

export interface SingleMessageResponse {
    /** @maxLength 255 */
    message: string;
}

export interface SourcingRecommendation {
    /** @format uuid */
    ingredient_uuid: string;
    /** @format uuid */
    ingredient_inventory_uuid: string;
    name: string;
    lead_time?: number | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    on_hand: string;
    on_hand_unit_of_measurement: string;
    /** @format uri */
    logo_url: string;
    /** @format date */
    next_order_date?: string | null;
    suggested_contract_quantity?: number;
    suggested_contract_period?: number;
    orders: SourcingRecommendationOrder[];
}

export interface SourcingRecommendationOrder {
    /** @format date */
    order_date: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    quantity: string;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement: UnitOfMeasurementEnum;
}

/**
 * * `fl oz` - Fluid Ounces
 * * `g` - Grams
 * * `mg` - milligrams
 * * `gals` - Gallons
 * * `kg` - Kilograms
 * * `L` - Liters
 * * `tons` - Metric Tons
 * * `oz` - Ounces
 * * `lbs` - Pounds
 * * `pcs` - Pieces
 * * `pt` - Pints
 * * `ml` - Milliliter
 */
export enum StorageUnitEnum {
    FlOz = "fl oz",
    G = "g",
    Mg = "mg",
    Gals = "gals",
    Kg = "kg",
    L = "L",
    Tons = "tons",
    Oz = "oz",
    Lbs = "lbs",
    Pcs = "pcs",
    Pt = "pt",
    Ml = "ml",
}

export interface SupplierCompany {
    id: number;
    /** @format uuid */
    uuid: string;
    /** @format uri */
    website: string;
    /** @maxLength 255 */
    name: string;
    /** @format uri */
    logo?: string | null;
    /** @maxLength 100 */
    industry?: string | null;
    kyb_status: string;
    /** @maxLength 50 */
    phone_number?: string | null;
    country: string;
    state: string;
    /**
     * * `brand` - Brand
     * * `supplier` - Supplier
     */
    company_type?: CompanyTypeEnum;
    /** @default ["h","r","e","o","t"] */
    product_type?: ProductTypeEnum[];
    /** @default ["h","r","e","o","t"] */
    activity_type?: ActivityTypeEnum[];
    /** @maxLength 255 */
    registration_number?: string | null;
    mongo_id: string | null;
    referral_code: string;
    phone_country_code?: PhoneCountryCodeEnum | BlankEnum | NullEnum | null;
    company_addresses?: CompanyAddress[];
    currency: string;
    /**
     * rapyd payout method
     * @maxLength 45
     */
    payout_method_type?: string | null;
    bank_account_country?: Country | BlankEnum | NullEnum | null;
}

export interface SupplierCreate {
    company_name: string;
    /** @format uri */
    website: string;
    first_name: string;
    last_name: string;
    /** @format email */
    email: string;
    /** @maxLength 10 */
    referred_by?: string;
    phone_country_code?: PhoneCountryCodeEnum | BlankEnum | NullEnum | null;
    registration_number?: string | null;
    phone_number?: string | null;
    company_address: CompanyAddress;
    company_type: ActivityTypeEnum[];
}

export interface SupplierDashboardCount {
    /** @min 0 */
    active_contracts: number;
    /** @min 0 */
    orders_to_date: number;
    /**
     * @format decimal
     * @pattern ^-?\d{0,13}(?:\.\d{0,2})?$
     */
    orders_to_date_value: string;
    /** @min 0 */
    active_quotes: number;
    currency: string;
}

export interface SupplierInquiryList {
    id: number;
    /** @format uuid */
    uuid: string;
    /**
     * * `awaiting_quotes` - Awaiting Quotes
     * * `quotes_available` - Quotes Available
     * * `ordered` - Ordered
     * * `expired` - Expired
     * * `proceeded` - Proceeded
     * * `contract_confirmed` - Contract Confirmed
     * * `supplier_decline_to_quote` - Supplier Decline To Quote
     * * `cancelled` - Cancelled
     */
    state?: InquiryState;
    /** @maxLength 255 */
    name: string;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    currency?: Currencies;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    quantity: string;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement?: UnitOfMeasurementEnum;
    /** @format date */
    due_date: string;
    delivery_address: DeliveryAddressForSupplier;
    /**
     * Datetime when the inquiry becomes published. Leave empty to keep it unpublished.
     * @format date-time
     */
    published?: string | null;
    /**
     * @format uri
     * @maxLength 2048
     */
    logo_url?: string;
    is_new: boolean;
    supplier_state: SupplierStateEnum;
    declined: string;
    /** @format date */
    first_order_delivery_date?: string | null;
    /** @default false */
    is_recurring?: boolean;
    is_delivery_date_flexible?: boolean;
    total_forecast_quantity: string;
    /**
     * * `non_recurring` - Non Recurring
     * * `recurring` - Recurring
     * * `purchase_request` - Purchase Request
     * * `ingredient` - Ingredient
     * * `ingredient_copy` - Ingredient Copy
     * * `offline_inquiry` - Offline Inquiry
     * * `sales_inquiry` - Sales Inquiry
     */
    category?: InquiryCategory;
    /** @format uuid */
    original_quote_uuid: string;
}

export interface SupplierInquiryResponse {
    id: number;
    /** @format uuid */
    uuid: string;
    /**
     * * `awaiting_quotes` - Awaiting Quotes
     * * `quotes_available` - Quotes Available
     * * `ordered` - Ordered
     * * `expired` - Expired
     * * `proceeded` - Proceeded
     * * `contract_confirmed` - Contract Confirmed
     * * `supplier_decline_to_quote` - Supplier Decline To Quote
     * * `cancelled` - Cancelled
     */
    state?: InquiryState;
    /**
     * * `non_recurring` - Non Recurring
     * * `recurring` - Recurring
     * * `purchase_request` - Purchase Request
     * * `ingredient` - Ingredient
     * * `ingredient_copy` - Ingredient Copy
     * * `offline_inquiry` - Offline Inquiry
     * * `sales_inquiry` - Sales Inquiry
     */
    category?: InquiryCategory;
    /** @maxLength 255 */
    name: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    current_unit_price: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    target_unit_price: string;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    currency?: Currencies;
    description: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    quantity: string;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement?: UnitOfMeasurementEnum;
    allergen_requirements?: (AllergenRequirementsEnum | BlankEnum)[];
    allergen_declaration?: boolean;
    dietary_certificate?: boolean;
    diet_requirements?: (DietRequirementsEnum | BlankEnum)[];
    forecast?: ForecastEnum | BlankEnum | NullEnum | null;
    /** @format date */
    due_date: string;
    delivery_address: DeliveryAddressForSupplier;
    /** @maxItems 250 */
    supplier_countries: (Country | BlankEnum)[];
    current_supplier?: string;
    /**
     * Datetime when the inquiry becomes published. Leave empty to keep it unpublished.
     * @format date-time
     */
    published?: string | null;
    /**
     * @format uri
     * @maxLength 2048
     */
    logo_url?: string;
    is_new: boolean;
    /** @format uuid */
    article?: string | null;
    existing_suppliers: string[] | null;
    supply_priorities?: SupplyPriorities[];
    /** @format date */
    first_order_delivery_date?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    opply_brand_commission?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    opply_supplier_commission?: string;
    /** @default false */
    is_recurring?: boolean;
    frequency?: FrequencyEnum | BlankEnum | NullEnum | null;
    /** @min 0 */
    desired_contract_length?: number | null;
    is_delivery_date_flexible?: boolean;
    /** @format uuid */
    parent_uuid?: string | null;
    ingredient?: number | null;
    reason_for_request?: string | null;
    desired_packaging_format?: string;
    desired_packaging_format_is_flexible?: boolean;
    ai_generated?: boolean;
    documents_to_copy?: string[] | null;
    orders_uuid: string[];
    /** @format uuid */
    existing_supplier_uuid: string;
    total_forecast_quantity: string;
    /** @format uuid */
    original_quote_uuid: string;
    declined: string;
    decline_reason: string;
    company_address: Address;
    progress: string;
    possible_suppliers: string;
    suppliers_found: string;
    number_of_quotes: string;
    company: Company;
    existing_supplier_name: string;
    supplier_matching: string;
    total_forecast_spend: string;
    /** @default "" */
    published_quote_uuid?: string;
    supplier_state: SupplierStateEnum;
    buyer_contact_name: string;
    buyer_full_name: string;
    commission_payout_vat_rate: string;
}

export interface SupplierOnboarding {
    /** @maxLength 255 */
    first_name: string;
    /** @maxLength 255 */
    last_name: string;
    /**
     * @format email
     * @maxLength 255
     */
    email: string;
    /** @maxLength 255 */
    password: string;
    /** @maxLength 255 */
    company_name: string;
    /** @maxLength 100 */
    state?: string;
    /** @format uri */
    website: string;
    company_address: CompanyAddress;
    referred_by?: string;
    phone_country_code?: PhoneCountryCodeEnum | BlankEnum | NullEnum | null;
    registration_number?: string | null;
    phone_number?: string | null;
    /** @default ["h","r","e","o","t"] */
    activity_type?: ActivityTypeEnum[];
}

export interface SupplierQuestionnaire {
    /** @default ["h","r","e","o","t"] */
    product_category?: ProductCategoryEnum[];
    annual_turnover?: AnnualTurnoverEnum | BlankEnum | NullEnum | null;
    num_of_employees?: NumOfEmployeesEnum | BlankEnum | NullEnum | null;
    num_of_skus?: NumOfSkusEnum | BlankEnum | NullEnum | null;
    mov?: MovEnum | BlankEnum | NullEnum | null;
}

export interface SupplierQuoteUpdate {
    company: Company;
    /** @maxLength 255 */
    product_name: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
     */
    quantity: string;
    /** @format date */
    delivery_date: string;
    /**
     * * `in_advance` - In Advance
     * * `on_dispatch` - On Dispatch
     */
    payment_scenario?: PaymentScenarioEnum;
    /**
     * @min 0
     * @max 365
     */
    payment_delay?: number;
    /**
     * @min 0
     * @default 0
     */
    shelf_life?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    quote_valid_for?: number | null;
    /**
     * The maximum time a supplier agrees to deliver the product within (days)
     * @min 0
     * @max 2147483647
     */
    lead_time: number;
    product_description?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    shipping_cost?: string | null;
    /**
     * * `EUR` - EUR
     * * `USD` - USD
     * * `GBP` - GBP
     * * `CAD` - CAD
     */
    currency: Currencies;
    /**
     * Datetime when the quote becomes published. Leave empty to keep it unpublished.
     * @format date-time
     */
    published?: string | null;
    /**
     * * `fl oz` - Fluid Ounces
     * * `g` - Grams
     * * `mg` - milligrams
     * * `gals` - Gallons
     * * `kg` - Kilograms
     * * `L` - Liters
     * * `tons` - Metric Tons
     * * `oz` - Ounces
     * * `lbs` - Pounds
     * * `pcs` - Pieces
     * * `pt` - Pints
     * * `ml` - Milliliter
     */
    unit_of_measurement?: UnitOfMeasurementEnum;
    /** @format date-time */
    created_date: string;
    /**
     * @format uri
     * @maxLength 2048
     */
    logo_url?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,10})?$
     */
    total_price: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,9}(?:\.\d{0,10})?$
     */
    unit_price?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,1}(?:\.\d{0,4})?$
     */
    vat_rate: string | null;
    is_reverse_charge?: boolean;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    commission_payout_vat_rate?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,7}(?:\.\d{0,2})?$
     */
    commission_payin_vat_rate?: string;
    commission_is_reverse_charge?: boolean;
    /** @maxLength 32 */
    sku?: string | null;
    /** @default "delivery_duty_paid" */
    incoterms?: IncotermsEnum | NullEnum | null;
    dispatch_address?: number | null;
    /**
     * * `available` - Available
     * * `requested` - Requested
     * * `sent` - Sent
     * * `received` - Received
     * * `accepted` - Accepted
     * * `rejected` - Rejected
     * * `declined` - Declined
     */
    samples_status?: SamplesStatusEnum;
    samples_delivery_address?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    recurring_order_lead_time?: number | null;
    /**
     * Duration of the contract in months.
     * @min -2147483648
     * @max 2147483647
     */
    contract_length?: number | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,17}(?:\.\d{0,2})?$
     */
    case_size?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,17}(?:\.\d{0,2})?$
     */
    minimum_order_quantity?: string | null;
    agreement_required?: boolean;
    account_form_required?: boolean;
    /**
     * * `non_recurring` - Non Recurring
     * * `recurring` - Recurring
     * * `purchase_request_quote` - Purchase Request Quote
     * * `offline_quote` - Offline Quote
     * * `sales_quote` - Sales Quote
     */
    category?: QuoteCategory;
    /** @format date */
    samples_declined_date?: string | null;
    samples_feedback?: SamplesFeedbackEnum[] | null;
    samples_feedback_comment?: string;
    /**
     * * `in_advance` - In Advance
     * * `on_dispatch` - On Dispatch
     * * `7_days_after_dispatch` - Days After Dispatch 7
     * * `14_days_after_dispatch` - Days After Dispatch 14
     * * `30_days_after_dispatch` - Days After Dispatch 30
     * * `60_days_after_dispatch` - Days After Dispatch 60
     */
    payment_terms?: PaymentTermsEnum;
    is_custom_contract?: boolean;
    /** @format date */
    samples_dispatch_date?: string | null;
    /** @format date */
    samples_sent_date?: string | null;
    samples_tracking_code?: string | null;
    /** @format date */
    samples_expected_delivery_date?: string | null;
    /** @maxLength 255 */
    samples_shipping_provider?: string | null;
    autoconfirm_orders?: boolean;
    /**
     * @min 0
     * @max 2147483647
     */
    minimum_lead_time?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    order_in_multiples_of?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    minimum_autoconfirm_order_quantity?: number | null;
    /**
     * @min 0
     * @max 2147483647
     */
    maximum_autoconfirm_order_quantity?: number | null;
    packaging_format?: string;
    show_to_other_buyers?: boolean;
    description_blocks?: QuoteDescriptionBlockCreate[] | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,1}(?:\.\d{0,4})?$
     */
    shipping_vat_rate?: string | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
     */
    total_forecast_quantity?: string | null;
    /**
     * * `total` - total
     * * `unit` - unit
     */
    selected_price_option?: SelectedPriceOptionEnum;
}

/**
 * * `awaiting_quote` - awaiting_quote
 * * `quote_provided` - quote_provided
 * * `ordered` - ordered
 * * `expired` - expired
 * * `declined` - declined
 */
export enum SupplierStateEnum {
    AwaitingQuote = "awaiting_quote",
    QuoteProvided = "quote_provided",
    Ordered = "ordered",
    Expired = "expired",
    Declined = "declined",
}

export interface SupplyPriorities {
    priority_name?: PriorityNameEnum | BlankEnum | NullEnum | null;
    /**
     * Level of priority for the user. 1-higher, 3-lower
     * @min 1
     * @max 3
     */
    priority_value?: number | null;
}

/**
 * * `EUR` - EUR
 * * `GBP` - GBP
 * * `USD` - USD
 * * `CAD` - CAD
 */
export enum SupportedCurrencies {
    EUR = "EUR",
    GBP = "GBP",
    USD = "USD",
    CAD = "CAD",
}

export interface TargetDeleteAdmin {
    company_uuids: string[];
}

export interface TaskCreated {
    /** @format uuid */
    task_id: string;
}

export interface TaskResult {
    /**
     * Celery ID for the Task that was run
     * @maxLength 255
     */
    task_id: string;
    /**
     * Task State
     * Current state of the task being run
     *
     * * `FAILURE` - FAILURE
     * * `PENDING` - PENDING
     * * `RECEIVED` - RECEIVED
     * * `RETRY` - RETRY
     * * `REVOKED` - REVOKED
     * * `STARTED` - STARTED
     * * `SUCCESS` - SUCCESS
     */
    status?: TaskResultStatusEnum;
    result: Record<string, any>;
}

/**
 * * `FAILURE` - FAILURE
 * * `PENDING` - PENDING
 * * `RECEIVED` - RECEIVED
 * * `RETRY` - RETRY
 * * `REVOKED` - REVOKED
 * * `STARTED` - STARTED
 * * `SUCCESS` - SUCCESS
 */
export enum TaskResultStatusEnum {
    FAILURE = "FAILURE",
    PENDING = "PENDING",
    RECEIVED = "RECEIVED",
    RETRY = "RETRY",
    REVOKED = "REVOKED",
    STARTED = "STARTED",
    SUCCESS = "SUCCESS",
}

/**
 * * `Kriya KYB Reminder` - Kriya Kyb Reminder
 * * `New Quote` - New Quote
 * * `New Quote Reminder` - New Quote Reminder
 * * `New Request` - New Request
 * * `New Request Reminder` - New Request Reminder
 * * `New Samples Request` - New Samples Request
 * * `Samples Request Reminder` - Samples Request Reminder
 * * `Samples Dispatched` - Samples Dispatched
 * * `Samples Received Reminder` - Samples Received Reminder
 * * `Samples Approved` - Samples Approved
 * * `Samples Not Approved` - Samples Not Approved
 * * `Buyer Started Negotiation` - Buyer Started Negotiation
 * * `Supplier Negotiation Response` - Supplier Negotiation Response
 * * `Quote updated` - Quote Updated
 * * `New Order` - New Order
 * * `Order Dispatched` - Order Dispatched
 * * `Payment Due` - Payment Due
 * * `Quote Proceeded` - Quote Proceeded
 * * `Create Contract Reminder` - Create Contract Reminder
 * * `Proceed with Contract` - Proceed With Contract
 * * `Reminder: Proceed with Contract` - Reminder Proceed With Contract
 * * `Confirm Contract` - Confirm Contract
 * * `Contract Created` - Contract Created
 * * `New Purchase Request` - New Purchase Request
 * * `New Purchase Request Reminder` - New Purchase Request Reminder
 * * `Response to Purchase Request` - Response To Purchase Request
 * * `Purchase Request Cancelled` - Purchase Request Cancelled
 * * `Quote Expiring` - Quote Expiring
 * * `Request Expiring` - Request Expiring
 * * `New Message` - New Message
 * * `New User` - New User
 * * `Contract Renewal Declined` - Contract Renewal Declined
 * * `Supplier Accepts Contract Renewal` - Contract Renewal Accepted
 * * `Sign New Contract Purchase Agreement` - Contract Renewal Accepted With Agreement
 * * `Contract Renewal Requested` - Contract Renewal Requested
 * * `Contract Renewal Terms accepted` - Contract Renewal Terms Accepted
 * * `Supplier Negotiates Contract Renewal` - Supplier Negotiates Contract Renewal
 * * `Re-order` - Reorder
 * * `Request for Re-order Declined` - Reorder Declined
 */
export enum TitleEnum {
    KriyaKYBReminder = "Kriya KYB Reminder",
    NewQuote = "New Quote",
    NewQuoteReminder = "New Quote Reminder",
    NewRequest = "New Request",
    NewRequestReminder = "New Request Reminder",
    NewSamplesRequest = "New Samples Request",
    SamplesRequestReminder = "Samples Request Reminder",
    SamplesDispatched = "Samples Dispatched",
    SamplesReceivedReminder = "Samples Received Reminder",
    SamplesApproved = "Samples Approved",
    SamplesNotApproved = "Samples Not Approved",
    BuyerStartedNegotiation = "Buyer Started Negotiation",
    SupplierNegotiationResponse = "Supplier Negotiation Response",
    QuoteUpdated = "Quote updated",
    NewOrder = "New Order",
    OrderDispatched = "Order Dispatched",
    PaymentDue = "Payment Due",
    QuoteProceeded = "Quote Proceeded",
    CreateContractReminder = "Create Contract Reminder",
    ProceedWithContract = "Proceed with Contract",
    ReminderProceedWithContract = "Reminder: Proceed with Contract",
    ConfirmContract = "Confirm Contract",
    ContractCreated = "Contract Created",
    NewPurchaseRequest = "New Purchase Request",
    NewPurchaseRequestReminder = "New Purchase Request Reminder",
    ResponseToPurchaseRequest = "Response to Purchase Request",
    PurchaseRequestCancelled = "Purchase Request Cancelled",
    QuoteExpiring = "Quote Expiring",
    RequestExpiring = "Request Expiring",
    NewMessage = "New Message",
    NewUser = "New User",
    ContractRenewalDeclined = "Contract Renewal Declined",
    SupplierAcceptsContractRenewal = "Supplier Accepts Contract Renewal",
    SignNewContractPurchaseAgreement = "Sign New Contract Purchase Agreement",
    ContractRenewalRequested = "Contract Renewal Requested",
    ContractRenewalTermsAccepted = "Contract Renewal Terms accepted",
    SupplierNegotiatesContractRenewal = "Supplier Negotiates Contract Renewal",
    ReOrder = "Re-order",
    RequestForReOrderDeclined = "Request for Re-order Declined",
}

export interface URL {
    /** @format uri */
    url: string;
}

export interface UUIDList {
    uuids: string[];
}

/**
 * * `fl oz` - Fluid Ounces
 * * `g` - Grams
 * * `mg` - milligrams
 * * `gals` - Gallons
 * * `kg` - Kilograms
 * * `L` - Liters
 * * `tons` - Metric Tons
 * * `oz` - Ounces
 * * `lbs` - Pounds
 * * `pcs` - Pieces
 * * `pt` - Pints
 * * `ml` - Milliliter
 */
export enum UnitOfMeasurementEnum {
    FlOz = "fl oz",
    G = "g",
    Mg = "mg",
    Gals = "gals",
    Kg = "kg",
    L = "L",
    Tons = "tons",
    Oz = "oz",
    Lbs = "lbs",
    Pcs = "pcs",
    Pt = "pt",
    Ml = "ml",
}

export interface UnleashedBuyerRequest {
    api_key: string;
    api_id: string;
}

export interface UnreadCount {
    unread_count: number;
}

export interface UpdatePassword {
    current_password: string;
    /** @maxLength 128 */
    password: string;
}

export interface UpdatePurchaseRequest {
    /**
     * @format decimal
     * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
     */
    quantity: string;
    /** @format date */
    due_date: string;
    delivery_address?: number | null;
    /** @format uuid */
    purchase_quote_uuid: string;
}

export interface UploadedProductsCatalogue {
    id: number;
    /** @format uuid */
    uuid: string;
    /**
     * * `pending` - Pending
     * * `being_processed` - Is being processed
     * * `processed` - Processed
     * * `failed` - Failed
     */
    status?: UploadedProductsCatalogueStatus;
    /** @format uri */
    catalogue?: string | null;
}

/**
 * * `pending` - Pending
 * * `being_processed` - Is being processed
 * * `processed` - Processed
 * * `failed` - Failed
 */
export enum UploadedProductsCatalogueStatus {
    Pending = "pending",
    BeingProcessed = "being_processed",
    Processed = "processed",
    Failed = "failed",
}

export interface UploadedProductsCatalogueStorage {
    /** @maxLength 976 */
    file_path: string;
}

/**
 * * `general_notifications` - General Notifications
 * * `actions` - Actions
 */
export enum UsedForEnum {
    GeneralNotifications = "general_notifications",
    Actions = "actions",
}

export interface User {
    id: number;
    /** @format uuid */
    uuid: string;
    /** @format email */
    email: string;
    /** @maxLength 150 */
    first_name?: string;
    /** @maxLength 150 */
    last_name?: string;
    /** @maxLength 255 */
    job_title?: string | null;
    /** @format double */
    tc_version?: number;
}

export interface UserAction {
    /** @format date-time */
    created: string;
    /**
     * * `login` - login
     * * `Quote viewed` - Quote viewed
     * * `Quote viewed on request details` - Quote viewed on request details
     * * `Inquiry viewed` - Inquiry viewed
     * * `Order viewed` - Order viewed
     */
    action?: UserActionActionEnum;
    extra_data?: string | null;
}

/**
 * * `login` - login
 * * `Quote viewed` - Quote viewed
 * * `Quote viewed on request details` - Quote viewed on request details
 * * `Inquiry viewed` - Inquiry viewed
 * * `Order viewed` - Order viewed
 */
export enum UserActionActionEnum {
    Login = "login",
    QuoteViewed = "Quote viewed",
    QuoteViewedOnRequestDetails = "Quote viewed on request details",
    InquiryViewed = "Inquiry viewed",
    OrderViewed = "Order viewed",
}

export interface UserAuthToken {
    token: string;
}

export interface UserInviteAcceptRequest {
    /** @format uuid */
    invite_uuid: string;
    password: string;
}

export interface UserInviteCreate {
    /** @maxLength 30 */
    first_name: string;
    /** @maxLength 30 */
    last_name: string;
    /**
     * @format email
     * @maxLength 254
     */
    email: string;
}

export interface UserList {
    /** @format uuid */
    uuid: string;
    /** @maxLength 150 */
    first_name?: string;
    /** @maxLength 150 */
    last_name?: string;
    /**
     * @format email
     * @maxLength 254
     */
    email: string;
    company_name: string | null;
    last_login: UserAction;
}

export interface UserRetrieve {
    /** @format uuid */
    uuid: string;
    /** @maxLength 150 */
    first_name?: string;
    /** @maxLength 150 */
    last_name?: string;
    /**
     * @format email
     * @maxLength 254
     */
    email: string;
    company_name: string | null;
    last_login: UserAction;
    /** @maxLength 255 */
    job_title?: string | null;
    company_type: string | null;
}

export interface UserTokenRequest {
    /** @format uuid */
    user_uuid: string;
}

export interface UserTokenResponse {
    token: string;
}

export interface UserUpdate {
    /** @maxLength 150 */
    first_name?: string;
    /** @maxLength 150 */
    last_name?: string;
    /** @maxLength 255 */
    job_title?: string | null;
    /**
     * @format email
     * @maxLength 254
     */
    email: string;
}

export interface Userflow {
    id: number;
    /** @format uuid */
    uuid: string;
    /** @format email */
    email: string;
    /** @maxLength 150 */
    first_name?: string;
    /** @maxLength 150 */
    last_name?: string;
    /** @maxLength 255 */
    job_title?: string | null;
    /** @format double */
    tc_version?: number;
    userflow_signature: string;
    signed_up_at: string;
}

export interface VatInvoiceResponse {
    /** @format uri */
    pdf_url: string;
    /** @format uri */
    pdf_url_merged: string;
}

export interface VatInvoiceSupplierResponse {
    /** @format uri */
    pdf_url: string;
}
