<template>
    <div>
        <toast />
        <RouterView />
    </div>
</template>

<script lang="ts">
import {
    computed,
    defineComponent,
    onBeforeMount,
    onBeforeUnmount,
    watch,
    watchEffect,
} from "vue";
import { useIdle } from "@vueuse/core";
import { RouterView } from "vue-router";
import isNullish from "@/utils/isNullish";
import { HOTJAR_USER_ID } from "@/constants";
import { useToast } from "primevue/usetoast";
import { useFeatureFlag } from "@/composables";
import { useToastStore } from "@/stores/toast";
import { useKriyaStore } from "@/stores/kriya";
import { useAuthStore } from "@/stores/userAuth";
import { useSupplierStore } from "@/stores/supplier";
import { useChatListStore } from "@/stores/chatList";
import { useParagon } from "@/composables/useParagon";
import WebSocketsService from "@/services/WebSockets";
import { useKybStatus } from "@/composables/useKybStatus";
import { useNotificationsStore } from "@/stores/notifications";
import Toast, { type ToastMessageOptions } from "primevue/toast";

export default defineComponent({
    components: {
        RouterView,
        Toast,
    },
    setup() {
        const authStore = useAuthStore();
        const toastStore = useToastStore();
        const supplierStore = useSupplierStore();
        const kriyaStore = useKriyaStore();
        const notificationsStore = useNotificationsStore();
        const chatListStore = useChatListStore();
        const { initializeFeatureFlags } = useFeatureFlag();
        const { subscribeToParagonEvents, unSubscribeToParagonEvents } =
            useParagon();
        const { fetchKybStatus } = useKybStatus();
        const webSocketService = WebSocketsService.getInstance();

        const {
            connect: connectNotifications,
            disconnect: disconnectNotifications,
        } = notificationsStore;

        const toast = useToast();

        /**
         * idle period is defined as 10 minutes
         * if the user remains idle for 10 minutes idle will be toggled to true
         */
        const { idle } = useIdle(10 * 60 * 1000);

        const createCookieScript = () => {
            if (import.meta.env.VITE_CURRENT_ENV === "PRODUCTION") {
                const script = document.createElement("script");
                script.id = "cookieyes";
                script.src =
                    "https://cdn-cookieyes.com/client_data/92ba444ef47267a44a5227ff/script.js";
                document.head.appendChild(script);
            }
        };

        const userUUID = computed(() => authStore.getUserUUID);

        toastStore.$subscribe((mutation, state) => {
            toast.add({ ...state } as ToastMessageOptions);
        });

        /**
         * Appends the hotjar tracking script to the head of the document.
         */
        const createHotJarTrackingScript = () => {
            if (import.meta.env.VITE_CURRENT_ENV !== "PRODUCTION") return;

            const script = document.createElement("script");
            script.id = "hotjar-base";
            script.type = "text/javascript";
            script.text = `
                        (function (h, o, t, j, a, r) {
                h.hj =
                    h.hj ||
                    function () {
                        (h.hj.q = h.hj.q || []).push(arguments);
                    };
                h._hjSettings = { hjid: ${HOTJAR_USER_ID}, hjsv: 6 };
                a = o.getElementsByTagName("head")[0];
                r = o.createElement("script");
                r.async = 1;
                r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                a.appendChild(r);
            })(
                window,
                document,
                "https://static.hotjar.com/c/hotjar-",
                ".js?sv="
            );
            `;

            document.head.appendChild(script);
        };

        /**
         * Appends the hotjar identity script to the head of the document.
         */
        const createHotJarIdentityScript = () => {
            if (import.meta.env.VITE_CURRENT_ENV !== "PRODUCTION") return;

            const script = document.createElement("script");

            script.id = "hotjar-identities";
            script.type = "text/javascript";
            script.text = `
                        var userId = '${HOTJAR_USER_ID}';
                        window.hj('identify', ${HOTJAR_USER_ID}, {
                            'Company name': '${authStore.companyData.name}',
                            'Company type': '${authStore.companyData.company_type}',
                            'Company country': '${authStore.companyData.country}',
                            'User first name': '${authStore.userData.first_name}',
                            'User last name': '${authStore.userData.last_name}',
                            'User email address': '${authStore.userData.email}',
                        });
                        `;

            document.head.appendChild(script);
        };

        document.addEventListener("visibilitychange", () => {
            if (document.hidden) return;

            webSocketService.establishConnection();
        });

        watch(
            userUUID,
            () => {
                if (!userUUID.value) return;

                chatListStore.fetchUserChatList();
            },
            { immediate: true }
        );

        watchEffect(() => {
            if (authStore.isSupplier && !isNullish(authStore.companyAddress)) {
                supplierStore.fetchSupplierKybPayoutDetails();
            }

            if (authStore.isBrand) {
                kriyaStore.fetchCompanyDetails();
                fetchKybStatus();
            }

            if (authStore.auth && authStore.userData) {
                subscribeToParagonEvents();

                if (import.meta.env.VITE_CURRENT_ENV === "PRODUCTION") {
                    // Initialize Pendo Once the user has been authenticated and we are on production servers
                    // @ts-ignore
                    // eslint-disable-next-line no-undef
                    pendo.initialize({
                        visitor: {
                            id: authStore.userData.uuid,
                            email: authStore.userData.email,
                            firstName: authStore.userData.first_name,
                            lastName: authStore.userData.last_name,
                        },

                        account: {
                            id: authStore.companyData.uuid,
                            accountName: authStore.companyData.name,
                        },
                    });
                }
            }
        });

        /**
         * if the user remains idle for more than 10 minutes and
         * the user token is not found in local storage then logout the user
         */
        watch(idle, (idleValue) => {
            const userToken = localStorage.getItem("userAuthToken");
            if (idleValue && !userToken) {
                authStore.signout();
            }
        });

        const unwatchAuthStore = watch(authStore, () => {
            if (!authStore.companyData || !authStore.userData || false) return;

            connectNotifications();
            createHotJarTrackingScript();
            createHotJarIdentityScript();
            unwatchAuthStore();
        });

        window.addEventListener("beforeunload", () => {
            disconnectNotifications();
        });

        onBeforeUnmount(() => {
            disconnectNotifications();
            unSubscribeToParagonEvents();
        });

        // on-create life-cycle
        onBeforeMount(async () => {
            createCookieScript();
            await initializeFeatureFlags();
        });

        return {
            authStore,
        };
    },
});
</script>
